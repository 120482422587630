import { MDBTable } from "mdbreact";
import { Fragment, useEffect } from "react";
import { Loader } from "../../../UI/Loaders/Loaders";
import useRepaymentApprovalLog from "../../../custom-hooks/useRepaymentsApprovalLog";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

interface IRepaymentApprovalLog {
  dataRecord: {
    corebankingStatusMessage: string;
    repaymentChannelMessage: string;
    trans_ref: string;
  };
}

const RepaymentApprovalLog = ({ dataRecord }: IRepaymentApprovalLog) => {
  const { data, error, isLoading } = useRepaymentApprovalLog(
    dataRecord?.trans_ref,
  );

  useEffect(() => {
    MixPanel.track(`Page View: RepaymentApprovalLog.tsx`, {
      page: "RepaymentApprovalLog.tsx",
    });
  }, []);

  if (isLoading)
    return <Loader centered={true} text="Loading approval trail..." />;

  if (error) {
    return (
      <div className="centerLoader m-b-40" style={{ paddingTop: "0" }}>
        <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" />
            An error occured, please try again.
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <div className="mb-3">
            <h3>
              Core banking status message:{" "}
              {dataRecord?.corebankingStatusMessage
                ? dataRecord?.corebankingStatusMessage
                : "NIL"}
            </h3>
            <h3>
              Repayment channel message:{" "}
              {dataRecord?.repaymentChannelMessage
                ? dataRecord?.repaymentChannelMessage
                : "NIL"}
            </h3>
          </div>

          <h3 className="mb-3">Approval Trail</h3>
          <div style={{ maxHeight: "50vh", overflowX: "hidden" }}>
            <>
              {data && data?.length > 0 ? (
                <Fragment>
                  <MDBTable responsive minimal="true" bordered hover>
                    <thead className="bg-lighter-grey">
                      <tr>
                        <th>S/N</th>
                        <th>Email</th>
                        <th>Approval Comment</th>
                        <th>Date/Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(
                        (
                          trail: {
                            email: string;
                            approvalComment: string;
                            createdAt: string;
                          },
                          _id: number,
                        ) => {
                          const { email, approvalComment, createdAt } = trail;
                          return (
                            <tr key={_id}>
                              <td>{_id + 1}</td>
                              <td>{email}</td>
                              <td>{approvalComment}</td>
                              <td>{createdAt}</td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </MDBTable>
                </Fragment>
              ) : (
                <div
                  className="centerLoader m-b-40"
                  style={{ paddingTop: "0" }}
                >
                  <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
                    <h4 className="center-txt p-y-5">
                      <i className="fas fa-bell" />
                      No approval trail available.
                    </h4>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepaymentApprovalLog;
