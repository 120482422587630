import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

export type TFundingWalletDetail = {
  id: number;
  customerId: number;
  walletProvider: number;
  walletProviderId: number;
  bankName: string;
  bankCode: string;
  currency: string;
  accountNumber: string;
  walletId: string;
  accountName: string;
  isDefault: boolean;
  availableBalance: number;
  ledgerBalance: number;
  accountStatus: string;
  accountStatusMessage: string;
  bvn: string;
  isBvnVerified: boolean;
  country: string;
  phoneNumberCountryDialCode: string;
  phoneNumber: string;
  customerType: string;
  aggregatorId: string;
  isTransactionPinSet: boolean;
  companyRegistrationNumber: string;
  transferCharge: number;
};

type TQuery = {
  aggregatorId: number;
  walletId: string;
};

const getFundingWalletDetail = async ({ aggregatorId, walletId }: TQuery) => {
  const { data } = await getData(
    `${ajaxEndpoints.GET_FUNDING_WALLET_DETAIL}?aggregatorId=${aggregatorId}&walletId=${walletId}`,
  );

  return data;
};

export default function useFundingWalletDetail({
  aggregatorId,
  walletId,
}: TQuery) {
  return useQuery<IResponse<TFundingWalletDetail>>(
    [{ aggregatorId, walletId }, "getFundingWalletDetail"],
    getFundingWalletDetail,
    {
      refetchOnWindowFocus: false,
    },
  );
}
