import React, { useEffect, useState } from "react";
import { appInsights } from "../../../../config/appInsights";
import CustomInputField from "../../../CustomHTMLElements/CustomInputField";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useLocation, useRouteMatch } from "react-router-dom";
import {
  useCustomerAccountDetails,
  useIndividualCustomerDetails,
} from "../../../../custom-hooks/useIndividualCustomer";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import {
  CustomerData,
  CustomerDataDocument,
} from "../../../../interfaces/individualCustomer";
import { Loader } from "../../../../UI/Loaders/Loaders";

const IndividualKycDocumentsTabs = () => {
  const match = useRouteMatch();
  const { id } = match?.params as { id: string };

  const [modal, setModal] = useState(false);
  const [document, setDocument] = useState<CustomerDataDocument | undefined>();
  useEffect(() => {
    appInsights.trackPageView({
      name: "IndividualKycDocumentsTabs",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: customerData,
    isLoading,
    isError,
  } = useIndividualCustomerDetails({
    customerId: Number(id),
  });

  const toggleModal = () => {
    setModal(!modal);
  };

  if (isLoading)
    return <Loader centered={true} text="Loading customer accounts..." />;

  if (isError)
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            customer accounts, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <br />
          <div>
            <CustomInputField
              label="BVN"
              type="text"
              name="bvn"
              disabled
              defaultValue={customerData?.data?.personalDetails?.bvn}
            />
          </div>
          <div className="mt-5">
            <CustomInputField
              label="NIN"
              type="text"
              name="nin"
              disabled
              defaultValue={
                customerData?.data?.personalDetails?.nin
                  ? String(customerData?.data?.personalDetails?.nin)
                  : "N/A"
              }
            />
          </div>
          <div>
            <label>Means of Identification</label>
            <div className="card">
              {customerData && customerData?.data?.documents?.length > 0 ? (
                customerData?.data?.documents?.map((item) => (
                  <div
                    key={item?.documentName}
                    className="card-body d-flex justify-content-between align-items-center"
                    style={{
                      padding: "10px 0",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <p
                      style={{ margin: "0" }}
                      className="advancly-primary-text"
                    >
                      {item?.documentName}
                      ................................................
                    </p>
                    <p
                      style={{ margin: "0" }}
                      className="cursor-pointer advancly-primary-text"
                      onClick={() => {
                        setDocument(item);
                        toggleModal();
                      }}
                    >
                      View
                    </p>
                  </div>
                ))
              ) : (
                <p className="d-flex justify-content-center align-items-center">
                  No documents uploaded
                </p>
              )}
            </div>
          </div>
        </div>

        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            Identification document
          </ModalHeader>
          <ModalBody>
            <img
              src={`https://advanclystorage.blob.core.windows.net/advancly/${document?.documentUrl}`}
              alt="Drivers License"
              className="img-fluid"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default IndividualKycDocumentsTabs;
