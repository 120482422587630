import React from "react";
import { ReactComponent as NoDataIcon } from "../../assets/svg/icons/no-data.svg";
import styles from "./NoTableDataAvailable.module.scss";

interface NoTableDataAvailableProps {
  text?: string;
}

export default function NoTableDataAvailable({
  text,
}: NoTableDataAvailableProps) {
  return (
    <tr>
      <td colSpan={100}>
        <div className={styles.noDataWrapper}>
          <NoDataIcon />
          <span>{text ? text : "There is no data available"}</span>
        </div>
      </td>
    </tr>
  );
}
