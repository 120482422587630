import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { USE_MOCK } from "../appConstants";
import {
  AccountDetails,
  CustomerData,
  CustomerDataAuditTrail,
} from "../interfaces/individualCustomer";
import {
  customerAccountDetails,
  individualCustomerData,
} from "../mocks/individualCustomerData";

export type TIndividualCustomerParams = {
  page: number;
  pageSize: number;
  startDate?: string;
  endDate?: string;
  name?: string;
  phoneNumber?: string;
  email?: string;
};

export interface IIndividualCustomer {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  acctNo: string;
  email: string;
  dateCreated: string;
  gender: string;
}

export interface IPaginatedIndividualCustomers {
  Page: number;
  PageSize: number;
  totalCount: number;
  data: IIndividualCustomer[];
}

export const getIndividualCustomers = async ({
  page,
  pageSize,
  startDate,
  endDate,
  name,
  email,
  phoneNumber,
}: TIndividualCustomerParams): Promise<IPaginatedIndividualCustomers> => {
  if (USE_MOCK) {
    // Return mock data using setTimeout to simulate network delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          Page: 1,
          PageSize: 20,
          totalCount: 1,
          data: [
            {
              id: 1,
              firstName: "Test",
              lastName: "User",
              phoneNumber: "0123456789",
              acctNo: "1234567890",
              email: "testuser@example.com",
              dateCreated: "2024-01-01",
              gender: "Non-binary",
            },
          ],
        });
      }, 5000);
    });
  }

  let queryParams = `?Page=${page}&PageSize=${pageSize}&ProductDomain=3&CustomerType=1`;
  if (startDate) queryParams += `&startDate=${startDate}`;
  if (endDate) queryParams += `&endDate=${endDate}`;
  if (name) queryParams += `&name=${name}`;
  if (email) queryParams += `&email=${email}`;
  if (phoneNumber) queryParams += `&phoneNumber=${phoneNumber}`;

  return await getDataWithDotNet(
    `${ajaxEndpoints.DB_INDIVIDUAL_CUSTOMERS}${queryParams}`,
  );
};

export const useIndividualCustomers = (params: TIndividualCustomerParams) => {
  return useQuery<IPaginatedIndividualCustomers, Error>(
    ["getIndividualCustomers", params],
    () => getIndividualCustomers(params),
  );
};

export const getIndividualCustomerDetails = async ({
  customerId,
}: {
  customerId: number;
}): Promise<CustomerData> => {
  if (USE_MOCK) {
    // Return mock data using setTimeout to simulate network delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(individualCustomerData);
      }, 5000);
    });
  }

  let queryParams = `?customerId=${customerId}`;

  return await getDataWithDotNet(
    `${ajaxEndpoints.DB_INDIVIDUAL_CUSTOMER_DETAILS}${queryParams}`,
  );
};

export const useIndividualCustomerDetails = ({
  customerId,
}: {
  customerId: number;
}) => {
  return useQuery<CustomerData, Error>(
    ["getIndividualCustomerDetails", customerId],
    () => getIndividualCustomerDetails({ customerId }),
  );
};

export const getCustomerAccountDetails = async ({
  customerId,
  accountNumber,
}: {
  customerId: number;
  accountNumber: number;
}): Promise<AccountDetails> => {
  if (USE_MOCK) {
    // Return mock data using setTimeout to simulate network delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: customerAccountDetails });
      }, 5000);
    });
  }

  let queryParams = `?customerId=${customerId}&accountNumber=${accountNumber}`;

  return await getDataWithDotNet(
    `${ajaxEndpoints.DB_INDIVIDUAL_CUSTOMER_ACCOUNT_DETAILS}${queryParams}`,
  );
};

export const useCustomerAccountDetails = ({
  customerId,
  accountNumber,
}: {
  customerId: number;
  accountNumber: number;
}) => {
  return useQuery<AccountDetails, Error>(
    ["getCustomerAccountDetails", customerId, accountNumber],
    () => getCustomerAccountDetails({ customerId, accountNumber }),
    {
      enabled: !!customerId && !!accountNumber,
      // the query will only run if customerId and accountNumber are both truthy
    },
  );
};

export const getIndividualCustomerAuditTrail = async ({
  customerId,
}: {
  customerId: number;
}): Promise<CustomerDataAuditTrail> => {
  let queryParams = `?customerId=${customerId}`;

  return await getDataWithDotNet(
    `${ajaxEndpoints.DB_INDIVIDUAL_CUSTOMER_AUDIT_TRAIL}${queryParams}`,
  );
};

export const useIndividualCustomerAuditTrail = ({
  customerId,
}: {
  customerId: number;
}) => {
  return useQuery<CustomerDataAuditTrail, Error>(
    ["getIndividualCustomerAuditTrail", customerId],
    () => getIndividualCustomerAuditTrail({ customerId }),
  );
};
