import React, { useEffect, useState } from "react";

import ajaxMethods from "../../../api/ajax-methods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { MDBDataTable } from "mdbreact";
import { filterDataByColumns } from "./../../../helpers/tableControllers";
import { Loader } from "../../../UI/Loaders/Loaders";
import { enforceMaxLength } from "../../../helpers/enforceMaxLength";
import { appInsights } from "../../../config/appInsights";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

export const CreditHistory = (props) => {
  const [customer_type, setCustomerType] = useState("Individual");
  const [creditBureau, setCreditBureau] = useState("Credpal");
  const [uniqueNumber, setUniqueNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({});
  const [newTableData, setNewTableData] = useState({});

  useEffect(() => {
    MixPanel.track(`Page View: CreditHistory.tsx`, {
      page: "CreditHistory.tsx",
    });
  }, []);

  const getCreditReport = (e) => {
    e.preventDefault();
    props.clearToast();
    if (!uniqueNumber || !uniqueNumber.length) {
      const customerType =
        (customer_type === "Individual" && "BVN") ||
        (customer_type === "Corporate" && "RC Number");
      return props.onSetToast("error", `Please enter your ${customerType}`);
    }
    setLoading(true);
    setTableData({});
    setNewTableData({});
    let creditBureauEnums;
    if (creditBureau === "CRC") {
      creditBureauEnums = 1;
    } else if (creditBureau === "Credpal") {
      creditBureauEnums = 2;
    } else if (creditBureau === "FIRST CENTRAL") {
      creditBureauEnums = 3;
    }
    ajaxMethods
      .get(
        ajaxEndpoints.GET_CREDIT_REPORT +
          `?uniquenumber=${uniqueNumber.toLowerCase()}&option=${creditBureauEnums}&customer_type=${customer_type.toLowerCase()}`,
      )
      .then((response) => {
        setLoading(false);
        if (response) {
          props.onSetToast("success", "Request successful");
          if (response.data) {
            setTableData(response.data);
            sortTableData(response.data.credithistory);
          }
          if (response.credithistory) {
            setTableData(response);
            sortTableData(response.credithistory);
          }
        } else {
          props.onSetToast("error", "Invalid response, please try again");
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "CreditHistory.js",
          },
        });
        setLoading(false);
        props.onSetToast("error", error.message);
      });
  };

  const sortTableData = (credithistory) => {
    let rows = [];
    credithistory.map((data, index) => {
      let row_data = {
        id: index + 1,
        instituition: data.institutionname,
        overdueamount: data.overdueamount,
        facilitycount: data.facilitycount,
        performingfacility: data.performingfacility,
        nonperformingfacility: data.nonperformingfacility,
        dishonouredchequecount: data.dishonouredchequecount,
        accountbalance: data.accountbalance,
      };
      rows.push(row_data);
      return rows;
    });

    let tableHeader = {
      columns: [
        { field: "id", label: "id", sort: "asc", width: 370 },
        {
          field: "instituition",
          label: "INSTITUTION",
          sort: "asc",
          width: 670,
        },
        {
          field: "overdueamount",
          label: "OVERDUE AMOUNT",
          sort: "asc",
          width: 670,
        },
        {
          field: "facilitycount",
          label: "FACILITY COUNT",
          sort: "asc",
          width: 700,
        },
        {
          field: "performingfacility",
          label: "PERFORMING FACILITY",
          sort: "asc",
          width: 700,
        },
        {
          field: "nonperformingfacility",
          label: "NON PERFORMING FACILITY",
          sort: "asc",
          width: 500,
        },
        {
          field: "dishonouredchequecount",
          label: "DISHONOURED CHEQUE COUNT",
          sort: "asc",
          width: 600,
        },
        {
          field: "accountbalance",
          label: "ACCOUNT BALANCE",
          sort: "asc",
          width: 600,
        },
      ],
      rows: [],
    };

    const columnsToExclude = [];
    let filteredTableData = filterDataByColumns(
      tableHeader,
      "exclude",
      columnsToExclude,
    );
    filteredTableData = {
      columns: [...tableHeader.columns],
      rows: [...rows],
    };
    setNewTableData(filteredTableData);
  };

  const {
    name,
    dob,
    address,
    dateofregistration,
    gender,
    nationality,
    phonenumber,
  } = tableData;

  if (loading)
    return <Loader text="Loading credit history ..." centered={true} />;
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="card animated speed-1x fadeInRight"
          style={{ width: "auto" }}
        >
          <div className="body text-center">
            <h3 className="m-t-0">
              <i className="adv-icon fas fa-cogs" />
              &nbsp; Credit history
            </h3>
            <p className="settings-block text-muted">
              Verify the loan history of a specific client
            </p>

            <form onSubmit={getCreditReport}>
              <label className="label-txt mt-1">Select Customer Type</label>
              <div className="form-group inputWithIcon">
                <select
                  className="round user__form form-control"
                  name="selectedProductId"
                  placeholder="Select Product category"
                  title="Select a Product"
                  id="selectedProductId"
                  value={customer_type}
                  onChange={(e) => setCustomerType(e.target.value)}
                >
                  <option selected>Individual</option>
                  <option>Corporate</option>
                </select>
                <i className="adv-aggregator-icon fas fa-building"></i>
              </div>

              <label className="label-txt mt-3">
                Select a Credit Bureau Provider
              </label>
              <div className="form-group inputWithIcon">
                <select
                  className="round user__form form-control"
                  name="selectedProductId"
                  placeholder="Select Product category"
                  title="Select a Product"
                  id="selectedProductId"
                  value={creditBureau}
                  onChange={(e) => setCreditBureau(e.target.value)}
                >
                  <option selected>Credpal</option>
                  <option>CRC</option>
                  <option>FIRST CENTRAL</option>
                </select>
                <i className="adv-aggregator-icon fas fa-building"></i>
              </div>

              <div>
                <label className="label-txt mt-3">
                  {(customer_type === "Individual" &&
                    "Enter Bank Verification Number") ||
                    (customer_type === "Corporate" && "Enter RC Number")}
                </label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="user__form form-control"
                    placeholder={
                      (customer_type === "Individual" && "BVN") ||
                      (customer_type === "Corporate" && "RC Number")
                    }
                    required
                    autoComplete="off"
                    name="bvn"
                    value={uniqueNumber}
                    // data-form="investment"
                    onChange={(e) => setUniqueNumber(e.target.value)}
                    onKeyPress={enforceMaxLength}
                    maxLength="20"
                  />
                  <i className="fas fa-lock" />
                </div>
              </div>

              {/* <div className="p-l-0 grid__padding"> */}

              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="submit"
                  className="btn login-btn"
                  data-form="investment"
                  disabled={loading}
                >
                  <i className="fas fa-share" />
                  &nbsp; Send
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* </div> */}

        {Object.keys(tableData).length > 0 && (
          <div
            className="card animated speed-1x fadeInRight"
            style={{ width: "auto" }}
          >
            <div className="body text-center">
              <h3 className="m-t-0 mb-3">
                <i className="adv-icon fas fa-cogs" />
                &nbsp;Details
              </h3>

              <div className="row col-md-12">
                <div>
                  <div className="col-md-6">
                    <label className="label-txt">Name</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        className="user__form form-control"
                        value={name ? name : "Name not found"}
                        disabled
                      />
                      <i className="fas fa-lock" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="label-txt">Phone Number</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        className="user__form form-control"
                        value={
                          phonenumber ? phonenumber : "Phone number not found"
                        }
                        disabled
                      />
                      <i className="fas fa-lock" />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="col-md-6">
                    <label className="label-txt">Date of Birth</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        className="user__form form-control"
                        value={dob ? dob : "Date of Birth not found"}
                        disabled
                      />
                      <i className="fas fa-lock" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="label-txt">Gender</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        className="user__form form-control"
                        value={gender ? gender : "Gender not found"}
                        disabled
                      />
                      <i className="fas fa-lock" />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="col-md-6">
                    <label className="label-txt">Date of Registration</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        className="user__form form-control"
                        value={
                          dateofregistration
                            ? dateofregistration
                            : "Registration Date not found"
                        }
                        disabled
                      />
                      <i className="fas fa-lock" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="label-txt">Nationality</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        className="user__form form-control"
                        value={
                          nationality ? nationality : "Nationality not found"
                        }
                        disabled
                      />
                      <i className="fas fa-lock" />
                    </div>
                  </div>
                </div>

                <div>
                  <label className="label-txt">address</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={address ? address : "Address not found"}
                      disabled
                    />
                    <i className="fas fa-lock" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {!loading && Object.keys(tableData).length > 0 && (
        <div className="grid__padding animated speed-1x fadeInRight">
          <MDBDataTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            data={newTableData}
          />
        </div>
      )}
    </div>
  );
};
