import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";
import { showModal, hideModal } from "../../redux/actions/modal";
import { postDataWithDotNet } from "../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { errorHandler } from "../../helpers/errorHandler";
import Alert from "../../NewComponents/TypedComponents/Alert/Alert.component";
import { useQueryCache } from "react-query";
import CustomSelectDropdown from "../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import useAllWallet from "../../custom-hooks/useAllWallet";
import { appInsights } from "../../config/appInsights";
import { MixPanel } from "../../shared/MixPanel/MixPanel";

const WalletTransactions = ({ aggregatorId }: any) => {
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    consumerType: "Aggregator",
    aggregatorId,
  });

  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [signUpError, setSignUpError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { data, status, error, refetch } = useAllWallet(state);

  const dispatch = useDispatch();

  const queryCache = useQueryCache();
  if (data) {
    if (count === null) {
      setCount(data?.totalCount);
    }
  }

  useEffect(() => {
    MixPanel.track(`Page View: Wallets.tsx`, {
      page: "Wallets.tsx",
    });
  }, []);

  const modalHandler = (modalData: any, type: string) => {
    dispatch(
      showModal({
        modalIdentifier: "wallet",
        dataRecord: modalData,
        action: "createwallet",
        type,
        aggregator_Id: aggregatorId,
        onSubmit,
      }),
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onSubmit = async ({ aggregatorId, currency }: any) => {
    setSignUpError("");
    setLoading(true);
    let reqBody = {
      currency: currency,
      targetId: Number(aggregatorId),
      consumerType: "Aggregator",
    };

    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.CREATE_WALLET,
        reqBody,
      );

      setLoading(false);
      queryCache.invalidateQueries("getDefaultWalletProvider");
      dispatch(hideModal());
      refetch();
      setSuccessMessage(response.message);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AllWallet.jsx",
        },
      });
      queryCache.invalidateQueries("getDefaultWalletProvider");
      dispatch(hideModal());
      setLoading(false);
      setSignUpError(errorHandler(error));
    }
  };

  let RenderedComponent = null;
  if (!data?.data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Wallet
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = <Loader centered={true} text="Loading wallet..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              wallet, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data?.data && data?.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no wallet.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Wallet ID</th>
                  <th>Account Name</th>
                  <th>Account Number</th>
                  <th>Bank Name</th>
                  <th>Currency</th>
                  <th>Wallet Provider</th>
                  <th>IsDefault</th>

                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data.map((request: any, _id: number) => {
                    const {
                      accountName,
                      accountNumber,
                      bankName,
                      currency,
                      walletId,
                      walletProvider,
                      isDefault,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>{_id + 1}</td>
                        <td>{walletId}</td>
                        <td>{accountName}</td>
                        <td>{accountNumber}</td>
                        <td>{bankName}</td>
                        <td>{currency}</td>
                        <td>{walletProvider}</td>
                        <td>{isDefault ? "True" : "False"}</td>
                        <td>
                          <Link
                            to={{
                              pathname: `/wallet-transactions/${walletId}`,
                              state: {
                                data: request,
                                consumerType: state.consumerType,
                              },
                            }}
                            className="color-blue"
                          >
                            View Details
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="dataTables_wrapper">
        {signUpError && <Alert message={signUpError} />}
        {successMessage && <Alert message={successMessage} type="success" />}
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-4">
            {!aggregatorId ? (
              <div
                style={{ width: "150px", marginBottom: "10px" }}
                className="mr-3"
              >
                <CustomSelectDropdown
                  label=""
                  onChange={handleChange}
                  name="consumerType"
                  value={state.consumerType}
                  className="round user__form form-control"
                >
                  <option value="1">Aggregator</option>
                  <option value="2">Customer/Investor</option>
                </CustomSelectDropdown>
              </div>
            ) : (
              <div></div>
            )}
            <button
              type="button"
              className="btn advancly-white-btn btn-md m-0"
              onClick={(e) => {
                modalHandler("", "create");
              }}
            >
              Create Wallet
            </button>
          </div>

          {/*Datatable || Loader || Error Message*/}
          {RenderedComponent}
          {data?.data && (
            <Paginator
              size={state.size}
              page={state.pageNumber}
              count={count}
              changeCurrentPage={(pageNumber: number) =>
                setState({ ...state, pageNumber })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletTransactions;
