import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { USE_MOCK } from "../appConstants";
import { IResponse } from "../interfaces/response";
import { IBusinessCustomerDetail } from "../components/DigitalBank/Interfaces";

export type TBusinessCustomerDetailParams = {
  customerId: number;
};

export const getBusinessCustomerDetail = async ({
  customerId,
}: TBusinessCustomerDetailParams): Promise<
  IResponse<IBusinessCustomerDetail>
> => {
  if (USE_MOCK) {
    // Return mock data using setTimeout to simulate network delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          message: "Requests fetched successfully!",
          status: true,
          status_code: 200,
          data: {
            accounts: null,
            seniorManagement: null,
            businessOwnerInformation: {
              dateOfBirth: "2001-07-20T00:00:00",
              countryCode: "RW",
              address: "Tachikawa",
              bvn: null,
              city: "Tachikawa",
              state: "Northern Province",
              identityNumber: "22184846605",
              passportUrl: null,
              firstName: "Yanskde",
              lastName: "Usienr",
              email: null,
              phoneNumber: null,
              gender: "Female",
            },
            partnersInformation: null,
            contactPerson: {
              dateOfBirth: null,
              state: null,
              identificationNumber: null,
              firstName: "Yonda",
              lastName: "Wayne",
              email: "yonway@yopmail.com",
              phoneNumber: "07051643484",
              gender: "",
            },
            businessInformation: {
              account_activation_date: "2024-07-18T13:02:16.046017",
              aggregator_id: 1359,
              biz_name: "John Doe Inc",
              biz_registration_number: "RC00000000",
              biz_registration_type: 0,
              biz_email: "yonway@yopmail.com",
              biz_phone: "07051643484",
              biz_phone_dialcode: "+234",
              biz_Address: "1ST FLOOR,SUM HOUSE,Fake Street",
              biz_category: "health & hospitals",
              business_type: "RegisteredBusiness",
              business_type_id: 2,
              biz_description: "manna",
              sector_id: 3,
              country_code: "NG",
              computed_score: 0,
              status: null,
              comment: null,
              onboarding_status: "LegalInProgress",
              approved_declined_by: null,
              approved_declined_date: null,
              onboarding_date: "2024-07-18T13:02:16.046017",
              first_name: null,
              last_name: null,
              personal_email: "yonway@yopmail.com",
              biz_short_name: null,
              biz_overview: null,
              city: "YABA",
              state: "Enugu State",
              country: "NG",
              tax_identification_number: "xc43467",
              onboarding_request_date: "2024-07-18T13:02:16.046017",
            },
            businessDocumentation: {
              businessDocuments: [
                {
                  documentId: 1,
                  documentName: "Certificate of Incorporation",
                  documentType: "undefined",
                  fileName: "simple_report_pdf_180720240237469189.pdf",
                  documentUrl:
                    "documents/onboarding/john_doe_inc/simple_report_pdf_180720240237469189.pdf",
                  documentCode: "CAC",
                  status: {
                    id: 1,
                    name: "Pending",
                    description: null,
                  },
                },
                {
                  documentId: 2,
                  documentName: "Proof of Address Corporate",
                  documentType: "Proof of Address",
                  fileName: "simple_report_pdf_180720240237469375.pdf",
                  documentUrl:
                    "documents/onboarding/john_doe_inc/simple_report_pdf_180720240237469375.pdf",
                  documentCode: "PAC",
                  status: {
                    id: 1,
                    name: "Pending",
                    description: null,
                  },
                },
              ],
              rcNumber: "RC00000000",
              taxIdentificationNumber: "xc43467",
            },
            registeredTrustees: [
              {
                countryCode: "KE",
                identityNumber: null,
                govtIdentityType: "DiverLicense",
                proofOfAddressFilePath:
                  "documents/Moris/img_2_1717660051322_jpg_190720240118566608.jpg",
                passportUrl: null,
                periodInCompanyBoard: 10,
                experienceRelevantToRole: "5",
                educationalQualification: "6",
                governmentIdFilePath:
                  "documents/Moris/img_6_1717660285628_jpg_190720240118559731.jpg",
                workExperience: "55",
                firstName: "Moris",
                lastName: "Beans",
                email: "linda@yopmail.con",
                phoneNumber: null,
                gender: "Male",
              },
            ],
            directorsDetails: [
              {
                bvn: null,
                countryCode: "RW",
                identityNumber: "27272626721",
                govtIdentityType: "NationalIdentificationNumber",
                proofOfAddressFilePath:
                  "documents/Daniel/img_6_1717660285628_jpg_190720240204249747.jpg",
                passportUrl: null,
                periodInCompanyBoard: 8,
                experienceRelevantToRole: "6",
                educationalQualification: "1",
                governmentIdFilePath:
                  "documents/Daniel/img_3_1717660055083_jpg_190720240204249076.jpg",
                workExperience: "7",
                firstName: "Daniel",
                lastName: "Shedrach",
                email: "au@yopmail.com",
                phoneNumber: null,
                gender: "Male",
                funderPersonId: 0,
                governmentIdApprovalStatus: "Pending",
                proofOfAddressApprovalStatus: "",
              },
            ],
            shareHolders: [
              {
                shareholderType: "Corporate",
                firstname: "Imamat Isiaka",
                lastname: "",
                numberOfShares: 8,
                currency: "NGN",
                nationality: null,
                amount: 10,
                percentageShareholding: 2,
              },
            ],
            additionalInformation: null,
            auditTrail: [
              {
                actionDate: "2024-07-16T16:03:35.343762",
                status: "",
                comment:
                  "Onboarding document uploaded by customer john_doe_inc",
                action: "Aggregator Onboarding Document Upload",
                startedBy: "",
                completedBy: "chucks@yopmail.com",
              },
            ],
          },
        });
      }, 5000);
    });
  }

  const { data } = await getData(
    `${ajaxEndpoints.DB_BUSINESS_CUSTOMERS_DETAILS}?customerId=${customerId}`
  );

  return data;
};

export const useBusinessCustomerDetail = (
  params: TBusinessCustomerDetailParams
) => {
  return useQuery<IResponse<IBusinessCustomerDetail>, Error>(
    ["getBusinessCustomerDetail", params],
    () => getBusinessCustomerDetail(params)
  );
};
