import React, { Component } from "react";
import "../../Issues/IssuesModal.css";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

export default class ViewRepayment extends Component {
  state = { scene: "form" };

  printHandler = () => {};

  cancelHandler = () => {};

  componentDidMount() {
    MixPanel.track(`Page View: ViewRepayment.tsx`, {
      page: "ViewRepayment.tsx",
    });
  }

  render() {
    const dataRecord = this.props.dataRecord;

    const {
      repayment_id,
      borrower_full_name,
      aggregator_name,
      repayment_amount,
      loan_due_date,
    } = dataRecord;

    return (
      <div className="Modal__dialog animated slideInDown" role="document">
        <div
          className="IssueModal Modal__content"
          style={{ overflow: "hidden" }}
        >
          <div className="Modal__body">
            <h3 className="Modal__title p-b-2">
              Repayment id: <span>{repayment_id}</span>
            </h3>

            <form>
              <label className="label-txt">Borrower Full Name</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={borrower_full_name}
                />
                <i className="far fa-calendar-alt" />
              </div>

              <label className="label-txt">Aggregator Name</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  name="cardNumber"
                  className="user__form form-control"
                  value={aggregator_name}
                />
                <i className="far fa-credit-card" />
              </div>

              <label className="label-txt">Loan Due Date</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={loan_due_date}
                />
                <i className="fas fa-user-circle" />
              </div>

              <label className="label-txt">Transaction Amount</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={repayment_amount}
                />
                <i className="fas fa-money" />
              </div>

              <div className="Form__action-buttons">
                {/* <button type="button"
                                    className="btn zoom-element btn login-btn m-0 mr-auto"
                                    onClick={this.proceedHandler}>Print</button> */}
                <button
                  type="button"
                  className="btn zoom-element btn login-btn"
                  onClick={this.props.onCloseModal}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
