import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Button, Row, Col } from "reactstrap";
import { appInsights } from "../../../../config/appInsights";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import { currencyFormatter } from "../../../../helpers/stringControllers";
import ToggleTab from "../../../../NewComponents/ToggleTab/ToggleTab.component";
import moment from "moment";
import { useLocation, useRouteMatch } from "react-router-dom";
import { CustomerData } from "../../../../interfaces/individualCustomer";
import {
  useCustomerAccountDetails,
  useIndividualCustomerDetails,
} from "../../../../custom-hooks/useIndividualCustomer";
import { Loader } from "../../../../UI/Loaders/Loaders";

const IndividualCustomerAccountDetails: React.FC<any> = ({ account }) => {
  let location: any = useLocation();
  let { accountNumber, accountName } = location?.state;

  const match = useRouteMatch();
  const { id } = match?.params as { id: string };

  const [activeTab, setActiveTab] = useState("general");

  const {
    data: details,
    isLoading,
    isError,
  } = useCustomerAccountDetails({
    customerId: Number(id),
    accountNumber: accountNumber,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "IndividualCustomerAccountDetails",
      isLoggedIn: true,
    });
  }, []);

  if (isLoading)
    return (
      <Loader centered={true} text="Loading customer account details..." />
    );

  if (isError)
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            customer account details, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Go back
        </h3>
      </div>
      <br />
      <h4 className="page-subtitle advancly-primary-text">{accountName}</h4>
      <br />

      <Row>
        <Col md={3}>
          <div className="card">
            <div className="card-body">
              <p>Product</p>
              <h4 className="page-subtitle advancly-primary-text">
                {details?.data?.product}
              </h4>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="card">
            <div className="card-body">
              <p>Account Number</p>
              <h4 className="page-subtitle advancly-primary-text">
                {details?.data?.accountNumber}
              </h4>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="card">
            <div className="card-body">
              <p>Available Balance</p>
              <h4 className="page-subtitle advancly-primary-text">
                {currencyFormatter(Number(details?.data?.availableBalance))}
              </h4>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="card">
            <div className="card-body">
              <p>Current Balance</p>
              <h4 className="page-subtitle advancly-primary-text">
                {currencyFormatter(Number(details?.data?.currentBalance))}
              </h4>
            </div>
          </div>
        </Col>
      </Row>
      <br />

      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="General"
          id="general"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      <br />
      <div>
        {activeTab === "general" && (
          <>
            <Row>
              <Col md={6}>
                <div className="card">
                  <div className="card-header font-weight-bold">
                    Savings Details
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p>Activated On:</p>
                      <p className="font-weight-bold">
                        {details?.data?.activatedOn}
                      </p>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <p>Officer Assigned:</p>
                      <p className="font-weight-bold">
                        {details?.data?.officerAssigned}
                      </p>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <p>Total Interest Earned:</p>
                      <p className="font-weight-bold">
                        {currencyFormatter(
                          Number(details?.data?.totalInterestEarned),
                          "NGN",
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="card">
                  <div className="card-header font-weight-bold">
                    Account Summary
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p>Total Withdrawals:</p>
                      <p className="font-weight-bold">
                        {currencyFormatter(
                          Number(details?.data?.totalWithdrawals),
                          "NGN",
                        )}
                      </p>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <p>Interest Earned:</p>
                      <p className="font-weight-bold">
                        {currencyFormatter(
                          Number(details?.data?.interestEarned),
                          "NGN",
                        )}
                      </p>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <p>Nominal Interest Rate:</p>
                      <p className="font-weight-bold">
                        {details?.data?.nominalInterestRate}%
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div className="card">
                  <div className="card-header font-weight-bold">
                    Performance History
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p>Total Deposits:</p>
                      <p className="font-weight-bold">
                        {currencyFormatter(
                          Number(details?.data?.totalDeposits),
                          "NGN",
                        )}
                      </p>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <p>Total Interest Earned:</p>
                      <p className="font-weight-bold">
                        {currencyFormatter(
                          Number(details?.data?.totalInterestEarned),
                          "NGN",
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default IndividualCustomerAccountDetails;
