import React, { useState } from "react";
import { postData } from "../../../../../newApis/apiMethods";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import styles from "./checkRollover.module.scss";
import { useQueryCache } from "react-query";
import Alert from "../../../../../NewComponents/Alert/Alert.component";
const ApproveOrDeclineRolloverConfirmationChecker = ({
  action,
  setcurrentStep,
  onCloseModal,
  currentComments,
  loanRef,
  rolloverMakerCheckerReference,
}: any) => {
  const [rolloverLoading, setRolloverLoading] = useState(false);
  const [rolloverSuccess, setRolloverSuccess] = useState(null);
  const [error, setError] = useState<string | null>(null);
  const queryCache = useQueryCache();

  const handleApproveOrDeclineRolloverChecker = async () => {
    setRolloverLoading(true);
    try {
      const payload = {
        loanRef: loanRef,
        rolloverMakerCheckerReference: rolloverMakerCheckerReference,
        comment: currentComments,
      };

      if (action === "approve") {
        const response = await postData(
          `${ajaxEndpoints.APPROVE_LOAN_ROLLOVER_BY_CHECKER}`,
          payload
        );

        if (response?.status_code === 200) {
          setRolloverLoading(false);
          setRolloverSuccess(response?.message);
          queryCache.invalidateQueries("getusePendingRolloverRequest");
          setTimeout(() => {
            onCloseModal();
          }, 3000);
        }
      } else {
        const response = await postData(
          `${ajaxEndpoints.DECLINE_LOAN_ROLLOVER_BY_CHECKER}`,
          payload
        );

        if (response?.status_code === 200) {
          setRolloverLoading(false);
          setRolloverSuccess(response?.message);
          queryCache.invalidateQueries("getusePendingRolloverRequest");
          setTimeout(() => {
            onCloseModal();
          }, 3000);
        }
      }
    } catch (error: any) {
      setError(error?.response?.data?.message);
    }
    setRolloverLoading(false);
  };
  const handleBackButton = () => {
    setcurrentStep(1);
  };
  return (
    <div>
      {rolloverSuccess && <Alert message={rolloverSuccess} type="success" />}
      {error && <Alert message={error} />}
      <div>
        <p
          className="cursor-pointer font-weight-bold mb-3"
          onClick={handleBackButton}
        >
          {" "}
          <i className="fas fa-arrow-left mr-2" /> Go Back
        </p>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p>{`Are you sure you want to ${action} Roll over?`}</p>
        <div className="d-flex justify-content-between">
          <div className={styles.rolloverTakeActionButton}>
            <button
              type="button"
              className="btn advancly-btn btn-md"
              onClick={handleApproveOrDeclineRolloverChecker}
            >
              {rolloverLoading ? "Loading..." : "Yes"}
            </button>
          </div>
          <div className="">
            <button
              className="btn      advancly-nobg-btn
 btn-md  "
              onClick={() => onCloseModal()}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveOrDeclineRolloverConfirmationChecker;
