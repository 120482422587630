import { ChangeEvent, useState } from "react";
import styles from "./BankStatementFormatDetailsModal.module.scss";
import closeIcon from "../../../assets/svg/icons/Close.svg";
import { BankStatementFormat } from "../../../helpers/bankStatementFormat";
import {
  postBankStatementFormats,
  updateBankStatementFormats,
} from "../../../newApis/dotNetApiMethods";
import * as apiEndpoints from "../../../api/ajax-endpoints";
import { message } from "antd";

interface BankStatementDetailsProps {
  bankStatement: BankStatementFormat;
  onClose: () => void;
  isLoadingUpdate: boolean;
  setIsLoadingUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingCreate: boolean;
  setIsLoadingCreate: React.Dispatch<React.SetStateAction<boolean>>;
  create: boolean;
  refetchBankStatementFormat: () => void;
}

function BankStatementFormatDetailsModal({
  onClose,
  bankStatement,
  isLoadingUpdate,
  setIsLoadingUpdate,
  isLoadingCreate,
  setIsLoadingCreate,
  create,
  refetchBankStatementFormat,
}: BankStatementDetailsProps) {
  const [formValues, setFormValues] = useState({
    id: bankStatement?.id,
    bank_name: bankStatement?.bank_name,
    country_code: bankStatement?.country_code,
    statement_identifier: bankStatement?.statement_identifier,
    statement_identifier_area: JSON.stringify(
      bankStatement.statement_identifier_area,
      null,
      2,
    ),
    extraction_settings: JSON.stringify(
      bankStatement.extraction_settings,
      null,
      2,
    ),
  });

  function handleInputChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name, value } = event.target;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const updateFormat = async () => {
    setIsLoadingUpdate(true);
    const reqBody = {
      id: formValues.id,
      bank_name: formValues.bank_name,
      country_code: formValues.country_code,
      statement_identifier: formValues.statement_identifier,
      statement_identifier_area: JSON.parse(
        formValues.statement_identifier_area,
      ),
      extraction_settings: JSON.parse(formValues.extraction_settings),
    };
    try {
      await updateBankStatementFormats(
        apiEndpoints.UPDATE_BANK_STATEMENT_FORMATS,
        reqBody,
      );
      message.success("Bank Statement Format updated Successfully");
      refetchBankStatementFormat();
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const createFormat = async () => {
    setIsLoadingCreate(true);
    const reqBody = {
      bank_name: formValues.bank_name,
      country_code: formValues.country_code,
      statement_identifier: formValues.statement_identifier,
      statement_identifier_area: JSON.parse(
        formValues.statement_identifier_area,
      ),
      extraction_settings: JSON.parse(formValues.extraction_settings),
    };
    try {
      await postBankStatementFormats(
        apiEndpoints.CREATE_BANK_STATEMENT_FORMATS,
        reqBody,
      );
      message.success("Bank Statement Format created Successfully");
      refetchBankStatementFormat();
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCreate(false);
    }
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        <h4>{formValues?.bank_name ? formValues?.bank_name : "New"} Format</h4>
        <div className={styles.form_container}>
          <div className={styles.form_item}>
            <label>ID</label>
            <input
              placeholder="id"
              name="id"
              value={formValues?.id}
              onChange={handleInputChange}
              disabled
            />
          </div>
          <div className={styles.form_item}>
            <label>Bank Name</label>
            <input
              placeholder="bank name"
              name="bank_name"
              value={formValues?.bank_name}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.form_item}>
            <label>Country Code</label>
            <input
              placeholder="Country Code"
              name="country_code"
              value={formValues?.country_code}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.form_item}>
            <label>Statement Identifier</label>
            <input
              placeholder="statement_identifier"
              name="statement_identifier"
              value={formValues?.statement_identifier}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.form_item}>
            <label>Statement Identifier Area</label>
            <textarea
              placeholder="statement_identifier_area"
              name="statement_identifier_area"
              value={formValues?.statement_identifier_area}
              rows={10}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.form_item}>
            <label>Extraction Settings</label>
            <textarea
              placeholder="extraction_settings"
              name="extraction_settings"
              value={formValues?.extraction_settings}
              rows={20}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.btn_container}>
            {!create ? (
              <button
                type="submit"
                className={styles.btn}
                onClick={updateFormat}
                disabled={isLoadingUpdate}
              >
                {isLoadingUpdate ? "Updating..." : "Update"}
              </button>
            ) : (
              <button
                type="submit"
                className={styles.btn}
                onClick={createFormat}
                disabled={isLoadingCreate}
              >
                {isLoadingCreate ? "Creating new format" : "Create"}
              </button>
            )}
          </div>
        </div>

        <img
          src={closeIcon}
          alt=""
          className={styles.cancel_button}
          onClick={onClose}
        />
      </div>
    </>
  );
}

export default BankStatementFormatDetailsModal;
