import { useEffect } from "react";

import { appInsights } from "../../../../config/appInsights";
import useFundingRequestRepaymentSchedule from "../../../../custom-hooks/useFundingRequestRepaymentSchedule";
import { Loader } from "../../../../UI/Loaders/Loaders";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { currencyFormatter } from "../../../../helpers/stringControllers";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";
import { getObjectFromLocalStorage } from "../../../../helpers/localStorage";
import { StorageKeys } from "../../../../helpers/storageKeys";

import styles from "./repaymentschedule.module.scss";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const FundingRequestRepaymentSchedule = ({
  properties: { dataRecord },
  onCloseModal,
}: any) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "FundingRequestRepaymentSchedule",
      isLoggedIn: true,
    });
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    MixPanel.track(`Page View: FundingRequestRepaymentSchedule.tsx`, {
      page: "FundingRequestRepaymentSchedule.tsx",
    });
  }, []);

  const {
    data: repaymentSchedule,
    status,
    error,
  } = useFundingRequestRepaymentSchedule({
    loanRef: dataRecord?.loanReference!,
  });

  let RenderedComponent = null;
  if (!repaymentSchedule && status === "error") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Generate Loan Schedule
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Fetching loan schedule..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              borrower's loans, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      repaymentSchedule?.data &&
      repaymentSchedule?.data.repaymentSchedule.periods.length === 0
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no loans for
              this borrower
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <MDBTable responsive minimal="true" bordered hover>
          <MDBTableHead>
            <tr>
              <th>S/N</th>
              <th>Days In Period</th>
              <th>Due Date</th>
              <th>Loan balance Outstanding</th>
              <th>Principal Disbursed</th>
              <th>Principal Due</th>
              <th>Interest Due</th>
              <th>Total Due for Period</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {repaymentSchedule?.data &&
              repaymentSchedule?.data?.repaymentSchedule &&
              repaymentSchedule?.data.repaymentSchedule.periods.map(
                (loanSchedule, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{loanSchedule.daysInPeriod}</td>
                      <td>
                        <div style={{ width: "100px" }}>
                          {loanSchedule.dueDate.join("-")}
                        </div>
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loanSchedule.principalLoanBalanceOutstanding),
                          repaymentSchedule?.data?.repaymentSchedule.currency
                            ?.code,
                        )}
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loanSchedule.principalDisbursed),
                          repaymentSchedule?.data?.repaymentSchedule.currency
                            ?.code,
                        )}
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loanSchedule.principalDue),
                          repaymentSchedule?.data?.repaymentSchedule.currency
                            ?.code,
                        )}
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loanSchedule.interestDue),
                          repaymentSchedule?.data?.repaymentSchedule.currency
                            ?.code,
                        )}
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loanSchedule.totalDueForPeriod),
                          repaymentSchedule?.data?.repaymentSchedule.currency
                            ?.code,
                        )}
                      </td>
                    </tr>
                  );
                },
              )}
            <tr>
              <td className="total">Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td className="total">
                {currencyFormatter(
                  Number(
                    repaymentSchedule?.data.repaymentSchedule
                      ?.totalPrincipalDisbursed,
                  ),
                  repaymentSchedule?.data?.repaymentSchedule.currency?.code,
                )}
              </td>
              <td className="total">
                {currencyFormatter(
                  Number(
                    repaymentSchedule?.data.repaymentSchedule
                      ?.totalPrincipalExpected,
                  ),
                  repaymentSchedule?.data?.repaymentSchedule.currency?.code,
                )}
              </td>
              <td className="total">
                {currencyFormatter(
                  Number(
                    repaymentSchedule?.data.repaymentSchedule
                      ?.totalInterestCharged,
                  ),
                  repaymentSchedule?.data?.repaymentSchedule.currency?.code,
                )}
              </td>
              <td className="total">
                {currencyFormatter(
                  Number(
                    repaymentSchedule?.data.repaymentSchedule
                      ?.totalRepaymentExpected,
                  ),
                  repaymentSchedule?.data?.repaymentSchedule.currency?.code,
                )}
              </td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      );
    }
  }

  return (
    <div
      className={`Modal__dialog md-modal animated slideInDown ${styles.FundingWalletDetails}`}
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1 page-title d-flex align-items-center">
            <BackButtonComponent
              customGoBack={() => {
                dispatch(
                  showModal({
                    modalIdentifier: "fundingwalletdetails",
                    dataRecord: getObjectFromLocalStorage(
                      StorageKeys.FUNDING_WALLET_DETAILS,
                    ),
                  }),
                );
              }}
            />
            Repayment Schedule
          </h3>
          <div className={styles.WalletTransactions}>{RenderedComponent}</div>
        </div>
      </div>
    </div>
  );
};

export default FundingRequestRepaymentSchedule;
