import { useDispatch } from "react-redux";

import userImage from "../../../../assets/svg/icons/user-profile-image.svg";
import styles from "./bvnAndNinInformation.module.scss";
import {
  IBvnDetails,
  INinDetails,
} from "../../../../custom-hooks/usePendingTierUpgradeRequests";
import { ADVANCLY_STORAGE_URL } from "../../../../appConstants";

interface IBvnAndNinInformationModalProps {
  dataRecord: {
    ninDetails: INinDetails;
    bvnDetails: IBvnDetails;
    _picturePath: string;
  };
  onCloseModal: () => void;
}

const BvnAndNinInformationModal = ({
  dataRecord,
  onCloseModal,
}: IBvnAndNinInformationModalProps) => {
  const dispatch = useDispatch();

  const { bvnDetails, ninDetails, _picturePath } = dataRecord;

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1"></h3>
          <div className={styles.bvnAndNinInformation}>
            <div className={styles.cardInformation}>
              <h1>NIN Information</h1>
              <div className="mt-3 mb-4 d-flex justify-content-center">
                <img
                  className={styles.userImage}
                  src={
                    _picturePath
                      ? `${ADVANCLY_STORAGE_URL}${_picturePath}`
                      : userImage
                  }
                  alt="users-image"
                  onClick={() =>
                    _picturePath &&
                    window.open(
                      `${ADVANCLY_STORAGE_URL}${_picturePath}`,
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.cardItem}>
                <p>First Name: {ninDetails?.firstName}</p>
              </div>
              <div className={styles.cardItem}>
                <p>Middle Name: {ninDetails?.middleName}</p>
              </div>{" "}
              <div className={styles.cardItem}>
                <p>Last Name: {ninDetails?.lastName}</p>
              </div>
              <div className={styles.cardItem}>
                <p>Date of Birth: {ninDetails?.dateOfBirth}</p>
              </div>
              <div className={styles.cardItem}>
                <p>Age: {ninDetails?.age}</p>
              </div>
              <div className={styles.cardItem}>
                <p>Sex: {ninDetails?.gender}</p>
              </div>
              <div className={styles.cardItem}>
                <p>Nationality: {ninDetails?.birthCountry}</p>
              </div>
            </div>
            <div className={styles.cardInformation}>
              <h1>BVN Information</h1>
              <div className="mt-3 mb-4 d-flex justify-content-center">
                <img
                  className={styles.userImage}
                  src={
                    _picturePath
                      ? `${ADVANCLY_STORAGE_URL}${_picturePath}`
                      : userImage
                  }
                  alt="users-image"
                  onClick={() =>
                    _picturePath &&
                    window.open(
                      `${ADVANCLY_STORAGE_URL}${_picturePath}`,
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.cardItem}>
                <p>First Name: {bvnDetails?.firstName}</p>
              </div>
              <div className={styles.cardItem}>
                <p>Middle Name: {bvnDetails?.middleName}</p>
              </div>{" "}
              <div className={styles.cardItem}>
                <p>Last Name: {bvnDetails?.lastName}</p>
              </div>
              <div className={styles.cardItem}>
                <p>Date of Birth: {bvnDetails?.dateOfBirth}</p>
              </div>
              <div className={styles.cardItem}>
                <p>Age: {bvnDetails?.age}</p>
              </div>
              <div className={styles.cardItem}>
                <p>Sex: {bvnDetails?.gender}</p>
              </div>
              <div className={styles.cardItem}>
                <p>Nationality: {bvnDetails?.birthCountry}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BvnAndNinInformationModal;
