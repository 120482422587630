import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import { Loader } from "../../../UI/Loaders/Loaders";
import { appInsights } from "../../../config/appInsights";
import Paginator from "../../Pagination/Pagination.component";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import { useBusinessCustomers } from "../../../custom-hooks/useBusinessCustomers";
import { showModal } from "../../../redux/actions/modal";
import { RootState } from "../../../redux/store";
import { setAdvancedCustomerSearchParams } from "../../../redux/actions/digitalBank";
import moment from "moment";

const BusinessCustomers = () => {
  const [query, setQuery] = useState({
    searchQuery: "",
    page: 1,
    pageSize: 10,
    searchValue: "",
    startDate: "",
    endDate: "",
  });
  const [state, setState] = useState({
    searchQuery: "",
    page: 1,
    pageSize: 10,
    searchValue: "",
    startDate: "",
    endDate: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const searchParams = useSelector(
    (state: RootState) => state?.digitalBankReducer,
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "BusinessCustomers.tsx",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: businessCustomers,
    status,
    error,
  } = useBusinessCustomers({
    pageNumber: query?.page,
    size: query?.pageSize,
    accountNumber: searchParams?.accountNumber,
    businessType: searchParams?.businessType,
    email: searchParams?.email,
    endDate: searchParams?.endDate ? searchParams?.endDate : query?.endDate,
    name: searchParams?.name,
    phoneNumber: searchParams?.phoneNumber,
    rcNumber: searchParams?.rcNumber,
    startDate: searchParams?.startDate
      ? searchParams?.startDate
      : query?.startDate,
  });

  let RenderedComponent = null;
  if (!businessCustomers && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Business Customers
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Business Customers..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Business Customers, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (businessCustomers &&
        businessCustomers.data &&
        businessCustomers.data.length === 0) ||
      (businessCustomers && !businessCustomers.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Business
              Customers
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Business Name</th>
                <th>Business Type</th>
                <th>Industry/Sector</th>
                <th>Business Email</th>
                <th>RC Number</th>
                <th>Request Date</th>
                <th>Onboarding Request Date</th>
                <th>Account Activation Date</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {businessCustomers &&
                businessCustomers.data.map((data, _id: number) => {
                  const {
                    businessEmail,
                    businessName,
                    businessType,
                    sector,
                    rcNumber,
                    requestDate,
                    onboardingRequestDate,
                    accountActivationDate,
                  } = data;
                  return (
                    <tr key={_id}>
                      <td>
                        {" "}
                        {(query?.page - 1) * query?.pageSize + (_id + 1)}
                      </td>
                      <td>{businessName}</td>
                      <td>{businessType}</td>
                      <td>{sector}</td>
                      <td>{businessEmail}</td>
                      <td>{rcNumber}</td>
                      <td>
                        {requestDate
                          ? moment(requestDate).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      <td>
                        {onboardingRequestDate
                          ? moment(onboardingRequestDate).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      <td>
                        {accountActivationDate
                          ? moment(accountActivationDate).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      <td>
                        <span
                          className="color-mid-blue cursor-pointer font-weight-bold"
                          onClick={() => {
                            history.push({
                              pathname: `/db/business-customers/${data?.id}`,
                            });
                          }}
                        >
                          {" "}
                          View Details
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setState((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const filterData = () => {
    setQuery(state);
  };

  const handleClear = () => {
    setQuery({
      searchQuery: "",
      page: 1,
      pageSize: 10,
      searchValue: "",
      startDate: "",
      endDate: "",
    });
    dispatch(
      setAdvancedCustomerSearchParams({
        email: "",
        name: "",
        phoneNumber: "",
        accountNumber: "",
        rcNumber: "",
        businessType: "",
        endDate: "",
        startDate: "",
      }),
    );
  };

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x animated fadeInRight">
        <h3 className="page-title mb-4">Business Customers</h3>

        <div className="row">
          <div className="form-group col-md-2">
            <label>From</label>
            <div className="input-group">
              <input
                type="date"
                name="startDate"
                className="form-control"
                value={state.startDate}
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="form-group col-md-2">
            <label>To</label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                name="endDate"
                value={state.endDate}
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
            <div className="input-group">
              <CustomSelectDropdown
                name="aggregatorName"
                onChange={() => console.log()}
                isShowDropdownArrow
              >
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
          <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
            <div className="input-group">
              <button
                className="btn advancly-white-btn ml-2 form-control"
                onClick={(e) => {
                  dispatch(
                    showModal({
                      modalIdentifier: "advancedcustomersearch",
                      dataRecord: { customerType: "businessCustomers" },
                    }),
                  );
                }}
              >
                <i className="fas fa-search" />
                &nbsp; Advance Search
              </button>
            </div>
          </div>
          <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
            <div className="input-group">
              <button
                className="btn advancly-btn ml-2 form-control"
                onClick={filterData}
              >
                Search
              </button>
            </div>
          </div>
          <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
            <div className="input-group">
              <button
                type="submit"
                className={`btn advancly-red-btn btn-md ml-3`}
                style={{ height: "3rem" }}
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </div>
        </div>

        <div>{RenderedComponent}</div>

        <Paginator
          size={query.pageSize}
          page={query.page}
          count={businessCustomers?.totalCount}
          changeCurrentPage={(pageNumber: number) =>
            setQuery({ ...state, page: pageNumber })
          }
        />
      </div>
    </div>
  );
};

export default BusinessCustomers;
