import { useEffect, useState } from "react";
import useAllAggregators from "../../../../custom-hooks/useAllAggregators";
import CustomSelectDropdown from "../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

export default function CreateWallet({ properties }) {
  const [state, setState] = useState({
    aggregatorId: properties?.aggregator_Id || "",
    currency: "",
    loading: false,
  });
  const { data: aggregatorData } = useAllAggregators();
  const handleSubmit = (e) => {
    e.preventDefault();
    properties?.onSubmit(state);
  };
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    MixPanel.track(`Page View: CreateWallet.tsx`, {
      page: "CreateWallet.tsx",
    });
  }, []);

  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div className="IssueModal Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <h3
            className="Modal__title m-b-2 p-x-2 p-y-1 "
            style={{ backgroundColor: "#713fff", color: "#fff" }}
          >
            Create wallet
          </h3>

          <form>
            <div className="row">
              <div className="col-xs-12 mb-4">
                <CustomSelectDropdown
                  label="Aggregator"
                  name="aggregatorId"
                  value={state?.aggregatorId}
                  onChange={(e) => handleChange(e)}
                  className="round user__form form-control"
                  disabled={properties?.aggregator_Id}
                >
                  <option value="">Aggregator</option>
                  {aggregatorData &&
                    aggregatorData.map((aggregator) => {
                      const { biz_name, aggregator_id } = aggregator;
                      return (
                        <option
                          value={Number(aggregator_id)}
                          key={aggregator_id}
                        >
                          {biz_name}
                        </option>
                      );
                    })}
                </CustomSelectDropdown>
              </div>
              <div className="col-xs-12 mb-4">
                <CustomSelectDropdown
                  label="Currency"
                  name="currency"
                  value={state?.currency}
                  onChange={(e) => handleChange(e)}
                  className="round user__form form-control"
                >
                  <option value=""></option>

                  <option value="NGN">Naira</option>

                  <option value="USD">Dollar</option>
                </CustomSelectDropdown>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-4 mb-4"></div>
              <div className="col-xs-4 mb-4 ">
                <div className="grid__full m-t-2">
                  <button
                    type="submit"
                    className="zoom-element btn login-btn"
                    onClick={(e) => handleSubmit(e)}
                  >
                    &nbsp; Proceed {state.loading && <ButtonLoader />}
                  </button>
                </div>
              </div>

              <div className="col-xs-4 mb-4"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
