import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DownloadUploadedDocument } from "../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import CustomInputField from "../../../CustomHTMLElements/CustomInputField";
import CustomOnboardingAccordion from "../../Components/CustomOnboardingAccordion";
import { getCountry } from "../helper";
import CustomTextArea from "./../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import useCountryCodes from "./../../../../custom-hooks/useCountryCodes";
import { ReactComponent as MessageIcon } from "../../../../assets/svg/icons/message-icon-2.svg";
import { auditRoleList, isAudit } from "../../../../helpers/roleTypes";
import ApprovalDeclineModal from "./Components/ApprovalDeclineModal";
import { handleDisableDocumentAction } from "../Documents/helper";
import { documentApprovalStatus } from "../../util";
import { showModal } from "../../../../redux/actions/modal";
import useDocumentViewer from "../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";

import styles from "./BoardOfDirectors.module.scss";

export default function BoardOfDirectors({
  boardOfDirectors,
  customerId,
  refetch,
  isFromCreditAnalysis = false,
}) {
  const [actionData, setActionData] = useState({});
  const { data } = useCountryCodes();

  const dispatch = useDispatch();

  const { handleViewDocument } = useDocumentViewer();

  const loggedInUser = useSelector((state) => state?.authReducer?.loggedInUser);

  const boardOfDirectorsDocumentType = {
    GOVERNMENT_ID: 1,
    PROOF_OF_ADDRESS: 2,
  };

  const handleViewComments = (e, comments) => {
    dispatch(
      showModal({
        modalIdentifier: "boardofdirectorsdocumentcomments",
        dataRecord: {
          comments: comments,
          // document_file_id: file?.aggregator_document_id,
        },
      })
    );
  };

  return (
    <div className={!isFromCreditAnalysis ? "onboardingInnerLayout" : ""}>
      {boardOfDirectors &&
        boardOfDirectors.length > 0 &&
        boardOfDirectors.map((_boardOfDirector, index) => (
          <CustomOnboardingAccordion
            key={index}
            title={`${_boardOfDirector?.first_name} ${_boardOfDirector?.last_name}`}
          >
            <form>
              <div className="row">
                <div className="col-12">
                  <CustomInputField
                    type="text"
                    label="Name"
                    defaultValue={`${_boardOfDirector?.first_name} ${_boardOfDirector?.last_name}`}
                    readOnly
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <CustomInputField
                    type="text"
                    label="Country"
                    readOnly
                    defaultValue={
                      _boardOfDirector?.country_code && data
                        ? getCountry(_boardOfDirector?.country_code, data)
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <CustomInputField
                    type="text"
                    label="Role"
                    placeholder="E.g Chairman"
                    readOnly
                    defaultValue={_boardOfDirector?.role}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <CustomInputField
                    type="text"
                    label="Academic & Professional Qualifications"
                    readOnly
                    defaultValue={_boardOfDirector?.educational_qualification}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <CustomInputField
                    type="number"
                    label="Identification Number"
                    defaultValue={_boardOfDirector?.identity_number}
                    readOnly
                  />
                </div>

                <div className="col-12">
                  <CustomTextArea
                    label="Work Experience"
                    readOnly
                    defaultValue={_boardOfDirector?.work_experience}
                  />
                </div>
                <div className="col-12">
                  <CustomTextArea
                    label="Experience relevant to the role/business"
                    readOnly
                    defaultValue={_boardOfDirector?.experience_relevant_to_role}
                  />
                </div>

                <div className={`col-12 ${styles.uploadedDocsContainer}`}>
                  <div className="d-flex">
                    <div className={styles.downloadUploadDocContainer}>
                      <DownloadUploadedDocument
                        name="Government ID"
                        pathname={_boardOfDirector?.government_id_file_path}
                        isViewDocument
                        handleView={() =>
                          handleViewDocument(
                            false,
                            _boardOfDirector?.government_id_file_path
                          )
                        }
                      />
                    </div>

                    <div className="d-flex ml-5">
                      <div className="d-flex">
                        {_boardOfDirector.government_id_approval_status ===
                          documentApprovalStatus?.DECLINED && (
                          <button
                            className="btn advancly-red-btn btn-md"
                            disabled
                          >
                            Declined
                          </button>
                        )}
                        {_boardOfDirector.government_id_approval_status ===
                          documentApprovalStatus?.APPROVED && (
                          <button
                            className="btn advancly-green-btn btn-md"
                            disabled
                          >
                            Approved
                          </button>
                        )}
                        {(_boardOfDirector.government_id_approval_status ===
                          documentApprovalStatus?.APPROVED ||
                          _boardOfDirector.government_id_approval_status ===
                            documentApprovalStatus?.DECLINED) && (
                          <div
                            className={styles.commentsAction}
                            onClick={(e) =>
                              handleViewComments(
                                e,
                                _boardOfDirector?.govt_id_comment
                              )
                            }
                          >
                            <MessageIcon className={styles.messageIcon} />
                            <span className={styles.commentText}>Comment</span>
                          </div>
                        )}
                      </div>

                      {_boardOfDirector.government_id_approval_status ===
                        documentApprovalStatus?.PENDING &&
                        !isAudit(
                          [...loggedInUser?.roles],
                          [...auditRoleList]
                        ) && (
                          <>
                            <button
                              type="button"
                              className="btn advancly-btn btn-md"
                              data-toggle="modal"
                              data-target="#boardOfDirectorsDocumentsActionModal"
                              disabled={handleDisableDocumentAction(
                                loggedInUser
                              )}
                              onClick={() =>
                                setActionData({
                                  actionType: "approve",
                                  data: {
                                    ..._boardOfDirector,
                                    documentType:
                                      boardOfDirectorsDocumentType?.GOVERNMENT_ID,
                                  },
                                })
                              }
                            >
                              Approve
                            </button>
                            <button
                              type="button"
                              className="btn btn advancly-off-2-btn btn-md ml-3"
                              data-toggle="modal"
                              data-target="#boardOfDirectorsDocumentsActionModal"
                              disabled={handleDisableDocumentAction(
                                loggedInUser
                              )}
                              onClick={() =>
                                setActionData({
                                  actionType: "decline",
                                  data: {
                                    ..._boardOfDirector,
                                    documentType:
                                      boardOfDirectorsDocumentType?.GOVERNMENT_ID,
                                  },
                                })
                              }
                            >
                              Decline
                            </button>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className={`col-12 ${styles.uploadedDocsContainer}`}>
                  <div className="d-flex">
                    <div className={styles.downloadUploadDocContainer}>
                      <DownloadUploadedDocument
                        name="Proof of Address"
                        pathname={_boardOfDirector?.proof_of_address_file_path}
                        isViewDocument
                        handleView={() =>
                          handleViewDocument(
                            false,
                            _boardOfDirector?.proof_of_address_file_path
                          )
                        }
                      />
                    </div>

                    <div className="d-flex ml-5">
                      <div className="d-flex">
                        {_boardOfDirector.proof_of_address_approval_status ===
                          documentApprovalStatus?.DECLINED && (
                          <button
                            className="btn advancly-red-btn btn-md"
                            disabled
                          >
                            Declined
                          </button>
                        )}
                        {_boardOfDirector.proof_of_address_approval_status ===
                          documentApprovalStatus?.APPROVED && (
                          <button
                            className="btn advancly-green-btn btn-md"
                            disabled
                          >
                            Approved
                          </button>
                        )}
                        {(_boardOfDirector.proof_of_address_approval_status ===
                          documentApprovalStatus?.APPROVED ||
                          _boardOfDirector.proof_of_address_approval_status ===
                            documentApprovalStatus?.DECLINED) && (
                          <div
                            className={styles.commentsAction}
                            onClick={(e) =>
                              handleViewComments(
                                e,
                                _boardOfDirector?.proof_of_address_comment
                              )
                            }
                          >
                            <MessageIcon className={styles.messageIcon} />
                            <span className={styles.commentText}>Comment</span>
                          </div>
                        )}
                      </div>

                      {_boardOfDirector.proof_of_address_approval_status ===
                        documentApprovalStatus?.PENDING &&
                        !isAudit(
                          [...loggedInUser?.roles],
                          [...auditRoleList]
                        ) && (
                          <>
                            <button
                              type="button"
                              className="btn advancly-btn btn-md"
                              data-toggle="modal"
                              data-target="#boardOfDirectorsDocumentsActionModal"
                              disabled={handleDisableDocumentAction(
                                loggedInUser
                              )}
                              onClick={() =>
                                setActionData({
                                  actionType: "approve",
                                  data: {
                                    ..._boardOfDirector,
                                    documentType:
                                      boardOfDirectorsDocumentType?.PROOF_OF_ADDRESS,
                                  },
                                })
                              }
                            >
                              Approve
                            </button>
                            <button
                              type="button"
                              className="btn btn advancly-off-2-btn btn-md ml-3"
                              data-toggle="modal"
                              data-target="#boardOfDirectorsDocumentsActionModal"
                              disabled={handleDisableDocumentAction(
                                loggedInUser
                              )}
                              onClick={() =>
                                setActionData({
                                  actionType: "decline",
                                  data: {
                                    ..._boardOfDirector,
                                    documentType:
                                      boardOfDirectorsDocumentType?.PROOF_OF_ADDRESS,
                                  },
                                })
                              }
                            >
                              Decline
                            </button>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </CustomOnboardingAccordion>
        ))}

      {boardOfDirectors && boardOfDirectors.length < 1 && (
        <div className="w-100 d-flex align-items-center justify-content-center py-3">
          <h3 className="font-weight-bold">
            Aggregator is yet to submit board of directors
          </h3>
        </div>
      )}

      <ApprovalDeclineModal
        data={actionData}
        refetchData={refetch}
        customerId={customerId}
      />
    </div>
  );
}
