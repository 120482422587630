import * as actionTypes from "./../actions/actionTypes";
import { IBusinessCustomerSearchParams } from "../../components/DigitalBank/Interfaces";

let initialState: IBusinessCustomerSearchParams = {
  email: "",
  name: "",
  phoneNumber: "",
  accountNumber: "",
  rcNumber: "",
  businessType: "",
  endDate: "",
  startDate: "",
};

const reducer = (
  state = initialState,
  action: { type: string; payload: IBusinessCustomerSearchParams },
) => {
  switch (action.type) {
    case actionTypes.SET_ADVANCED_CUSTOMERS_SEARCH_PARAMS:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
