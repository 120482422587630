import { useSelector } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { postDataWithDotNet } from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { errorHandler } from "../../../../helpers/errorHandler";
import { formatMoney } from "../../../../helpers/formatter";
import moment from "moment";
import { ROLE_TYPES } from "../../../../helpers/roleTypes";
import { IWithdrawalData } from "../../../../components/Requests/components/WithdrawalRequestsDataTable";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

interface IProps {
  properties: { item: IWithdrawalData };
  onCloseModal: () => void;
}
const ApproveAdminWithdrawalRequestsModal = ({
  properties,
  onCloseModal,
}: IProps) => {
  const {
    currency_code,
    narration,
    reference,
    user_id,
    investment_product_name,
    deposit_type,
    investment_reference_number,
    funder_id,
    funder_type,
    firstname,
    lastname,
    email,
    phone_number,
    funder_investment_id,
    amount,
    transaction_date,
  } = properties?.item;
  const loggedInUser = useSelector(
    (state: any) => state?.authReducer?.loggedInUser,
  );
  const { roles } = loggedInUser;

  useEffect(() => {
    MixPanel.track(`Page View: ApproveAdminWithdrawalRequest.tsx`, {
      page: "ApproveAdminWithdrawalRequest.tsx",
    });
  }, []);

  const [comment, setComment] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [withdrawalSuccess, setWithdrawalSuccess] = useState("");
  const [error, setError] = useState("");
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const submitHandleApprove = async () => {
    if (!comment) {
      return setIsFormValid(false);
    }
    try {
      setApproving(true);
      const response = await postDataWithDotNet(
        ajaxEndpoints.ADMIN_APPROVE_WITHDRAWAL,
        {
          comment,
          reference: reference,
        },
      );
      setWithdrawalSuccess(response?.message || "Success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setError(errorHandler(error));
    } finally {
      setApproving(false);
    }
  };

  const submitHandleReject = async () => {
    if (!comment) {
      return setIsFormValid(false);
    }
    try {
      setRejecting(true);
      const response = await postDataWithDotNet(
        ajaxEndpoints.REJECT_INVESTMENT_TOP_UP_AND_WITHDRAWAL,
        {
          comment,
          reference: reference,
        },
      );
      setWithdrawalSuccess(response?.message || "Success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setError(errorHandler(error));
    } finally {
      setRejecting(false);
    }
  };

  return (
    <div
      className="AuditTrailModal Modal__content"
      style={{ overflow: "hidden" }}
    >
      <div className="Modal__body">
        <Fragment>
          {withdrawalSuccess && (
            <p className="alert alert-success">{withdrawalSuccess}</p>
          )}
          <h3 className="Modal__title p-b-1">Withdrawal Request</h3>
          <hr />
          <div className="row">
            {/* Withdrawal Amount */}
            <div className="col-md-6 mb-3">
              <label>Withdrawal Amount:</label>
              <input
                type="text"
                className="form-control"
                value={formatMoney(amount || 0, currency_code)}
                readOnly
              />
            </div>
            {/* Funder Investment ID */}
            <div className="col-md-6 mb-3">
              <label>Funder Investment ID:</label>
              <input
                type="text"
                className="form-control"
                value={funder_investment_id}
                readOnly
              />
            </div>
            {/* Transaction Date */}
            <div className="col-md-6 mb-3">
              <label>Transaction Date:</label>
              <input
                type="text"
                className="form-control"
                value={moment(transaction_date).format("LL")}
                readOnly
              />
            </div>
            {/* Top-Up Type */}
            <div className="col-md-6 mb-3">
              <label>Deposit Type:</label>
              <input
                type="text"
                className="form-control"
                value={deposit_type}
                readOnly
              />
            </div>

            {/* Narration */}
            <div className="col-md-6 mb-3">
              <label>Narration:</label>
              <input
                type="text"
                className="form-control"
                value={String(narration) || ""}
                readOnly
              />
            </div>
            {/* Reference */}
            <div className="col-md-6 mb-3">
              <label>Reference:</label>
              <input
                type="text"
                className="form-control"
                value={reference}
                readOnly
              />
            </div>
            {/* User ID */}
            <div className="col-md-6 mb-3">
              <label>User ID:</label>
              <input
                type="text"
                className="form-control"
                value={user_id}
                readOnly
              />
            </div>
            {/* Investment Product Name */}
            <div className="col-md-6 mb-3">
              <label>Investment Product Name:</label>
              <input
                type="text"
                className="form-control"
                value={investment_product_name}
                readOnly
              />
            </div>
            {/* Deposit Type */}
            <div className="col-md-6 mb-3">
              <label>Deposit Type:</label>
              <input
                type="text"
                className="form-control"
                value={deposit_type}
                readOnly
              />
            </div>
            {/* Investment Reference Number */}
            <div className="col-md-6 mb-3">
              <label>Investment Reference Number:</label>
              <input
                type="text"
                className="form-control"
                value={investment_reference_number}
                readOnly
              />
            </div>
            {/* Funder ID */}
            <div className="col-md-6 mb-3">
              <label>Funder ID:</label>
              <input
                type="text"
                className="form-control"
                value={funder_id}
                readOnly
              />
            </div>
            {/* Funder Type */}
            <div className="col-md-6 mb-3">
              <label>Funder Type:</label>
              <input
                type="text"
                className="form-control"
                value={funder_type}
                readOnly
              />
            </div>
            {/* First Name */}
            <div className="col-md-6 mb-3">
              <label>First Name:</label>
              <input
                type="text"
                className="form-control"
                value={firstname}
                readOnly
              />
            </div>
            {/* Last Name */}
            <div className="col-md-6 mb-3">
              <label>Last Name:</label>
              <input
                type="text"
                className="form-control"
                value={lastname}
                readOnly
              />
            </div>
            {/* Email */}
            <div className="col-md-6 mb-3">
              <label>Email:</label>
              <input
                type="text"
                className="form-control"
                value={email}
                readOnly
              />
            </div>
            {/* Phone Number */}
            <div className="col-md-6 mb-3">
              <label>Phone Number:</label>
              <input
                type="text"
                className="form-control"
                value={phone_number}
                readOnly
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>Comment</label>
              <input
                type="text"
                className="form-control"
                name="comment"
                defaultValue={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                  setIsFormValid(true);
                }}
              ></input>
              {!isFormValid && <p className="text-danger">Enter a comment</p>}
            </div>
          </div>
          {roles &&
            roles?.length > 0 &&
            roles?.includes(ROLE_TYPES.INVESTMENT_WITHDRAWAL_APPROVER) && (
              <>
                <hr />
                {withdrawalSuccess && (
                  <p className="alert alert-success col-md-6 offset-md-3">
                    {withdrawalSuccess}
                  </p>
                )}

                {error && (
                  <p className="alert alert-danger col-md-6 offset-md-3">
                    {error}
                  </p>
                )}
                <div className="col-12 d-flex justify-content-end flex-wrap">
                  <button
                    className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                    type="button"
                    disabled={!comment || !reference || approving || rejecting}
                    onClick={submitHandleApprove}
                  >
                    {approving ? (
                      <span className="spinner-border spinner-border-sm text-white" />
                    ) : (
                      "Approve"
                    )}
                  </button>
                  <button
                    className="btn btn-danger btn-sm transition-3d-hover mt-2"
                    type="button"
                    disabled={!comment || !reference || rejecting || approving}
                    onClick={submitHandleReject}
                  >
                    {rejecting ? (
                      <span className="spinner-border spinner-border-sm text-white" />
                    ) : (
                      "Reject"
                    )}
                  </button>
                </div>
              </>
            )}
        </Fragment>
      </div>
    </div>
  );
};
export default ApproveAdminWithdrawalRequestsModal;
