import React, { Fragment, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Route Components
import Onboarding from "../../components/Onboarding/Onboarding";
import Login from "../../components/Login/Login";
import Logout from "../../components/Logout/Logout";
import Profile from "./../../components/Profile/Profile";
import Recovery from "../../components/Recovery/Recovery";
import Dashboard from "../../components/Dashboard/Dashboard";
import ManageUsers from "../../components/ManageUsers/ManageUsers";
import ManageLoans from "../../components/ManageLoans/ManageLoans";
import ReportIssue from "../../components/ReportIssue/ReportIssue.js";
import Notifications from "../../components/Notifications/Notifications";
import AuditTrail from "../../components/AuditTrail/AuditTrail";
import Settings from "../../components/Settings/Settings";
import Notfound from "../../components/Notfound/Notfound";
import ContactUs from "../../components/ContactUs/ContactUs";
import Terms from "../Footer/Terms";
import Privacy from "../Footer/Privacy";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import ManageApproval from "../../components/ManageApproval/ManageApproval";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop.component";
import ManageRolesAndUsers from "../../components/ManageRoles/ManageRolesAndUsers";
import DisbursementApproverDashboard from "../../components/DisbursementApproverDashboard/DisbursementApproverDashboard";
import RepaymentReports from "../../components/RepaymentReports/RepaymentReports";
import Enable2FA from "../../components/Enable2FA/Enable2FA";
import CreditBureau from "../../components/CreditBureau/CreditBureau";
import protectPage from "./../../helpers/protectPage";
import AggregatorOnboarding from "./../../components/AggregatorOnboarding/AggregatorOnboarding";
import AggregatorOnboardingReview from "../../components/AggregatorOnboardingReview/AggregatorOnboardingReview";
import AggregatorOnboardingRequiredDocuments from "./../../components/AggregatorOnboardingSettings/RequiredDocuments/AggregatorOnboardingRequiredDocuments";
import GeneralPowerBiDashboard from "../../components/PowerBiDasboard/GeneralPowerBiDashboard";
import Investor from "../../components/Investor/Investor";
import InvestorTabs from "../../components/Investor/Tabs/InvestorTabs";
import InvestorInvestmentWallet from "../../components/Investor/Tabs/InvestorInvestmentWallet";
import EachInvestorInvestment from "../../components/Investor/Tabs/EachInvestorInvestment/EachInvestorInvestment";
import FunderInvestmentProduct from "../../components/FunderInvestmentProduct/FunderInvestmentProduct";
import CreateInvestmentProduct from "../../components/FunderInvestmentProduct/CreateInvestmentProducts/CreateInvestmentProduct";
import EditInvestmentProduct from "../../components/FunderInvestmentProduct/CreateInvestmentProducts/EditInvestmentProduct";
import PreviewInvesmentFunds from "../../components/FunderInvestmentProduct/CreateInvestmentProducts/PreviewInvestment/PreviewInvesmentFunds";
import ApprovalRequest from "../../components/ApprovalRequest/ApprovalRequest";
import PreviewInvestorDetails from "../../components/ApprovalRequest/Tabs/PreviewInvestorDetails";
import PreviewInvesmentFundDetails from "../../components/ApprovalRequest/Tabs/PreviewInvesmentFundDetails";
import ActiveInvestmentTab from "../../components/FunderInvestmentProduct/Tabs/ActiveInvestmentTab/ActiveInvestmentTab";
import BatchLoanRepayment from "../../components/BatchLoanRepayment/BatchLoanRepayment";
import BatchRepaymentRequest from "../../components/BatchRepaymentRequest/BatchRepaymentRequest";
import Requests from "../../components/Requests/Requests";
import PreviewOfflineInvestment from "../../components/Requests/Tabs/PreviewOfflineInvestment";
import AllInvestments from "../../components/AllInvestments/AllInvestments";
import PowerBiReport from "../../components/PowerBiDasboard/PowerBiReport";
import EachFunderInvestment from "../../components/AllInvestments/EachFunderInvestment";
import AllAggregatorTransactions from "../../components/AllTransactions/AllAggregatorTransactions";
import LoanRepaymentReport from "../../components/LoanRepaymentReport/LoanRepaymentReport";
import IncomeInterestReport from "../../components/IncomeInterestReport/IncomeInterestReport";
import Wallet from "../../components/Wallet/Wallet";
import WalletTransactions from "../../components/Wallet/WalletTransactions";
import WalletDisbursementTransactions from "../../components/WalletDisbursementApprovals/WalletDisbursementTransactions";
import WalletTransactionLog from "../../components/WalletDisbursementApprovals/WalletTransactionLog";
import Messaging from "../../components/Messaging";
import { messagingUrl } from "../../components/Messaging/urlConstants";
import SendMessage from "../../components/Messaging/SendMessage/sendMessage";
import PreviousMessages from "../../components/Messaging/PreviousMessages/previousMessages";
import ProductSetup from "../../components/ProductSetup/productSetup";
import BirthdayNotifications from "../../components/BirthdayNotifications";
import CompanyWallets from "../../components/Wallet/CompanyWallets";
import CompanyWalletTransactions from "../../components/Wallet/CompanyWalletTransactions";
import GlobalWaitlist from "../../components/AggregatorOnboarding/GlobalWaitlist/GlobalWaitlist.component";
import ViewMessage from "../../components/Messaging/ViewMessage/viewMessage";
import UploadDealSlip from "../../components/DealSlip/uploadDealSlip";
import PendingDealSlips from "../../components/DealSlip/PendingDealSlips/pendingDealSlips";
import ViewDealSlip from "../../components/DealSlip/PendingDealSlips/viewDealSlip";
import PrivateRoute from "./PrivateRoute";
import { ROLE_TYPES } from "../../helpers/roleTypes";
import AccessDenied from "../../components/AccessDenied/AccessDenied";
import Regulatory from "../../components/Regulatory/Regulatory";
import Finance from "../../components/Finance/Finance";
import LoanCreationQueue from "../../components/Queue/LoanCreationQueue";
import BankStatement from "../../components/BankStatement/BankStatement";
import LoanProduct from "../../components/LoanProduct/LoanProduct";
import PendingLoanApplication from "../../components/AutomatedCredit/RMPendingLoanAppplication/PendingLoanApplication";
import ViewLoanApplication from "../../components/AutomatedCredit/ViewLoanApplicationDetails/ViewLoanApplication";
import CreateLoanProduct from "../../components/LoanProduct/CreateLoanProduct/CreateLoanProduct";
import ViewLoanProduct from "../../components/LoanProduct/ViewLoanProduct/ViewLoanProduct";
import EditLoanProduct from "../../components/LoanProduct/EditLoanProduct/EditLoanProduct";
import LoanApplications from "../../components/AutomatedCredit/LoanApplications/LoanApplications";
import LoanEligibilityQuestions from "../../components/LoanEligibilityQuestions/EligibilityQuestions/AggregatorOnboardingEligiblityQuestions";
import CreditAnalystPendingLoanApplication from "../../components/AutomatedCredit/CreditAnalystPendingLoanApplications/CreditAnalystPendingLoanApplications";
import CreditAnalysis from "../../components/AutomatedCredit/CreditAnalysis/CreditAnalysis";
import CreditAnalysisSettings from "../../components/AutomatedCredit/Setup";
import LoanProductDetailFees from "../../components/LoanProduct/LoanProductDetailFees";
import PrepareOfferLetter from "../../components/PrepareOfferLetter/PrepareOfferLetter";
import ApproverPendingLoan from "../../components/ManageApproval/Approver/ApproverPendingLoan";
import ApproverLoanSectionScreen from "../../components/ManageApproval/Approver/ApproverLoanSectionScreen";
import MccPendingLoanFinalScreen from "../../components/ManageApproval/MccPendingLoanApplications/MccPendingLoanFinalScreen";
import MccPendingLoan from "../../components/ManageApproval/MccPendingLoanApplications/MccPendingLoan";
import PreDisbursementPendingLoanApplication from "../../components/AutomatedCredit/PreDisbursement/PreDisbursementPendingLoanApplication";
import PreDisbursementMainMenu from "../../components/AutomatedCredit/PreDisbursement/PreDisbursementMainMenu";
import InvestmentProspect from "../../components/Investor/Tabs/InvestmentProspect/InvestmentProspect";
import InvestmentProspectTabs from "../../components/Investor/Tabs/InvestmentProspect/InvestmentPropspectTabs";
import FundingWallets from "../../components/CreditLine/FundingWallets";
import CreateFundingWallet from "../../components/CreditLine/CreateFundingWallet";
import FundingRequestApproval from "../../components/CreditLine/FundingRequestApproval";
import CreditBureauReportMain from "../../modals/Modals/Loans/CreditBureauCRC/CreditBureauReport/CreditBureauReportMain";
import TierUpgrade from "../../components/DigitalBank/TierUpgrade";
import ReviewTierUpgradeRequest from "../../components/DigitalBank/TierUpgrade/ReviewTierUpgradeRequest";
import IndividualCustomers from "../../components/DigitalBank/IndividualCustomers";
import BusinessCustomers from "../../components/DigitalBank/BusinessCustomers";
import BusinessCustomerDetails from "../../components/DigitalBank/BusinessCustomers/BusinessCustomerDetails";
import SelectedAccountDetail from "../../components/DigitalBank/BusinessCustomers/BusinessCustomerDetails/Tabs/AccountDetails/SelectedAccountDetail";
import ViewLoanApplicationReadOnly from "../../components/AutomatedCredit/ViewLoanApplicationDetails/ViewLoanApplicationReadOnly";
import IndividualCustomerDetails from "../../components/DigitalBank/IndividualCustomers/Tabs";
import IndividualCustomerAccountDetails from "../../components/DigitalBank/IndividualCustomers/Components/IndividualCustomerAccountDetails";
import AggregatorOnboardingEligiblityQuestions from "../../components/LoanEligibilityQuestions/EligibilityQuestions/AggregatorOnboardingEligiblityQuestions";
import BusinessCustomerOnboardingReview from "../../components/DigitalBank/BusinessOnboardingReview";
import MccPendingApprovals from "../../components/ManageApproval/MccApprovalsPendingLoanApplications/MccPendingApprovals";
import MccApprovalsFinalPage from "../../components/ManageApproval/MccApprovalsPendingLoanApplications/MccApprovalsFinalPage";
import LoanRolloverRequestCheckerMain from "../../modals/Modals/Loans/ApplyRollover/LoanRolloverRequestCheckerMain";
import HealthProbe from "../../components/HealthProbe/HealthProbe";

const AppRouter = (props) => {
  const { pathname } = useLocation();
  //  Life Cycle Hooks
  useEffect(() => {
    //  If user is logged in and user is not registered on SSO, redirect to changed password.
    // This runs anytime the url changes
    if (props.isAuthenticated) {
      if (props.loggedInUser && props.loggedInUser?.ForceChangeOfPassword) {
        props.history.push("/change-password");
      }
    }
  }, [props.history, props.isAuthenticated, props.loggedInUser, pathname]);
  return (
    <Fragment>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/login/" component={Login} />
        <Route path="/onboarding/" component={Onboarding} />
        <Route path="/logout/" component={Logout} />
        <Route path="/forgot-password/" component={Recovery} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms-of-use" component={Terms} />
        <Route path="/page-not-found/" component={Notfound} />
        <Route path="/health/" component={HealthProbe} />
        <Route path="/enable-2fa/" component={protectPage(Enable2FA)} />
        <Route path="/contact-us/" component={protectPage(ContactUs)} />
        <Route path="/profile/" component={protectPage(Profile)} />
        <Route
          path="/change-password"
          component={protectPage(ChangePassword)}
        />
        {/* Might be redundant */}
        <Route path="/notifications/" component={protectPage(Notifications)} />
        <Route path="/report-issue/" component={protectPage(ReportIssue)} />
        <Route path="/reports/" component={protectPage(RepaymentReports)} />
        {/* Pay extra attention */}
        <Route path="/audit-trail/" component={protectPage(AuditTrail)} />
        {/* Exists on the system. Couldn't find the source */}
        <Route
          path="/wallet-transactions/:id"
          component={protectPage(WalletTransactions)}
        />
        {/* Authenticated Routes */}
        <Route path="/access-denied/" component={protectPage(AccessDenied)} />
        <Route
          exact
          path="/funding-wallets"
          component={protectPage(FundingWallets)}
        />
        <Route
          exact
          path="/funding-wallets/create"
          component={protectPage(CreateFundingWallet)}
        />
        <Route
          exact
          path="/funding-request-approval"
          component={protectPage(FundingRequestApproval)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.GENERAL_DASHBOARD]}
          path="/dashboard/"
          component={protectPage(Dashboard)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.GENERAL_DASHBOARD]}
          path="/main-dashboard/"
          component={protectPage(GeneralPowerBiDashboard)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.STANDARD_LOAN_REPORT]}
          path="/main-report/"
          component={protectPage(PowerBiReport)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.DISBURSEMENT_APPROVER]}
          path="/approve-loans/"
          component={protectPage(DisbursementApproverDashboard)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.LOAN_REPAYMENT_REPORT]}
          path="/loan-repayment-reports/"
          component={protectPage(LoanRepaymentReport)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.STANDARD_LOAN_REPORT]}
          path="/loan-income-interest-reports"
          component={protectPage(IncomeInterestReport)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.AGGREGATOR_VIEWER]}
          path="/manage-ecosystems/"
          component={protectPage(ManageUsers)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.USER_CREATION_INITIATOR]}
          path="/manage-roles-and-users/"
          component={protectPage(ManageRolesAndUsers)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_VIEWER]}
          path="/manage-loans/"
          component={protectPage(ManageLoans)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.DEAL_SLIP_APPROVER]}
          path="/manage-loans/:id/deal-slip"
          component={protectPage(UploadDealSlip)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_PRODUCT_APPROVE]}
          path="/manage-approvals/"
          component={protectPage(ManageApproval)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_PRODUCT_APPROVE]}
          path="/loan-rollover-request"
          component={protectPage(LoanRolloverRequestCheckerMain)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CUSTOMER_WALLET_VIEWER]}
          path="/wallet/"
          component={protectPage(Wallet)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CUSTOMER_WALLET_VIEWER]}
          exact={true}
          path="/company-wallets"
          component={protectPage(CompanyWallets)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CUSTOMER_WALLET_VIEWER]}
          path="/company-wallets/transactions/"
          component={protectPage(CompanyWalletTransactions)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_VIEWER]}
          path="/credit-bureau/"
          component={protectPage(CreditBureau)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_VIEWER]}
          path="/credit-bureau-information"
          component={protectPage(CreditBureauReportMain)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.DEAL_SLIP_APPROVER]}
          path="/pending-slips/"
          component={protectPage(PendingDealSlips)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.DEAL_SLIP_APPROVER]}
          path="/pending-slip"
          component={protectPage(ViewDealSlip)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.GLOBAL_SETTINGS]}
          path="/settings/"
          component={protectPage(Settings)}
        />
        <PrivateRoute
          permittedRoles={[
            ROLE_TYPES.GLOBAL_SETTINGS,
            ROLE_TYPES.CREDIT_PRODUCT_SETUP,
          ]}
          path="/bank-statement/"
          component={protectPage(BankStatement)}
        />
        <PrivateRoute
          permittedRoles={[
            ROLE_TYPES.CREDIT_PRODUCT_SETUP,
            ROLE_TYPES.CREDIT_PRODUCT_VIEWER,
          ]}
          path="/product-setup/"
          component={protectPage(ProductSetup)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.GLOBAL_WAITLIST]}
          path="/aggregator-global-waitlist"
          component={protectPage(GlobalWaitlist)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER]}
          path="/customer-onboarding"
          component={protectPage(AggregatorOnboarding)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER]}
          path="/aggregator-onboarding/:id"
          component={protectPage(AggregatorOnboardingReview)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER]}
          path="/aggregator-onboarding/eligibility/admin"
          component={protectPage(AggregatorOnboardingEligiblityQuestions)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER]}
          path="/aggregator-onboarding/documents/admin"
          component={protectPage(AggregatorOnboardingRequiredDocuments)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investment-prospect"
          component={protectPage(InvestmentProspect)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investment-prospect/:id"
          component={protectPage(InvestmentProspectTabs)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investor"
          component={protectPage(Investor)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investor"
          component={protectPage(Investor)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investor/:id"
          component={protectPage(InvestorTabs)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investor/:id/investment"
          component={protectPage(EachInvestorInvestment)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investor/:id/wallet"
          component={protectPage(InvestorInvestmentWallet)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investments"
          component={protectPage(FunderInvestmentProduct)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[
            ROLE_TYPES.AGGREGATOR_WALLET_DISBURSEMENT_VIEWER,
            ROLE_TYPES.AGGREGATOR_VIEWER,
          ]}
          path="/wallet-disbursement-transactions"
          component={protectPage(WalletDisbursementTransactions)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[
            ROLE_TYPES.AGGREGATOR_WALLET_DISBURSEMENT_VIEWER,
            ROLE_TYPES.AGGREGATOR_VIEWER,
          ]}
          path="/wallettransactionlog/:id"
          component={protectPage(WalletTransactionLog)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[
            ROLE_TYPES.INVESTMENT_PRODUCT_SETUP,
            ROLE_TYPES.INVESTMENT_VIEWER,
          ]}
          path="/investments/create"
          component={protectPage(CreateInvestmentProduct)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investments/:id"
          component={protectPage(ActiveInvestmentTab)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[
            ROLE_TYPES.INVESTMENT_VIEWER,
            ROLE_TYPES.INVESTMENT_PRODUCT_SETUP,
          ]}
          path="/investments/:id/edit"
          component={protectPage(EditInvestmentProduct)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investments/preview/:id"
          component={protectPage(PreviewInvesmentFunds)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/all-investments"
          component={protectPage(AllInvestments)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/investment-details/:id"
          component={protectPage(EachFunderInvestment)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.INVESTMENT_VIEWER]}
          path="/transactions"
          component={protectPage(AllAggregatorTransactions)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[
            ROLE_TYPES.INVESTMENT_VIEWER,
            ROLE_TYPES.INVESTMENT_PRODUCT_APPROVER,
          ]}
          path="/approval-requests"
          component={protectPage(ApprovalRequest)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[
            ROLE_TYPES.INVESTMENT_VIEWER,
            ROLE_TYPES.INVESTMENT_PRODUCT_APPROVER,
          ]}
          path="/approval-requests/:id/investor"
          component={protectPage(PreviewInvestorDetails)}
          exact
        />
        <PrivateRoute
          permittedRoles={[
            ROLE_TYPES.INVESTMENT_VIEWER,
            ROLE_TYPES.INVESTMENT_PRODUCT_APPROVER,
          ]}
          path="/approval-requests/:id/investment"
          component={protectPage(PreviewInvesmentFundDetails)}
          exact
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CUSTOMER_INVESTMENT_APPROVER]}
          path="/requests"
          component={protectPage(Requests)}
          exact
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CUSTOMER_INVESTMENT_APPROVER]}
          path="/requests/:id"
          component={protectPage(PreviewOfflineInvestment)}
          exact
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.REPAYMENT_INITIATOR]}
          path="/loan-repayment"
          component={protectPage(BatchLoanRepayment)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.REPAYMENT_APPROVER]}
          path="/repayment-request"
          component={protectPage(BatchRepaymentRequest)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.NOTIFICATION_VIEWER]}
          path="/messaging"
          component={protectPage(Messaging)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.NOTIFICATION_VIEWER]}
          path={`/messaging/${messagingUrl.SEND_MESSAGE}`}
          component={protectPage(SendMessage)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.NOTIFICATION_VIEWER]}
          path={`/messaging/${messagingUrl.PREVIOUS_MESSAGES}`}
          component={protectPage(PreviousMessages)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.NOTIFICATION_VIEWER]}
          path={`/messaging/${messagingUrl.VIEW_PREVIOUS_MESSAGE}`}
          component={protectPage(ViewMessage)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.NOTIFICATION_VIEWER]}
          path={`/birthday-notifications`}
          component={protectPage(BirthdayNotifications)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.REGULATORY_REPORT]}
          path={`/regulatory`}
          component={protectPage(Regulatory)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.QUEUE_ERROR_BUCKET]}
          path={`/queue`}
          component={protectPage(LoanCreationQueue)}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.REGULATORY_REPORT]}
          path={`/finance`}
          component={protectPage(Finance)}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_VIEWER]}
          path="/loan-product"
          component={protectPage(LoanProduct)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_PRODUCT_SETUP]}
          path="/loan-product/new"
          component={protectPage(CreateLoanProduct)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_VIEWER]}
          path="/loan-product/old/:id"
          component={protectPage(ViewLoanProduct)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_VIEWER]}
          path="/loan-product/old/:id/fees"
          component={protectPage(LoanProductDetailFees)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_PRODUCT_SETUP]}
          path="/loan-product/old/:id/edit"
          component={protectPage(EditLoanProduct)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_PRODUCT_SETUP]}
          path="/loan-product/old/:id/view"
          component={protectPage(EditLoanProduct)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_APPRAISAL]}
          path="/credit-analyst-pending-loan-application"
          component={protectPage(CreditAnalystPendingLoanApplication)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_APPRAISAL]}
          path="/credit-analyst-pending-loan-application/:id"
          component={protectPage(CreditAnalysis)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_APPRAISAL]}
          path="/predisbursement-pending-loan-application"
          component={protectPage(PreDisbursementPendingLoanApplication)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_APPRAISAL]}
          path="/predisbursement-pending-loan-application/:id"
          component={protectPage(PreDisbursementMainMenu)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_APPLICATION_APPROVER]}
          path="/loan-approver-pending-applications"
          component={protectPage(ApproverPendingLoan)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.CREDIT_APPLICATION_APPROVER]}
          path="/loan-approver-pending-applications/:id"
          component={protectPage(ApproverLoanSectionScreen)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.MCC]}
          path="/MCC-pending-loan-applications"
          component={protectPage(MccPendingLoan)}
          exact={true}
        />
        {/* mcc approver route */}
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.MCC_APPROVER]}
          path="/MCC-approvals-pending-loan-applications"
          component={protectPage(MccPendingApprovals)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.MCC]}
          path="/MCC-pending-loan-applications/:id"
          component={protectPage(MccPendingLoanFinalScreen)}
          exact={true}
        />
        <PrivateRoute
          permittedRoles={[ROLE_TYPES.MCC_APPROVER]}
          path="/MCC-approvals-pending-loan-applications/:id"
          component={protectPage(MccApprovalsFinalPage)}
          exact={true}
        />
        {/* These new loan product/application routes need to be protected */}
        <Route
          path="/loan-applications"
          component={protectPage(LoanApplications)}
          exact={true}
        />
        <Route
          path="/loan-applications/:id"
          component={protectPage(ViewLoanApplication)}
          exact={true}
        />
        <Route
          path="/loan-applications-readonly/:id"
          component={protectPage(ViewLoanApplicationReadOnly)}
          exact={true}
        />
        <Route
          path="/rm-pending-loan-application"
          component={protectPage(PendingLoanApplication)}
          exact={true}
        />
        <Route
          path="/prepare-offer-letter"
          component={protectPage(PrepareOfferLetter)}
          exact={true}
        />

        <Route
          path="/credit-analysis-settings"
          component={protectPage(CreditAnalysisSettings)}
          exact={true}
        />

        <PrivateRoute
          permittedRoles={[ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER]}
          path="/loan-eligibility"
          component={protectPage(LoanEligibilityQuestions)}
          exact={true}
        />

        {/* Digital Bank Routes. Will become private routes once we know the appropriate roles */}
        <Route
          exact
          path="/db/tier-upgrade"
          component={protectPage(TierUpgrade)}
        />
        <Route
          exact
          path="/db/tier-upgrade/:id"
          component={protectPage(ReviewTierUpgradeRequest)}
        />
        <Route
          path="/db/individual-customers"
          component={protectPage(IndividualCustomers)}
          exact
        />
        <Route
          path="/db/individual-customers/:id"
          component={protectPage(IndividualCustomerDetails)}
          exact
        />
        <Route
          path="/db/individual-customers/:id/details"
          component={protectPage(IndividualCustomerAccountDetails)}
          exact
        />
        <Route
          exact
          path="/db/business-customers"
          component={protectPage(BusinessCustomers)}
        />
        <Route
          exact
          path="/db/business-customers/:id"
          component={protectPage(BusinessCustomerDetails)}
        />
        <Route
          exact
          path="/db/business-customer/account-detail"
          component={protectPage(SelectedAccountDetail)}
        />
        <Route
          exact
          path="/db/customer-onboarding/:id"
          component={protectPage(BusinessCustomerOnboardingReview)}
        />

        <Redirect from="/*" to="/page-not-found" />
      </Switch>
      <ScrollToTop />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    authReducer: { isAuthenticated, loggedInUser },
  } = state;

  return {
    isAuthenticated,
    loggedInUser,
  };
};
export default withRouter(connect(mapStateToProps, {})(AppRouter));
