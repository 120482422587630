import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../UI/Loaders/Loaders";
import { useEffect, useLayoutEffect, useState } from "react";
import { clearDocumentViewModalRecord } from "../../../redux/actions/documenViewer";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const DocumentViewerModal = ({
  dataRecord: { documentUrl, type },
  onCloseModal,
}) => {
  const [documentData, setDocumentData] = useState({
    documentUrl: documentUrl,
    type: type,
    isLoading: true,
  });

  // console.log("Viewer", documentUrl)
  const dispatch = useDispatch();

  useEffect(() => {
    MixPanel.track(`Page View: DocumentViewer.tsx`, {
      page: "DocumentViewer.tsx",
    });
  }, []);

  const updatedDocumentData = useSelector(
    (state) => state?.documentViewerReducer,
  );

  useLayoutEffect(() => {
    if (updatedDocumentData?.documentUrl) {
      setDocumentData({
        documentUrl: updatedDocumentData?.documentUrl,
        type: updatedDocumentData?.type,
        isLoading: updatedDocumentData?.isLoading,
      });
    }
  }, [updatedDocumentData]);

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1"></h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {documentData?.isLoading ? (
              <ButtonLoader />
            ) : (
              <>
                <object
                  type={documentData?.type}
                  data={documentData?.documentUrl}
                  width="1000"
                  height="800"
                  style={{
                    objectFit: "contain",
                  }}
                ></object>
              </>
            )}
          </div>
          <div className="Form__action-buttons m-t-2">
            <button
              type="button"
              className="btn zoom-element btn login-btn"
              onClick={() => {
                onCloseModal();
                dispatch(clearDocumentViewModalRecord());
              }}
              data-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              &nbsp; Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentViewerModal;
