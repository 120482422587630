import { useEffect, useState } from "react";

import { appInsights } from "../../../../../../config/appInsights";
import useFundingWalletDetail from "../../../../../../custom-hooks/useFundingWalletDetails";
import ButtonLoader, { Loader } from "../../../../../../UI/Loaders/Loaders";
import { TFundingWallet } from "../../../../../../custom-hooks/useFundingWallets";
import useFundingWalletTransactions from "../../../../../../custom-hooks/useFundingWalletTransactions";
import { formatMoney } from "../../../../../../helpers/formatter";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../../../redux/actions/modal";
import { RootState } from "../../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../../helpers/roleTypes";

import styles from "./walletdetails.module.scss";

interface IWalletDetails {
  walletInfo: TFundingWallet;
}

const WalletDetails = ({ walletInfo }: IWalletDetails) => {
  const [isRefetching, setIsRefetching] = useState(false);

  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletDetails",
      isLoggedIn: true,
    });
  }, []);

  const dispatch = useDispatch();

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser,
  );

  const {
    data: walletDetails,
    isLoading: isLoadingWalletDetails,
    refetch: refetchWalletDetails,
  } = useFundingWalletDetail({
    aggregatorId: walletInfo?.aggregatorId,
    walletId: walletInfo?.walletId,
  });

  const {
    data: walletTransactions,
    error: walletTransactionsError,
    status: walletTransactionStatus,
    refetch: refetchWalletTransactions,
  } = useFundingWalletTransactions({
    accountNumber: walletInfo?.accountNumber,
  });

  let RenderedComponent = null;
  if (!walletTransactions && walletTransactionStatus !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Funding Wallet
            transactions
          </h4>
        </div>
      </div>
    );
  } else if (walletTransactionStatus === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Wallet Transactions..." />
    );
  } else {
    if (walletTransactionsError) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              funding wallet transactions, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (walletTransactions &&
        walletTransactions.data &&
        walletTransactions.data.transactions.length === 0) ||
      (walletTransactions && !walletTransactions.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no funding
              wallet transactions.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
            scrollY
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Date Created</th>
                <th>Narration </th>
                <th>Debit</th>
                <th>Credit</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {walletTransactions &&
                walletTransactions.data.transactions.map(
                  (data, _id: number) => {
                    const {
                      creditAmount,
                      debitAmount,
                      description,
                      dateCreated,
                    } = data;
                    return (
                      <tr key={_id}>
                        <td>{_id + 1}</td>
                        {/* <td>
                        {" "}
                        {(searchQuery?.Page - 1) * searchQuery?.PageSize +
                          (_id + 1)}
                      </td> */}
                        <td>{dateCreated}</td>
                        <td>{description}</td>
                        <td>
                          {formatMoney(
                            debitAmount,
                            walletTransactions?.data?.currency,
                          )}
                        </td>
                        <td>
                          {formatMoney(
                            creditAmount,
                            walletTransactions?.data?.currency,
                          )}
                        </td>
                      </tr>
                    );
                  },
                )}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  const handleRefresh = async () => {
    await refetchWalletDetails();
    await refetchWalletTransactions();
  };

  return (
    <div className={styles.WalletDetails}>
      <div className={styles.WithDrawFund}>
        <button
          type="button"
          className="btn advancly-btn btn-md m-0"
          disabled={
            !loggedInUser?.roles.includes(ROLE_TYPES.FUNDING_APPROVER) ||
            walletInfo.fundingPartner.toLowerCase() === "wema"
          }
          onClick={() => {
            dispatch(
              showModal({
                modalIdentifier: "fundingreceiverwalletdetails",
                dataRecord: {
                  ...walletDetails?.data,
                  aggregatorId: walletInfo?.aggregatorId,
                },
              }),
            );
          }}
        >
          Withdraw Fund
        </button>
      </div>
      <div className={styles.Details}>
        {isLoadingWalletDetails ? (
          <div className="d-flex justify-content-center">
            <ButtonLoader />
          </div>
        ) : (
          <ul>
            <li>
              <div className={styles.DetailItem}>
                <span>Bank Name</span>
                <span>{walletDetails?.data?.bankName}</span>
              </div>
            </li>
            <li>
              <div className={styles.DetailItem}>
                <span>Account Name</span>
                <span>{walletDetails?.data?.accountName}</span>
              </div>
            </li>
            <li>
              <div className={styles.DetailItem}>
                <span>Account Number</span>
                <span>{walletDetails?.data?.accountNumber}</span>
              </div>
            </li>
            <li>
              <div className={styles.DetailItem}>
                <span>Account Balance</span>
                <span className="d-flex">
                  <span>
                    {formatMoney(
                      walletDetails?.data?.availableBalance,
                      walletTransactions?.data?.currency,
                    )}
                  </span>
                  <span className={styles.Refresh} onClick={handleRefresh}>
                    refresh
                  </span>
                </span>
              </div>
            </li>
          </ul>
        )}
      </div>

      <h5>Wallet Transactions</h5>
      <div className={styles.WalletTransactions}>{RenderedComponent}</div>
    </div>
  );
};

export default WalletDetails;
