import { ChangeEvent, MouseEvent, useState, FocusEvent } from "react";
import fuseBackgroundImage from "../../assets/images/fuse_background_img.png";
// import fuseLogo from "../../assets/svg/icons/fuseLogo.svg";
// Router
import { Link } from "react-router-dom";

// Validators
import { exists, isEmail } from "../../helpers/validators";

// Styles
import "./LoginForm.css";

interface ILoginForm {
  onAuth: (arg: any) => void;
}

const LoginForm = ({ onAuth }: ILoginForm) => {
  const [state, setState] = useState({
    loginForm: {
      username: "",
      password: "",
    },
    passwordType: "password",
  });

  const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    setState((prev) => {
      return {
        ...prev,
        loginForm: {
          ...prev.loginForm,
          [name]: value,
        },
      };
    });
  };

  // Clear all indicators on focus
  const removeErrorHandler = (e: FocusEvent) => {
    e.preventDefault();

    document.querySelector("#username")?.classList.remove("error");
    document.querySelector("#email-msg")?.classList.add("hidden");
    document.querySelector("#password")?.classList.remove("error");
    document.querySelector("#password-msg")?.classList.add("hidden");
  };

  const validateLoginCredentials = (loginData: {
    username: string;
    password: string;
  }) => {
    const { username, password } = loginData;
    const errorEmail =
      '<i class="fas fa-envelope"> </i>&nbsp; Email Field is Empty';
    const errorEmailInvalid =
      '<i class="fas fa-envelope"> </i>&nbsp; Enter a Valid Email Address';
    const errorPassword =
      '<i class="fas fa-key"> </i>&nbsp; Password Field is Empty';
    const errorMsg =
      '<i class="fas fa-user-times"> </i>&nbsp; Enter Login Credentials';

    if (username === "") {
      document.querySelector("#username")?.classList.add("error");
      document.querySelector("#email-msg")?.classList.remove("hidden");

      let emailMessageElement: Element | null =
        document.querySelector("#email-msg");

      if (emailMessageElement !== null) {
        emailMessageElement.innerHTML = errorEmail;
      }

      return false;
    }

    if (!isEmail(username)) {
      document.querySelector("#username")?.classList.add("error");
      document.querySelector("#email-msg")?.classList.remove("hidden");
      let emailMessageElement: Element | null =
        document.querySelector("#email-msg");

      if (emailMessageElement !== null) {
        emailMessageElement.innerHTML = errorEmailInvalid;
      }

      return false;
    }

    if (password === "") {
      document.querySelector("#password")?.classList.add("error");
      document.querySelector("#password-msg")?.classList.remove("hidden");

      let passwordMsgElement: Element | null =
        document.querySelector("#password-msg");

      if (passwordMsgElement !== null) {
        passwordMsgElement.innerHTML = errorPassword;
      }

      return false;
    }

    if (!(exists(username) && exists(password) && isEmail(username))) {
      document.querySelector("#password")?.classList.add("error");
      document.querySelector("#username")?.classList.add("error");
      document.querySelector("#password-msg")?.classList.remove("hidden");

      let passwordMsgElement: Element | null =
        document.querySelector("#password-msg");

      if (passwordMsgElement !== null) {
        passwordMsgElement.innerHTML = errorMsg;
      }

      return false;
    }

    let data = {
      ...loginData,
      username: loginData.username,
    };

    return data;
  };

  const loginHandler = (e: MouseEvent) => {
    e.preventDefault();
    let response = validateLoginCredentials(state.loginForm);

    if (!response) {
      return false;
    }

    onAuth(response);
  };

  const enterHandler = () => {
    window.addEventListener("keyup", function (event) {
      // 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        // Cancel default action
        event.preventDefault();
        // Trigger the button element with a click
        // Trigger the button element with a click
        let loginTrigger = document.querySelector(
          "#loginButton",
        ) as HTMLElement;
        if (loginTrigger && loginTrigger !== null) {
          loginTrigger.click();
        }
      }
    });
  };

  const togglePasswordField = () => {
    if (state.passwordType === "password") {
      setState((prev) => ({ ...prev, passwordType: "text" }));
    } else {
      setState((prev) => ({ ...prev, passwordType: "password" }));
    }
  };

  enterHandler();

  return (
    <div className="login-flex">
      <div className="form-overview">
        <div className="form-overview-h1">
          <h1 className="color-blue" style={{ fontWeight: "bold" }}>
            Welcome Back
          </h1>
          <p className="proceed-login" style={{ fontSize: "1rem" }}>
            Log in to proceed
          </p>
        </div>
        <form className="form-overview-login">
          <div className="form-overview-login-div">
            <label htmlFor="username" style={{ fontSize: "1rem" }}>
              Email Address
            </label>
            <input
              type="email"
              id="username"
              name="username"
              className=""
              placeholder="Enter Email Address"
              autoComplete="off"
              value={state.loginForm.username}
              onChange={inputChangeHandler}
              onFocus={removeErrorHandler}
            />
          </div>

          <div className="text-danger">
            <span id="email-msg" className="hidden error-element">
              <i className="fas fa-user-times" />
              &nbsp; Invalid login credentials
            </span>
          </div>
          <div
            className="form-overview-login-div"
            style={{ paddingTop: "24px", position: "relative" }}
          >
            <label htmlFor="password">Password</label>
            <input
              style={{ position: "relative" }}
              type={state.passwordType}
              name="password"
              id="password"
              className=""
              placeholder="Enter Password"
              value={state.loginForm.password}
              onChange={inputChangeHandler}
              onFocus={removeErrorHandler}
            />
            <small
              className={`togglePasswordButton`}
              style={{
                position: "absolute",
                right: "0",
                marginTop: "12px",
                marginRight: "12px",
              }}
              onClick={togglePasswordField}
            >
              {state.passwordType === "password" ? "Show" : "Hide"}
            </small>
          </div>

          <div className="text-danger">
            <span id="password-msg" className="hidden error-element">
              <i className="fas fa-user-times" />
              &nbsp; Invalid login credentials
            </span>
          </div>

          <div className="log-in-reset">
            <div className="reset">
              <Link to="/forgot-password">
                <p>
                  Forgot password ??{" "}
                  <span style={{ color: "#713fff" }}>Reset</span>{" "}
                </p>
              </Link>
            </div>
            <div className="login">
              <button
                id="loginButton"
                type="button"
                className="login-button"
                onClick={loginHandler}
              >
                Log In
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* Login Background image */}
      <div className="login-background-image">
        <img src={fuseBackgroundImage} alt="advancly background" />
      </div>
    </div>
  );
};

export default LoginForm;
