import { useEffect } from "react";
import { appInsights } from "../../../../../../config/appInsights";
import { DownloadUploadedDocument } from "../../../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import useDocumentViewer from "../../../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { v4 as uuidv4 } from "uuid";
import { documentApprovalStatus } from "../../../../../AggregatorOnboardingReview/util";
import { IBusinessDocumentation } from "../../../../Interfaces";

import styles from "./businessDocumentation.module.scss";

interface IBusinessDocumentationProps {
  data: IBusinessDocumentation;
}

const BusinessDocumentation = ({ data }: IBusinessDocumentationProps) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "BusinessDocumentation.tsx",
      isLoggedIn: true,
    });
  }, []);

  const { handleViewDocument } = useDocumentViewer();

  return (
    <div className="table-view speed-1x animated fadeInRight">
      <div className={styles.uploadedDocsContainer}>
        {data.businessDocuments && data.businessDocuments.length ? (
          <div>
            {data.businessDocuments.map((uploadedDocument, _id) => (
              <div className="row mb-5" key={_id}>
                <div className="col-12 d-flex justify-content-between">
                  <label className="form-label">
                    {_id + 1}. {uploadedDocument.documentName}
                  </label>
                </div>
                <div className="col-12 d-flex">
                  <div className="d-flex" key={uuidv4()}>
                    <div className={styles.downloadUploadDocContainer}>
                      <DownloadUploadedDocument
                        name={uploadedDocument.fileName}
                        pathname={uploadedDocument.documentUrl}
                        //   isViewDocument
                        handleView={() =>
                          handleViewDocument(
                            false,
                            uploadedDocument?.documentUrl
                          )
                        }
                      />
                    </div>

                    <div className="d-flex ml-5">
                      <div className="d-flex">
                        {uploadedDocument.status.id ===
                          documentApprovalStatus.DECLINED && (
                          <button
                            className="btn advancly-red-btn btn-md"
                            disabled
                          >
                            Declined
                          </button>
                        )}
                        {uploadedDocument.status.id ===
                          documentApprovalStatus.APPROVED && (
                          <button
                            className="btn advancly-green-btn btn-md"
                            disabled
                          >
                            Approved
                          </button>
                        )}
                        {uploadedDocument.status.id ===
                          documentApprovalStatus.PENDING && (
                          <button
                            className="btn advancly-grey-btn btn-md"
                            disabled
                          >
                            Pending
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-100 d-flex align-items-center justify-content-center py-3">
            <h3 className="font-weight-bold">
              Customer is yet to upload any document
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessDocumentation;
