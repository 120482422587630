import React from "react";
import { Loader } from "../../../../UI/Loaders/Loaders";
import usePendingRolloverRequest from "../../../../custom-hooks/usePendingRolloverRequest";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";
import { currencyFormatter } from "../../../../helpers/stringControllers";

const PendingRolloverList = () => {
  const dispatch = useDispatch();

  const {
    data: loanPendingRolloverData,
    status,
    error,
  } = usePendingRolloverRequest();

  const handlePendingRolloverApproveOrDecline = (
    tableData: any,
    action: string
  ) => {
    dispatch(
      showModal({
        modalIdentifier: "checkerapproveordeclineloanrolloverrequest",
        dataRecord: { rolloverData: tableData, action },
      })
    );
  };

  let RenderedComponent = null;
  if (!loanPendingRolloverData && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Loan Rollover
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Pending Loan Rollover ..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              pending rollover
            </h4>
          </div>
        </div>
      );
    } else if (!loanPendingRolloverData) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no pending
              rollover .
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Loan Reference</th>
                  <th>Customer</th>
                  <th>Product</th>
                  <th>Amount</th>
                  <th>Tenure</th>
                  <th>Interest (%)</th>
                  <th>Outstanding</th>
                  <th>Date Created</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {loanPendingRolloverData?.data &&
                  loanPendingRolloverData?.data?.map(
                    (request: any, _id: number) => {
                      const {
                        preferred_Loan_tenure,
                        loan_interest_rate,
                        product_name,
                        loan_outstanding,
                        pub_date,
                        loan_ref,
                        loan_amount,
                        currency,

                        borrower_details: { first_name, last_name },
                      } = request;
                      return (
                        <tr key={_id}>
                          <td>{_id + 1}</td>
                          <td>{loan_ref}</td>
                          <td>{`${first_name} ${last_name}`}</td>
                          <td>{product_name}</td>
                          <td>{currencyFormatter(loan_amount, currency)}</td>
                          <td>{preferred_Loan_tenure}</td>
                          <td>{loan_interest_rate}</td>
                          <td>
                            {currencyFormatter(loan_outstanding, currency)}
                          </td>
                          <td>{pub_date.substring(0, 10)}</td>
                          <td>
                            <div className="button-container d-flex justify-content-between">
                              <button
                                type="button"
                                className="btn advancly-btn"
                                onClick={() =>
                                  handlePendingRolloverApproveOrDecline(
                                    request,
                                    "approve"
                                  )
                                }
                              >
                                Approve
                              </button>
                              <button
                                type="button"
                                className="btn advancly-white-btn ml-3"
                                onClick={() =>
                                  handlePendingRolloverApproveOrDecline(
                                    request,
                                    "decline"
                                  )
                                }
                              >
                                Decline
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="dataTables_wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-4">
            <h3 className="page-title">Rollover Request</h3>
          </div>

          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default PendingRolloverList;
