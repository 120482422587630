import { Fragment, useEffect, useState } from "react";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import { DownloadUploadedDocument } from "../Components/DownloadUploadedDocument";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const DocumentRequests = ({ properties }) => {
  const { handleSubmit, handleReject, request } = properties;
  const [allApprovalLoading, setAllApprovalLoading] = useState(false);
  const [allDeclineLoading, setAllDeclineLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [showSingleDeclineComment, setShowSingleDeclineComment] =
    useState(false);
  const [showSingleApproveComment, setShowSingleApproveComment] =
    useState(false);
  const [showAllDeclineComment, setShowAllDeclineComment] = useState(false);
  const [showAllApproveComment, setShowAllApproveComment] = useState(false);
  const [documentIdToBeProcessed, setDocumentIdToBeProcessed] = useState({});

  useEffect(() => {
    MixPanel.track(`Page View: DocumentRequests.tsx`, {
      page: "DocumentRequests.tsx",
    });
  }, []);

  const submitHandleApprove = async (e) => {
    e.preventDefault();
    setAllApprovalLoading(true);
    handleSubmit({
      funder_id: Number(request.funder_id),
      funder_kyc_documents: [
        {
          funder_kyc_document_id: documentIdToBeProcessed,
        },
      ],
      comment: comment,
    });
  };
  const submitHandleAllApprove = async (e) => {
    e.preventDefault();
    setAllApprovalLoading(true);

    let allKycId = [];
    if (request?.files && showAllApproveComment) {
      const requests = request?.files;
      for (let i = 0; i < requests?.length; i++) {
        const funder_kyc_document_id = Number(
          requests[i].funder_kyc_document_id,
        );
        allKycId.push({ funder_kyc_document_id });
      }
    }
    await handleSubmit({
      funder_id: Number(request.funder_id),
      funder_kyc_documents: allKycId,
      comment: comment,
    });
  };
  const submitHandleDecline = async (e) => {
    e.preventDefault();
    setAllDeclineLoading(true);
    handleReject({
      funder_id: Number(request.funder_id),
      funder_kyc_documents: [
        {
          funder_kyc_document_id: Number(documentIdToBeProcessed),
        },
      ],
      comment: comment,
    });
  };
  const submitHandleAllDecline = async (e) => {
    e.preventDefault();
    setAllDeclineLoading(true);
    let allKycId = [];
    if (request?.files) {
      const requests = request?.files;
      for (let i = 0; i < requests?.length; i++) {
        const funder_kyc_document_id = Number(
          requests[i].funder_kyc_document_id,
        );
        allKycId.push({ funder_kyc_document_id });
      }
    }
    await handleReject({
      funder_id: Number(request.funder_id),
      funder_kyc_documents: allKycId,
      comment: comment,
    });
  };

  return (
    <div
      className="AuditTrailModal Modal__content modal-md"
      style={{ overflow: "hidden" }}
    >
      <div className="Modal__body">
        <Fragment>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h3 className="Modal__title page-title">{request?.funder_name}</h3>
            <div>
              {!showAllDeclineComment &&
                !showAllApproveComment &&
                !showSingleApproveComment &&
                !showSingleDeclineComment && (
                  <button
                    className="btn advancly-dimred-btn btn-sm transition-3d-hover mr-3"
                    type="button"
                    disabled={allDeclineLoading}
                    onClick={(e) => setShowAllDeclineComment(true)}
                  >
                    Decline all {allDeclineLoading && <ButtonLoader />}
                  </button>
                )}
              {!showAllDeclineComment &&
                !showAllApproveComment &&
                !showSingleApproveComment &&
                !showSingleDeclineComment && (
                  <button
                    className="btn advancly-btn btn-sm transition-3d-hover"
                    type="button"
                    disabled={allApprovalLoading}
                    onClick={(e) => setShowAllApproveComment(true)}
                  >
                    Approve all {allApprovalLoading && <ButtonLoader />}
                  </button>
                )}
              {showAllDeclineComment && (
                <button
                  className="btn advancly-dimred-btn btn-sm transition-3d-hover mr-3"
                  type="button"
                  disabled={allDeclineLoading}
                  onClick={submitHandleAllDecline}
                >
                  Proceed To Decline All {allDeclineLoading && <ButtonLoader />}
                </button>
              )}
              {showAllApproveComment && (
                <button
                  className="btn advancly-btn btn-sm transition-3d-hover mr-3"
                  type="button"
                  disabled={allApprovalLoading}
                  onClick={submitHandleAllApprove}
                >
                  Proceed To Approve All{" "}
                  {allApprovalLoading && <ButtonLoader />}
                </button>
              )}
              {showSingleDeclineComment && (
                <button
                  className="btn advancly-dimred-btn btn-sm transition-3d-hover mr-3"
                  type="button"
                  disabled={allDeclineLoading}
                  onClick={submitHandleDecline}
                >
                  Proceed To Decline {allDeclineLoading && <ButtonLoader />}
                </button>
              )}
              {showSingleApproveComment && (
                <button
                  className="btn advancly-btn btn-sm transition-3d-hover mr-3"
                  type="button"
                  disabled={allApprovalLoading}
                  onClick={submitHandleApprove}
                >
                  Proceed To Approve {allApprovalLoading && <ButtonLoader />}
                </button>
              )}
            </div>
          </div>
          <h6 className="pt-3 page-subtitle">Upload Documents</h6>
          <hr />
          <div className="w-100">
            {showSingleDeclineComment ||
            showSingleApproveComment ||
            showAllApproveComment ||
            showAllDeclineComment ? (
              <div className="col-12 mb-1">
                <label>Comment</label>
                <input
                  type="text"
                  className="form-control"
                  name="comment"
                  defaultValue={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></input>
              </div>
            ) : (
              <div>
                {request && request?.files?.length ? (
                  request?.files?.map((uploadedDocument, _id) => (
                    <div className="row" key={_id}>
                      <div className="col-12">
                        <DownloadUploadedDocument
                          name={uploadedDocument.file_name}
                          pathname={uploadedDocument.upload_path}
                          id={uploadedDocument.funder_kyc_document_id}
                          setDocumentIdToBeProcessed={
                            setDocumentIdToBeProcessed
                          }
                          setShowSingleDeclineComment={
                            setShowSingleDeclineComment
                          }
                          setShowSingleApproveComment={
                            setShowSingleApproveComment
                          }
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-100 d-flex align-items-center justify-content-center py-3">
                    <h3 className="font-weight-bold">No uploaded document</h3>
                  </div>
                )}
              </div>
            )}
          </div>
        </Fragment>
      </div>
    </div>
  );
};
export default DocumentRequests;
