import React, { useState, Fragment, useEffect } from "react";

// Redux
import { connect } from "react-redux";
import { showModal } from "../../../redux/actions/modal";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
// UI Components
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "./../../Pagination/Pagination.component";
import useAllFailedBatchDisbursement from "../../../custom-hooks/useAllFailedBatchDisbursement";
import { queryCache } from "react-query";
import { errorHandler } from "../../../helpers/errorHandler";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";

import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { appInsights } from "../../../config/appInsights";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const FailedBatchDisbursement = ({ showModal }) => {
  const [state, setState] = useState({
    size: 10,
    pageNumber: 1,
  });
  const [loading, setLoading] = useState(null);
  const [success, setSuccess] = useState(null);
  const [disburseError, setDisburseError] = useState(null);
  const { data, status, error } = useAllFailedBatchDisbursement();

  useEffect(() => {
    MixPanel.track(`Page View: FailedBatchDisbursement.tsx`, {
      page: "FailedBatchDisbursement.tsx",
    });
  }, []);

  const dialogHandler = ({ action, data }) => {
    showModal({
      action,
      dataRecord: data,
      modalIdentifier: "failedbatchdisbursement",
      subModal: "single",
      retryFailedBatchLoanDibursement,
    });
  };

  const resetTable = () => {
    setState({
      ...state,
      pageNumber: 1,
    });
  };
  const retryFailedBatchLoanDibursement = async (reference) => {
    setLoading("Retry Batch Loans...");
    setDisburseError(null);
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.RETRY_FAILED_BATCH_DISBURSEMENT +
          "?batchdisbursementKey=" +
          reference,
      );
      setLoading(null);
      setSuccess(response.message);
      queryCache.invalidateQueries(["getBatchDisbursement"]); // setSuccess("loan has been disbursed");
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "FailedBatchDisbursement.js",
        },
      });
      setLoading(null);
      setDisburseError(errorHandler(error));
      setTimeout(() => {
        setDisburseError(null);
      }, 3000);
    }
  };
  const { size, pageNumber } = state;

  let RenderedComponent = null;

  //  If loans object is equal to null e.g. Initial component render before API fetch
  if (error) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> Unable to Fetch Loans
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader text="Loading Failed Batch Loans..." centered={true} />
    );
  } else if (loading) {
    RenderedComponent = <Loader centered={true} text={loading} />;
  } else if (disburseError) {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> {disburseError}
          </h4>
        </div>
      </div>
    );
  } else if (success) {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> {success}
          </h4>
        </div>
      </div>
    );
  } else if (data?.length === 0) {
    RenderedComponent = (
      <div className="centerLoader">
        <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There are Currently No Batch Loans to
            Retry
          </h4>
        </div>
      </div>
    );
  } else if (data?.length > 0) {
    RenderedComponent = (
      <Fragment>
        <MDBTable responsive minimal="true" striped bordered hover>
          <MDBTableHead>
            <tr>
              <th>S/N</th>
              <th>Aggregator</th>
              <th>Product</th>
              <th>Amount</th>
              <th>No of Loans</th>
              <th>Manage</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {data &&
              data
                .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                .map((failedBatchDisbursementData, _id) => {
                  const { productName, aggregator, amount, loanCount } =
                    failedBatchDisbursementData;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{aggregator}</td>
                      <td>{productName}</td>
                      <td>{amount}</td>
                      <td>{loanCount}</td>
                      <td>
                        <span
                          className="details btn-manage zoom-element btn-slim"
                          onClick={() => {
                            dialogHandler({
                              action: "failedbatchdisbursementdetails",
                              data: failedBatchDisbursementData,
                            });
                          }}
                        >
                          Details
                        </span>
                        <span
                          className="retry btn-manage zoom-element btn-slim"
                          onClick={() => {
                            dialogHandler({
                              action: "retryfailedbatchdisbursement",
                              data: failedBatchDisbursementData,
                            });
                          }}
                        >
                          Retry
                        </span>
                      </td>
                    </tr>
                  );
                })}
          </MDBTableBody>
        </MDBTable>
        <Paginator
          size={size}
          page={pageNumber}
          count={data?.length}
          changeCurrentPage={(pageNumber) => {
            setState({ ...state, pageNumber });
          }}
        />
      </Fragment>
    );
  }
  return (
    <div className="dataTables_wrapper">
      <div className="m-l-1 m-t-2 m-b-1">
        <label htmlFor="table-fetch" className="form-group">
          Number of results:{" "}
          <select
            onChange={(e) => {
              resetTable();
              setState({ ...state, size: e.target.value });
            }}
            id="table-fetch"
            className="table-fetch-select"
            value={size}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </label>
      </div>
      <div
        id="activeEcosystem"
        className="Disbursement table-view animated speed-1x fadeInRight"
      >
        <div className="m-l-1 m-r-1">
          {/* All Loans Ready for Disbursement Datatable || Loader || Error Message*/}
          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default connect(null, { showModal })(FailedBatchDisbursement);
