import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAggregatorEnvironment = async (id) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.GET_AN_AGGREGATOR_ONBOARDING_STATUS}?customer_id=${id}`
  );
  return data;
};

export default function useAggregatorEnvironment(id) {
  // The id is a query identifier here
  return useQuery([id, "getAggregatorEnvironment"], getAggregatorEnvironment, {
    refetchOnWindowFocus: false,
  });
}
