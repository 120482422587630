import { useEffect, useState } from "react";

import { appInsights } from "../../../../config/appInsights";
import { TFundingWallet } from "../../../../custom-hooks/useFundingWallets";
import CustomInputField from "../../../../components/CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomSelectDropdown from "../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postData } from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { showModal } from "../../../../redux/actions/modal";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import { getObjectFromLocalStorage } from "../../../../helpers/localStorage";
import { StorageKeys } from "../../../../helpers/storageKeys";

import styles from "./fundRequest.module.scss";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const schema = yup.object().shape({
  tenorType: yup.string().required("Tenor Type is required"),
  tenor: yup.number().min(3).max(12).required("Tenor is required"),
  amount: yup.string().required("Amount is required"),
});

enum TenorType {
  DAILY = 0,
  WEEKLY = 1,
  MONTHLY = 2,
}

type TFormPayload = {
  amount: string;
  tenor: number;
  tenorType: string;
};

const FundingRequest = ({ properties: { dataRecord }, onCloseModal }: any) => {
  const [walletInfo, setWalletInfo] = useState<TFundingWallet>();
  const [isLoading, setIsLoading] = useState(false);
  const [tenorDuration, setTenorDuration] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const handleFormatAmount = (amount: string) => {
    return Number(amount.replace(/,/g, ""));
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "FundRequest",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: FundingRequest.tsx`, {
      page: "FundingRequest.tsx",
    });
  }, []);

  useEffect(() => {
    if (dataRecord) {
      setWalletInfo(dataRecord);
    }
  }, [dataRecord]);

  const { register, handleSubmit, errors, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const durationType = [
    {
      name: "Daily",
      value: 0,
    },
    {
      name: "Weekly",
      value: 1,
    },
    {
      name: "Monthly",
      value: 2,
    },
  ];

  const onSubmit = async (values: TFormPayload) => {
    setIsLoading(true);
    const payload = {
      fundingWalletId: walletInfo?.walletId,
      amount: handleFormatAmount(values?.amount),
      tenor: values?.tenor,
      tenorType: values?.tenorType,
    };

    try {
      const response = await postData(ajaxEndpoints.FUNDING_REQUEST, payload);

      if (response?.data?.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "success",
              message: response?.data?.message,
            },
          }),
        );
        setTimeout(() => {
          history.push("/funding-wallets");
        }, 2000);
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: response?.data?.message,
            },
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        }),
      );
    }
    setIsLoading(false);
  };

  const getTenorDuration = (_tenorType: string) => {
    if (Number(tenorType) === TenorType.DAILY) {
      return setTenorDuration("Days");
    }

    if (Number(tenorType) === TenorType.WEEKLY) {
      return setTenorDuration("Weeks");
    }

    if (Number(tenorType) === TenorType.MONTHLY) {
      return setTenorDuration("Months");
    }
  };

  const tenorType = watch("tenorType");

  useEffect(() => {
    if (tenorType) {
      getTenorDuration(tenorType);
    }
  }, [tenorType]);

  return (
    <div
      className={`Modal__dialog md-modal animated slideInDown ${styles.FundingWalletDetails}`}
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1 page-title d-flex align-items-center">
            <BackButtonComponent
              customGoBack={() => {
                dispatch(
                  showModal({
                    modalIdentifier: "fundingwalletdetails",
                    dataRecord: getObjectFromLocalStorage(
                      StorageKeys.FUNDING_WALLET_DETAILS,
                    ),
                  }),
                );
              }}
            />
            Funding Request ({walletInfo?.aggregatorName})
          </h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                <CustomInputField
                  type="text"
                  enableSeparator
                  label="Amount"
                  reference={register}
                  name="amount"
                  placeholder="e.g 100,000,000"
                  errors={errors.amount}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                <CustomSelectDropdown
                  label="Tenor Type"
                  reference={register}
                  name="tenorType"
                  errors={errors.tenorType}
                >
                  <option value="">Select Tenor Type</option>
                  {durationType.map((_item, index) => (
                    <option key={index} value={_item?.value}>
                      {_item?.name}
                    </option>
                  ))}
                </CustomSelectDropdown>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                <CustomInputField
                  type="number"
                  // label={`Tenor (3-12) ${tenorDuration}`}
                  label={`Tenor`}
                  reference={register}
                  name="tenor"
                  errors={errors.tenor}
                />
              </div>
            </div>

            <div className="row">
              <button className="ml-3 btn advancly-btn">
                {isLoading ? <ButtonLoader /> : "Submit Request"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FundingRequest;
