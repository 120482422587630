import { useState } from "react";

import addCircleIcon from "../../../../assets/svg/icons/add-circle.svg";
import minusCircleIcon from "../../../../assets/svg/icons/minus-cirlce.svg";

import styles from "./accordion.module.scss";

interface ICustomOnboardingAccordion {
  children: React.ReactNode;
  title: string;
  subTitle: string;
}

const Accordion = ({
  children,
  title,
  subTitle,
}: ICustomOnboardingAccordion) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.AccordionContainer}>
      <div className={styles.settingsCard}>
        <div className={styles.title}>
          <h1>{title}</h1>
          <p>{subTitle}</p>
        </div>
        <div>
          {isOpen && (
            <img
              src={minusCircleIcon}
              onClick={() => setIsOpen((prev) => !prev)}
              className="cursor-pointer"
            />
          )}
          {!isOpen && (
            <img
              src={addCircleIcon}
              onClick={() => setIsOpen((prev) => !prev)}
              className="cursor-pointer"
            />
          )}
        </div>
      </div>
      {isOpen && <div className={styles.Content}>{children}</div>}
    </div>
  );
};

export default Accordion;
