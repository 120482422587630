import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import { Loader } from "../../../../../UI/Loaders/Loaders";
import { appInsights } from "../../../../../config/appInsights";
import Paginator from "../../../../Pagination/Pagination.component";
import CustomSelectDropdown from "../../../../CustomHTMLElements/CustomSelectDropdown";
import { usePendingTierUpgradeRequests } from "../../../../../custom-hooks/usePendingTierUpgradeRequests";
import { UpradeType } from "../../../Interfaces";

const UpgradeToTierThree = () => {
  const [query, setQuery] = useState({
    searchQuery: "",
    page: 1,
    pageSize: 10,
    searchValue: "",
    startDate: "",
  });
  const [state, setState] = useState({
    searchQuery: "",
    page: 1,
    pageSize: 10,
    searchValue: "",
    startDate: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "UpgradeToTierThree.tsx",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: pendingTierUpgradeRequests,
    status,
    error,
  } = usePendingTierUpgradeRequests({
    pageNumber: query?.page,
    size: query?.pageSize,
    dateCreated: query?.startDate,
    TierType: 3,
  });

  let RenderedComponent = null;
  if (!pendingTierUpgradeRequests && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Tier Upgrade
            Requests Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Pending Tier Upgrade Requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Pending Tier Upgrade Requests, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (pendingTierUpgradeRequests &&
        pendingTierUpgradeRequests.data &&
        pendingTierUpgradeRequests.data.length === 0) ||
      (pendingTierUpgradeRequests && !pendingTierUpgradeRequests.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Pending Tier
              Upgrade Requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone Number</th>
                <th>Acct No</th>
                <th>Email Address</th>
                <th>Date Created</th>
                <th>Gender</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {pendingTierUpgradeRequests &&
                pendingTierUpgradeRequests.data.map((data, _id: number) => {
                  const {
                    accountNumber,
                    dateCreated,
                    email,
                    firstName,
                    gender,
                    id,
                    lastName,
                    phoneNumber,
                  } = data;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      {/* <td>
                        {" "}
                        {(searchQuery?.Page - 1) * searchQuery?.PageSize +
                          (_id + 1)}
                      </td> */}
                      <td>{firstName}</td>
                      <td>{lastName}</td>
                      <td>{phoneNumber}</td>
                      <td>{accountNumber}</td>
                      <td>{email}</td>
                      <td>{dateCreated}</td>
                      <td>{gender}</td>
                      <td>
                        <span
                          className="color-mid-blue cursor-pointer font-weight-bold"
                          onClick={() => {
                            history.push({
                              pathname: `/db/tier-upgrade/${data?.id}`,
                              state: {
                                tier: UpradeType.TIER_TWO,
                              },
                            });
                          }}
                        >
                          {" "}
                          View Request
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setState((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const filterData = () => {
    setQuery(state);
  };

  return (
    <div>
      <div className="">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="row">
            <div className="form-group col-md-4">
              <label>Search by name, account number or email address</label>
              <div className="input-group">
                <input
                  type="search"
                  className="form-control"
                  value={state.searchValue}
                  name="searchValue"
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="form-group col-md-2">
              <label>From</label>
              <div className="input-group">
                <input
                  type="date"
                  name="startDate"
                  className="form-control"
                  value={state.startDate}
                  onChange={handleFilterChange}
                />
              </div>
            </div>

            <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
              <div className="input-group">
                <button
                  className="btn advancly-btn ml-2 form-control"
                  onClick={filterData}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
              <div className="input-group">
                <CustomSelectDropdown
                  name="aggregatorName"
                  onChange={handleFilterChange}
                  isShowDropdownArrow
                >
                  <option value={10}>10 Entries</option>
                  <option value={20}>20 Entries</option>
                  <option value={50}>50 Entries</option>
                  <option value={100}>100 Entries</option>
                </CustomSelectDropdown>
              </div>
            </div>
          </div>

          <div>{RenderedComponent}</div>

          <Paginator
            size={state.pageSize}
            page={state.page}
            count={pendingTierUpgradeRequests?.totalCount}
            changeCurrentPage={(pageNumber: number) =>
              setState({ ...state, page: pageNumber })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default UpgradeToTierThree;
