import { useEffect, useState } from "react";

import { appInsights } from "../../../../config/appInsights";
import CustomInputField from "../../../../components/CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomSelectDropdown from "../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postData } from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { showModal } from "../../../../redux/actions/modal";
import useFundingWalletDetail from "../../../../custom-hooks/useFundingWalletDetails";
import { formatMoney } from "../../../../helpers/formatter";

import styles from "./approveDecline.module.scss";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

enum ApprovalDecision {
  APPROVE = "approve",
  DECLINE = "decline",
}

type TFormPayload = {
  comment: string;
  otp?: string;
  approvalDecision: string;
};

const schema = yup.object().shape({
  approvalDecision: yup.string().required("Approval decision is required"),
  comment: yup.string().required("Comment is required"),
  otp: yup.string().required("Otp is required"),
});

const ApproveDeclineFundingRequest = ({
  properties: { dataRecord },
  onCloseModal,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    appInsights.trackPageView({
      name: "ApproveDeclineFundingRequest",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: ApproveDeclineFundingRequest.tsx`, {
      page: "ApproveDeclineFundingRequest.tsx",
    });
  }, []);

  const { data: walletDetails, isLoading: isLoadingWalletDetails } =
    useFundingWalletDetail({
      aggregatorId: dataRecord?.aggregatorId!,
      walletId: dataRecord?.walletId,
    });

  const { register, handleSubmit, errors, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const approvalOptions = [
    {
      name: "Approve",
      value: ApprovalDecision.APPROVE,
    },
    {
      name: "Decline",
      value: ApprovalDecision.DECLINE,
    },
  ];

  const onSubmit = async (values: TFormPayload) => {
    setIsLoading(true);
    const payload = {
      comment: values?.comment,
      otp: values?.otp,
      loan_ref: dataRecord?.makerCheckRefNo,
      approve:
        values?.approvalDecision === ApprovalDecision?.APPROVE ? true : false,
    };
    try {
      const response = await postData(
        ajaxEndpoints.APPROVE_DECLINE_FUNDING_REQUEST,
        payload,
      );

      if (response?.data?.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "success",
              message: response?.data?.message,
            },
          }),
        );
        setTimeout(() => {
          history.push("/funding-wallets");
        }, 2000);
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: response?.data?.message,
            },
          }),
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ApproveDeclineFundingRequest",
        },
      });

      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        }),
      );
    }
    setIsLoading(false);
  };

  return (
    <div
      className={`Modal__dialog md-modal animated slideInDown ${styles.ApproveDeclineFundingRequest}`}
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className={`Modal__title p-b-1 ${styles.HeaderTitle}`}>
            Funding Request
          </h3>

          <p>What decision will you like to make?</p>

          <div className={styles.Details}>
            {isLoadingWalletDetails ? (
              <div className="d-flex justify-content-center">
                <ButtonLoader />
              </div>
            ) : (
              <ul>
                <li>
                  <div className={styles.DetailItem}>
                    <span>Amount</span>
                    <span>
                      {formatMoney(dataRecord?.amount, dataRecord?.currency)}
                    </span>
                  </div>
                </li>
                <li>
                  <div className={styles.DetailItem}>
                    <span>Duration Type</span>
                    <span>{dataRecord?.tenorType}</span>
                  </div>
                </li>
                <li>
                  <div className={styles.DetailItem}>
                    <span>Duration</span>
                    <span>{dataRecord?.tenor}</span>
                  </div>
                </li>
                <li>
                  <div className={styles.DetailItem}>
                    <span>Bank Name</span>
                    <span>{walletDetails?.data?.bankName}</span>
                  </div>
                </li>
                <li>
                  <div className={styles.DetailItem}>
                    <span>Account Name</span>
                    <span>{walletDetails?.data?.accountName}</span>
                  </div>
                </li>
                <li>
                  <div className={styles.DetailItem}>
                    <span>Account Number</span>
                    <span>{walletDetails?.data?.accountNumber}</span>
                  </div>
                </li>
                <li>
                  <div className={styles.DetailItem}>
                    <span>Account Balance</span>
                    <span>
                      {formatMoney(
                        walletDetails?.data?.availableBalance,
                        dataRecord?.currency,
                      )}
                    </span>
                  </div>
                </li>
              </ul>
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-2">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <CustomSelectDropdown
                  label="Approval Decision"
                  reference={register}
                  name="approvalDecision"
                  errors={errors.approvalDecision}
                >
                  <option value="">Select Option</option>
                  {approvalOptions.map((_item, index) => (
                    <option key={index} value={_item?.value}>
                      {_item?.name}
                    </option>
                  ))}
                </CustomSelectDropdown>
              </div>
            </div>

            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
                <CustomInputField
                  type="text"
                  label="Comment"
                  reference={register}
                  name="comment"
                  errors={errors.comment}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <CustomInputField
                  type="text"
                  label="2FA Code"
                  reference={register}
                  name="otp"
                  errors={errors.otp}
                />
              </div>
            </div>

            <div className="row">
              <button className="ml-3 btn advancly-btn">
                {isLoading ? <ButtonLoader /> : "Proceed"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApproveDeclineFundingRequest;
