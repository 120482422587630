import { FormEvent, Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextArea from "../../../../NewComponents/TypedComponents/CustomHTMLElements/CustomTextArea";
import { useRouteMatch, useLocation } from "react-router-dom";
import Alert from "../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { postData } from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { UpradeType } from "../../Interfaces";
import { IPendingTierUpgradeRequest } from "../../../../custom-hooks/usePendingTierUpgradeRequests";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";

const schema = yup.object().shape({
  message: yup
    .string()
    .trim()
    .required("Please enter your message")
    .min(5, "Message must have at least 5 characters")
    .max(256, "Message cannot be longer than 256 characters"),
});

export default function TierUpgradeReviewModal({
  dismissModalRef,
  modalActionType,
  declineRequest,
  loading,
  initialOnboardingCode,
}: any) {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [success, setSuccess] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();

  const { tier, data } = location?.state as {
    tier: UpradeType;
    data: IPendingTierUpgradeRequest;
  };

  const match = useRouteMatch();
  const { id } = match.params as { id: number };

  const handleApproveTierUpgrade = async (e: FormEvent) => {
    e.preventDefault();
    setUpdating(true);
    try {
      const payload = {
        customerId: id,
        tierType: tier,
        email: data?.email,
      };
      const response = await postData(
        ajaxEndpoints.DB_APPROVE_KYC_TIER_UPGRADE,
        payload
      );

      if (response?.data?.status_code === 200) {
        dismissModalRef.current.click();
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setUpdating(false);
  };

  const handleCloseModal = () => {
    const modalClassList = document.getElementById("addReviewTierUpgradeModal");
    if (modalClassList) {
      return modalClassList.classList;
    }
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div
        className="modal fade"
        id="addReviewTierUpgradeModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addReviewTierUpgradeModalLabel"
        aria-hidden="true"
        onClick={handleCloseModal}
      >
        <div
          className={`modal-dialog ${
            modalActionType === "cancel" && "modal-sm"
          } modal-dialog-centered`}
          role="document"
        >
          <div className="modal-content">
            {(() => {
              switch (modalActionType) {
                case "approve":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="page-subtitle">
                          Are you sure you want to approve tier upgrade?
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Fragment>
                          {error && (
                            <p className="alert alert-danger small">{error}</p>
                          )}
                          <p className="text-justify">
                            A mail will be sent to inform this Customer once you
                            have approved their tier upgrade request.
                          </p>
                          <form onSubmit={handleApproveTierUpgrade}>
                            <div className="row">
                              <div className="col-12 d-flex justify-content-end flex-wrap">
                                <button
                                  className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                  type="button"
                                  data-dismiss="modal"
                                  disabled={loading || updating}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                                  type="submit"
                                  disabled={loading || updating}
                                >
                                  {loading ? "Approving..." : "Approve Request"}
                                </button>
                              </div>
                            </div>
                          </form>
                        </Fragment>
                      </div>
                    </Fragment>
                  );
                case "decline":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="page-subtitle">
                          Are you sure you want to decline tier upgrade?
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="text-justify">
                          Please add a comment to complete this process. A mail
                          will be sent to inform this Customer once you have
                          declined.
                        </p>
                        <form onSubmit={handleSubmit(declineRequest)}>
                          <div className="row">
                            <div className="col-12">
                              <CustomTextArea
                                maxLength={256}
                                reference={register}
                                placeholder="Enter message here..."
                                label="Admin Comment"
                                name="message"
                                showRequiredIcon={true}
                                errors={errors.message}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                                disabled={loading || updating}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                disabled={loading || updating}
                              >
                                {loading ? "Declining..." : "Decline Upgrade"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
