import React, { useEffect } from "react";
import { ReactComponent as UserIcon } from "../../../../assets/svg/icons/user-no-fill.svg";
import { appInsights } from "../../../../config/appInsights";
import useDirectDebitListAccounts from "../../../../custom-hooks/useDirectDebitListAccounts";
import { Loader } from "../../../../UI/Loaders/Loaders";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const DirectDebitDetails = ({ dataRecord }: { dataRecord: any }) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "Loan Details View  - (DirectDebitDetails.tsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: DirectDebitDetails`, {
      page: "DirectDebitDetails",
    });
  }, []);

  const customerId = dataRecord?.borrower_details?.customer_id;

  const {
    data: directDebitListAccountsData,
    isLoading: DirectDebitListAccountsLoading,
    error: DirectDebitListAccountsError,
  } = useDirectDebitListAccounts(customerId);

  return (
    <div
      id="topOfPage"
      style={{
        padding: "1rem",
        height: "70vh",
        scrollbarColor: "auto",
        overflowY: "scroll",
      }}
    >
      <header
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <UserIcon />
          User:{" "}
          <span>{`${dataRecord?.borrower_details?.first_name}  ${dataRecord?.borrower_details?.last_name}`}</span>
        </div>
        <h2>Direct Debit Details</h2>
      </header>

      <div className="py-3">
        {DirectDebitListAccountsLoading ? (
          <Loader />
        ) : directDebitListAccountsData?.bank_accounts?.length > 0 ? (
          <div className="mt-3 mb-3 pb-3 " style={{ width: "80%" }}>
            <div className="row m-x-sm m-y-2 grid__padding">
              <div className="table-responsive">
                <table className="table">
                  <thead className="bg-lighter-grey">
                    <tr>
                      <th>S/N</th>
                      <th>Bank Code</th>
                      <th>Bank Name</th>
                      <th>Account Name</th>
                      <th>Account Number</th>
                    </tr>
                  </thead>
                  {directDebitListAccountsData?.bank_accounts?.map(
                    (accountData: any, index: number) => (
                      <tbody key={accountData.account_number}>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{accountData.bank_code}</td>
                          <td>{accountData.bank_name}</td>
                          <td>{accountData?.account_name ?? "NIL"}</td>
                          <td>{accountData?.account_number ?? "NIL"}</td>
                        </tr>
                      </tbody>
                    ),
                  )}
                </table>
              </div>
            </div>
          </div>
        ) : DirectDebitListAccountsError ? (
          <p style={{ marginTop: "20px" }}>
            An error occurred, unable to fetch available account
          </p>
        ) : (
          <p style={{ marginTop: "20px" }}>
            No Direct Debit Account has been added
          </p>
        )}
      </div>
    </div>
  );
};

export default DirectDebitDetails;
