import { useState } from "react";
import ToggleTab from "../../../NewComponents/ToggleTab/ToggleTab.component";
import UpgradeToTierTwo from "./Tabs/UpgradeToTierTwo";
import UpgradeToTierThree from "./Tabs/UpgradeToTierThree";

enum Tabs {
  UPGRADE_TO_TIER_TWO = "upgrade-to-tier-two",
  UPGRADE_TO_TIER_THREE = "upgrade-to-tier-three",
}

const TierUpgrade = () => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.UPGRADE_TO_TIER_TWO);

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x animated fadeInRight">
        <h3 className="page-title mb-4">Tier Upgrade Requests</h3>
        <div className="alignToggleTabItems mb-3">
          <ToggleTab
            text="Upgrade to Tier 2"
            id={Tabs.UPGRADE_TO_TIER_TWO}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Upgrade to Tier 3"
            id={Tabs.UPGRADE_TO_TIER_THREE}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div>
          {(() => {
            switch (activeTab) {
              case Tabs.UPGRADE_TO_TIER_TWO:
                return <UpgradeToTierTwo />;
              case Tabs.UPGRADE_TO_TIER_THREE:
                return <UpgradeToTierThree />;

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default TierUpgrade;
