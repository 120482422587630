import React, { useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// UI Components
import { Loader } from "../../../../UI/Loaders/Loaders";
import useRolledOverLoans from "../../../../custom-hooks/useRolledOverLoans";
import { currencyFormatter } from "../../../../helpers/stringControllers";
import { attachClassNameToTag } from "../../../../helpers/attachClassNameToTag";
import { accurateQuantifier } from "./../../../../helpers/accurateQuantifier";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const ViewRolledOverLoans = ({ dataRecord }) => {
  const { data, status, error } = useRolledOverLoans({
    parent_rollover_loan_reference: dataRecord.parent_rollover_loan_reference,
  });

  useEffect(() => {
    MixPanel.track(`Page View: RolledOverLoans.tsx`, {
      page: "RolledOverLoans.tsx",
    });
  }, []);

  //JSX Template
  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch roll over Loans
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading all roll over loans..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              roll over loans, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (data && data.data && data.data.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no roll over
              loans for this borrower
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <MDBTable responsive minimal="true" bordered hover>
          <MDBTableHead>
            <tr>
              <th>S/N</th>
              <th>Loan Ref</th>
              <th>Tenure</th>
              <th>Repayment Frequency</th>
              <th>Amount</th>
              <th>Amount to Repay</th>
              <th>Pub Date</th>
              <th>Settlement Date</th>
              <th>Parent R/V Loan Ref</th>
              <th>Roll Over Loan Ref</th>
              <th>Loan Status</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {data &&
              data.data &&
              data.data.map((loans, _id) => {
                const {
                  loan_amount,
                  loan_amount_repay,
                  status,
                  pub_date,
                  settlement_day,
                  loan_ref,
                  loan_tenure,
                  repayment_type,
                  currency,
                  parent_rollover_loan_reference,
                  rollover_loan_reference,
                } = loans;
                return (
                  <tr key={_id}>
                    <td>{_id + 1}</td>
                    <td>{loan_ref}</td>
                    <td>{accurateQuantifier(loan_tenure, "day")}</td>
                    <td>{repayment_type}</td>
                    <td>{currencyFormatter(Number(loan_amount), currency)}</td>
                    <td>
                      {currencyFormatter(Number(loan_amount_repay), currency)}
                    </td>
                    <td>{pub_date?.substring(0, 10)}</td>
                    <td>
                      {settlement_day ? settlement_day?.substring(0, 10) : ""}
                    </td>
                    <td>{parent_rollover_loan_reference}</td>
                    <td>{rollover_loan_reference}</td>
                    <td>
                      <span className={attachClassNameToTag(status)}>
                        {attachClassNameToTag(status)}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </MDBTableBody>
        </MDBTable>
      );
    }
  }

  return (
    <div className="table-view speed-1x fadeInRight">{RenderedComponent}</div>
  );
};

export default ViewRolledOverLoans;
