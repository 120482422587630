import { MDBDataTable } from "mdbreact";
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { appInsights } from "../../../config/appInsights";
import useAggregatorWalletSignatories from "../../../custom-hooks/useAllAgggregatorSignatories";
import useAllProductType from "../../../custom-hooks/useAllProductType";
import { putDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import { showModal } from "../../../redux/actions/modal";
import { LabelledInput } from "../../../UI/Inputs/Inputs";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const AggregatorProfile = (props: any) => {
  const [productIds, setProductIds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<{
    columns: { label: string; field: string }[];
    rows: any;
  }>();

  useEffect(() => {
    MixPanel.track(`Page View: AggregatorProfile.tsx`, {
      page: "AggregatorProfile.tsx",
    });
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    aggregator_rep,
    biz_category,
    biz_email,
    biz_phone,
    is_active,
    biz_address,
  } = props.aggregatorDetails;

  const { data: availableProductTypes } = useAllProductType();
  const {
    data: walletSignatories,
    error: walletSignatoriesError,
    isError,
    isLoading: isLoadingWalletSignatories,
  } = useAggregatorWalletSignatories(props.aggregator_id);

  useEffect(() => {
    if (walletSignatories) {
      setTableData({
        columns: [
          {
            label: "Name",
            field: "name",
          },
          {
            label: "Email",
            field: "email",
          },
          {
            label: "Level No",
            field: "levelNo",
          },
        ],
        rows: walletSignatories?.data,
      });
    }
  }, [walletSignatories]);

  useEffect(() => {
    if (props.aggregatorProductTypes?.data?.data.length > 0) {
      let ids = props.aggregatorProductTypes?.data?.data.map(
        (item: any) => item?.id,
      );
      setProductIds(ids);
    }
  }, [props.aggregatorProductTypes?.data?.data]);

  const _getAggregatorProductTypes = (listInQuestion: any[]) => {
    if (listInQuestion && listInQuestion.length > 0) {
      const result = listInQuestion.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      });

      return result;
    }

    return [];
  };

  const cleanProductTypes = (arrayInQuestion: any[]) => {
    if (arrayInQuestion && arrayInQuestion.length > 0) {
      const result = arrayInQuestion.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });

      return result;
    }

    return [];
  };

  const onProductTypeChange = (listInQuestion: any[]) => {
    const productTypeIds = listInQuestion.map((item) => item.value);
    setProductIds(productTypeIds);
  };

  const updateAggregatorProducType = async () => {
    setIsLoading(true);
    try {
      const payload = {
        aggregator_id: Number(props.aggregator_id),
        product_type_ids: productIds,
      };
      const response = await putDataWithDotNet(
        "/account/aggregator_products",
        payload,
      );
      if (response.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.message },
          }),
        );

        setTimeout(() => {
          history.push("/manage-ecosystems");
        }, 2000);
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.message },
          }),
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorProfile.js",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        }),
      );
    }
    setIsLoading(false);
  };

  if (aggregator_rep) {
    return (
      <div className="col-sm-12 mt-30 animated fadeInRight">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card">
              <div className="body text-center">
                <h3 className="m-t-0 m-b-1">
                  <i className="adv-icon fas fa-id-card-alt" />
                  &nbsp; Aggregator Representative
                </h3>
                <div className="grid__full settings-block">
                  <LabelledInput
                    inputValue={`${aggregator_rep.first_name} ${aggregator_rep.last_name}`}
                    label="Email"
                    icon="fas fa-user-circle"
                  />

                  <LabelledInput
                    inputValue={aggregator_rep.personal_email}
                    label="Full Name"
                    icon="far fa-envelope"
                  />

                  <LabelledInput
                    inputValue={aggregator_rep.personal_phone}
                    label="Phone"
                    icon="fas fa-phone"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card">
              <div className="body text-center">
                <h3 className="m-t-0 m-b-1">
                  <i className="adv-icon fas fa-cogs" />
                  &nbsp; Aggregator Profile
                </h3>
                <div className="grid__full settings-block">
                  <label className="label-txt">Email</label>
                  <div className="form-group inputWithIcon">
                    <input
                      className="user__form form-control"
                      readOnly={true}
                      value={biz_email}
                    />
                    <i className="far fa-envelope" aria-hidden={true} />
                  </div>
                  <label className="label-txt">Phone</label>
                  <div className="form-group inputWithIcon">
                    <input
                      className="user__form form-control"
                      readOnly={true}
                      value={biz_phone}
                    />
                    <i className="fas fa-phone" aria-hidden={true} />
                  </div>
                  <label className="label-txt">Address</label>
                  <div className="form-group inputWithIcon">
                    <textarea
                      className="user__form form-control sm-radius"
                      readOnly={true}
                      value={`  ${biz_address}`}
                    ></textarea>
                    <i className="far fa-address-card" aria-hidden={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="card">
              <div className="body text-center">
                <h3 className="m-t-0 m-b-1">
                  <i className="adv-icon fas fa-cogs" />
                  &nbsp; Sector Profile
                </h3>

                <div className="grid__full m-b-1">
                  <div className="p-l-0 grid__padding">
                    <label className="label-txt">Sector</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        className="user__form form-control"
                        value={biz_category}
                        readOnly={true}
                      />
                      <i className="fas fa-greater-than" aria-hidden={true} />
                    </div>
                  </div>
                </div>

                <div className="grid__full settings-block">
                  <label className="label-txt">Sector Status</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={is_active ? "Active" : "Inactive"}
                      readOnly={true}
                    />
                    <i className="fas fa-greater-than" aria-hidden={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card">
              <div className="body text-center">
                <h3 className="m-t-0 m-b-1">
                  <i className="adv-icon fas fa-cogs" />
                  &nbsp; Aggregator Product Type
                </h3>
              </div>
              <div className="grid__full m-b-1">
                <div className="p-l-0 grid__padding">
                  <CustomSearchDropDown
                    label="Select Product Type"
                    labelClass="d-block"
                    defaultValue={_getAggregatorProductTypes(
                      props.aggregatorProductTypes?.data?.data,
                    )}
                    defaultOptions={cleanProductTypes(availableProductTypes)}
                    onChange={onProductTypeChange}
                    isSearchable={false}
                    isMulti={true}
                  />
                  <div className="d-flex justify-content-end">
                    <button
                      data-mode="close"
                      className="create zoom-element btn m-0 login-btn p-2"
                      onClick={updateAggregatorProducType}
                    >
                      {isLoading ? "Loading..." : <>Submit</>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card">
              <div className="body text-center">
                <h3 className="m-t-0 m-b-1">
                  <i className="adv-icon fas fa-cogs" />
                  &nbsp; Aggregator Wallet Signatories
                </h3>
              </div>
              <div className="grid__full m-b-1">
                <div className="p-l-0 grid__padding">
                  {isLoadingWalletSignatories ? (
                    <p className="text-center">Loading...</p>
                  ) : (
                    <div>
                      {walletSignatories &&
                        walletSignatories?.data?.length > 0 && (
                          <MDBDataTable
                            responsive
                            minimal={true}
                            striped
                            bordered
                            hover
                            data={tableData}
                          />
                        )}
                      {isError && (
                        <p className="text-center text-danger">
                          {walletSignatoriesError?.response?.data?.message}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="centerLoader">
        <div className="grid__padding animated speed-1x fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" />
            Could not fetch aggregator data.
          </h4>
        </div>
      </div>
    );
  }
};

export default AggregatorProfile;
