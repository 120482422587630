import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { USE_MOCK } from "../appConstants";
import { IPaginatedResponse } from "../interfaces/response";

type TParams = {
  customerId: string;
  accountNumber: string;
};

export interface ICustomerTransactions {
  accountNumber: string;
  transactionAmount: number;
  balance: number;
  transactionDate: string;
  createdDate: string;
  transactionType: string;
  narration: string;
  clientId: number;
  currencyCode: string;
}

export const getCustomerTransactions = async ({
  accountNumber,
  customerId,
}: TParams): Promise<IPaginatedResponse<ICustomerTransactions[]>> => {
  if (USE_MOCK) {
    // Return mock data using setTimeout to simulate network delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          message: "Requests fetched successfully!",
          status: true,
          status_code: 200,
          page: 1,
          pageSize: 10,
          totalCount: 100,
          totalPages: 100,
          data: [
            {
              accountNumber: "4415153651",
              transactionAmount: 300000,
              balance: 4700000,
              transactionDate: "2024-09-05T00:00:00",
              createdDate: "2024-09-05T17:30:00.732105",
              transactionType: "DEBIT",
              narration: "test payment",
              clientId: 75614,
              currencyCode: "NGN",
            },
          ],
        });
      }, 5000);
    });
  }

  const { data } = await getData(
    `${ajaxEndpoints.DB_CUSTOMER_TRANASCTIONS}?isAdmin=true&customerId=${customerId}&accountNumber=${accountNumber}`
  );
  return data;
};

export const useCustomerTransactions = (params: TParams) => {
  return useQuery<IPaginatedResponse<ICustomerTransactions[]>, Error>(
    ["getCustomerTransactions", params],
    () => getCustomerTransactions(params)
  );
};
