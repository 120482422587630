import { ChangeEvent, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import LoanProductDetail from "./LoanProductDetail/LoanProductDetail";
import { showModal } from "../../../redux/actions/modal";
import useProductCategory from "../../../custom-hooks/useProductCategory";

import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { appInsights } from "../../../config/appInsights";
import { putData } from "../../../newApis/dotNetApiMethods";
import { v4 as uuidv4 } from "uuid";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import { TEditProductData } from "../types";

import styles from "./EditLoanProduct.module.scss";
import useLoanProductById from "../../../custom-hooks/useLoanProductById";
import ButtonLoader from "../../../UI/Loaders/Loaders";

const EditLoanProduct = () => {
  const [productName, setProductName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [alertError, setAlertError] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [productDetailList, setProductDetailList] = useState<
    TEditProductData[]
  >([
    {
      unique_id: uuidv4(),
      amortization_type_id: 0,
      amortization_type: "",
      core_banking_product_id: "",
      core_banking_tenant: "",
      currency: "",
      disbursement_bank_id: 0,
      disbursement_bank_name: "",
      disbursement_account_name: "",
      disbursement_account_number: "",
      disbursement_channel_id: 0,
      disbursement_channel_account_id: 0,
      interest_type: "",
      interest_type_id: 0,
      loan_type: "",
      loan_type_id: 0,
      maximum_amount: 0,
      maximum_interest_rate: 0,
      maximum_tenor: 0,
      minimum_amount: 0,
      minimum_interest_rate: 0,
      minimum_tenor: 0,
      moratorium_type_id: 0,
      moratorium_type: "",
      repayment_bank_id: 0,
      repayment_bank_name: "",
      repayment_account_name: "",
      repayment_account_number: "",
      repayment_channel_account_id: 0,
      repayment_channel_id: 0,
      maximum_running_loans: 0,
      repayment_type: "",
      moratorium_duration: "",
      id: 0,
      loan_product_id: 0,
      loan_calculation_method: "",
    },
  ]);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const state = location.state as { id: number; isEdit: boolean };
  const { data: productCategories } = useProductCategory();
  const { data: loanProduct } = useLoanProductById(state?.id);

  useEffect(() => {
    if (loanProduct && loanProduct?.data?.data) {
      let result = loanProduct?.data?.data?.loan_product_details.map(
        (_item) => {
          return {
            ..._item,
            unique_id: uuidv4(),
            loan_product_id: loanProduct?.data?.data?.id,
          };
        }
      );

      setProductCategory(loanProduct?.data?.data.loan_product_category_id);
      setProductName(loanProduct?.data?.data?.name);
      setProductDetailList(result);
    }
  }, [loanProduct]);

  const handleChangeProductName = (e: ChangeEvent<HTMLInputElement>) => {
    return setProductName(e.target.value);
  };

  const handleChangeCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    return setProductCategory(e.target.value);
  };

  const handleEditLoanProduct = async () => {
    setIsCreating(true);
    if (productName === "" || productCategory === "") {
      setAlertError("You have not entered product name or category");
      return setTimeout(() => {
        setAlertError("");
      }, 3000);
    }
    setAlertError("");

    let _productDetail = productDetailList.map((data) => {
      return {
        minimum_amount: data?.minimum_amount
          ? Number(data?.minimum_amount)
          : null,
        maximum_amount: data?.maximum_amount
          ? Number(data?.maximum_amount)
          : null,
        minimum_interest_rate: data?.minimum_interest_rate
          ? Number(data?.minimum_interest_rate)
          : null,
        maximum_interest_rate: data?.maximum_interest_rate
          ? Number(data?.maximum_interest_rate)
          : null,
        interest_type_id: data?.interest_type_id
          ? Number(data?.interest_type_id)
          : null,
        minimum_tenor: data?.minimum_tenor ? Number(data?.minimum_tenor) : null,
        maximum_tenor: data?.maximum_tenor ? Number(data?.maximum_tenor) : null,
        disbursement_channel_id: data?.disbursement_channel_id
          ? Number(data?.disbursement_channel_id)
          : null,
        disbursement_channel_account_id: data?.disbursement_channel_account_id
          ? Number(data?.disbursement_channel_account_id)
          : null,
        disbursement_bank_id: data?.disbursement_bank_id
          ? Number(data?.disbursement_bank_id)
          : null,
        disbursement_account_number: data?.disbursement_account_number
          ? String(data?.disbursement_account_number)
          : null,
        disbursement_account_name: data?.disbursement_account_name
          ? String(data?.disbursement_account_name)
          : null,
        repayment_channel_id: data?.repayment_channel_id
          ? Number(data?.repayment_channel_id)
          : null,
        repayment_channel_account_id: data?.repayment_channel_account_id
          ? Number(data?.repayment_channel_account_id)
          : null,
        repayment_bank_id: data?.repayment_bank_id
          ? Number(data?.repayment_bank_id)
          : null,
        maximum_running_loans: data?.maximum_running_loans
          ? Number(data?.maximum_running_loans)
          : null,
        repayment_account_number: data?.repayment_account_number
          ? String(data?.repayment_account_number)
          : null,
        repayment_account_name: data?.repayment_account_name
          ? String(data?.repayment_account_name)
          : null,
        core_banking_tenant: data?.core_banking_tenant
          ? data?.core_banking_tenant
          : null,
        core_banking_product_Id: data?.core_banking_product_id
          ? Number(data?.core_banking_product_id)
          : null,
        repayment_type: data?.repayment_type ? data?.repayment_type : null,
        moratorium_type_id: data?.moratorium_type_id
          ? Number(data?.moratorium_type_id)
          : null,
        amortization_type_id: data?.amortization_type_id
          ? Number(data?.amortization_type_id)
          : null,
        moratorium_duration: data?.moratorium_duration
          ? String(data?.moratorium_duration)
          : null,
        loan_type_id: data?.loan_type_id ? Number(data?.loan_type_id) : null,
        id: data?.id ? data?.id : null,
        loan_product_id: state?.id ? state?.id : null,
      };
    });

    try {
      const payload = {
        id: state?.id,
        name: productName,
        loan_product_category_id: Number(productCategory),
        loan_product_details: _productDetail,
      };
      const response = await putData(ajaxEndpoints.LOAN_PRODUCT, {
        ...payload,
      });

      if (response?.data?.status_code === 200) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        setTimeout(() => {
          history.push(`/loan-product`);
        }, 2000);
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsCreating(false);
  };

  const handelAddProductDetail = () => {
    setProductDetailList((prev) => {
      return [
        ...prev,
        {
          unique_id: uuidv4(),
          amortization_type_id: 0,
          core_banking_product_id: "",
          core_banking_tenant: "",
          currency: "",
          disbursement_bank_id: 0,
          disbursement_account_name: "",
          disbursement_account_number: "",
          disbursement_channel_id: 0,
          disbursement_channel_account_id: 0,
          interest_type: "",
          interest_type_id: 0,
          loan_type_id: 0,
          maximum_amount: 0,
          maximum_interest_rate: 0,
          maximum_tenor: 0,
          minimum_amount: 0,
          minimum_interest_rate: 0,
          minimum_tenor: 0,
          moratorium_type: "",
          moratorium_type_id: 0,
          repayment_bank_id: 0,
          repayment_account_name: "",
          repayment_account_number: "",
          repayment_channel_account_id: 0,
          repayment_channel_id: 0,
          repayment_type: "",
          id: 0,
          loan_product_id: 0,
          maximum_running_loans: 0,
          moratorium_duration: "",
        },
      ];
    });
  };

  const handleDeleteProductDetail = (id: string) => {
    // let result = productDetailList.filter(
    //   (_detail) => _detail?.unique_id !== id
    // );
    // setProductDetailList(result);
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "Create Loan Product - (CreateLoanProduct.tsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="animated fadeInRight">
      <div className="mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />{" "}
          {state.isEdit ? " Edit Loan Product" : "View Loan Product"}
        </h3>
      </div>

      {alertError && <Alert message={alertError} />}

      <div className={styles.ActionContainer}>
        {state.isEdit && (
          <div className={styles.FormButtonContainer}>
            <button
              className={`btn advancly-btn btn-md ${styles.SearchActionButton}`}
              onClick={handleEditLoanProduct}
            >
              Edit
              {isCreating && <ButtonLoader />}
            </button>
          </div>
        )}
      </div>

      <div className="row p-3">
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-sm-6">
              <label>Product Name</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={handleChangeProductName}
                  value={productName}
                  readOnly={!state.isEdit ? true : false}
                />
                <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              </div>
            </div>
            <div className="col-sm-6">
              <label>Product Category</label>
              <div className="form-group inputWithIcon">
                <select
                  className="round user__form form-control"
                  name="loan_product_category_id"
                  placeholder="Select a Product"
                  onChange={handleChangeCategory}
                  value={productCategory}
                  disabled={!state.isEdit ? true : false}
                >
                  <option value="">Select a Product Category</option>
                  {productCategories &&
                    productCategories?.data?.data.map((_productCategory) => (
                      <option
                        key={_productCategory?.id}
                        value={_productCategory?.id}
                      >
                        {_productCategory?.name}
                      </option>
                    ))}
                </select>
                <i className="adv-aggregator-icon fas fa-university"></i>{" "}
              </div>
            </div>
          </div>
          <div className={styles.ProductDetailsContainer}>
            {productDetailList.map((_productDetail, index) => (
              <LoanProductDetail
                key={index}
                handleDeleteProductDetail={handleDeleteProductDetail}
                data={_productDetail}
                productDetailList={productDetailList}
                setProductDetailList={setProductDetailList}
              />
            ))}
          </div>

          <div className="row d-flex justify-content-end pl-2 pr-3 mt-4">
            {state.isEdit && (
              <button
                className="btn advancly-btn btn-sm m-0 transition-3d-hover"
                onClick={handelAddProductDetail}
              >
                <i className="fas fa-plus mr-3" />
                Add Product Detail
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLoanProduct;
