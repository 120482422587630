import { useEffect } from "react";
import { appInsights } from "../../../../../../config/appInsights";
import CustomInputField from "../../../../../CustomHTMLElements/CustomInputField";
import Accordion from "../../../../Components/Accordion";
import { DownloadUploadedDocument } from "../../../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import useDocumentViewer from "../../../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { IRegisteredTrustees } from "../../../../Interfaces";

interface IRegisteredTrusteesProps {
  data: IRegisteredTrustees[];
}

const RegisteredTrustees = ({
  data: registeredTrustees,
}: IRegisteredTrusteesProps) => {
  const { handleViewDocument } = useDocumentViewer();

  useEffect(() => {
    appInsights.trackPageView({
      name: "RegisteredTrustees.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      {registeredTrustees.map((_item, _id) => (
        <div className="mb-3" key={_id}>
          <Accordion
            title={`Registered Trustee (${_id + 1})`}
            subTitle="Click the - icon to see more information on the registered trustee"
          >
            <div className="row">
              <div className="col-md-5">
                <CustomInputField
                  label="First Name"
                  type="text"
                  name="firstName"
                  defaultValue={_item?.firstName!}
                  disabled
                />
                <CustomInputField
                  label="Email"
                  type="text"
                  name="email"
                  defaultValue={_item?.email!}
                  disabled
                />
                <CustomInputField
                  label="Academic & Professional Qualification"
                  type="text"
                  name="educationalQualification"
                  defaultValue={_item?.educationalQualification!}
                  disabled
                />
                <CustomInputField
                  label="Experience relevant to the role"
                  type="text"
                  name="experienceRelevantToRole"
                  defaultValue={_item?.experienceRelevantToRole!}
                  disabled
                />
                <CustomInputField
                  label="Work Experience"
                  type="text"
                  name="workExperience"
                  defaultValue={_item?.workExperience!}
                  disabled
                />

                <div className="mb-5">
                  <DownloadUploadedDocument
                    name={"Passport"}
                    pathname={_item?.passportUrl}
                    handleView={() =>
                      handleViewDocument(false, _item?.passportUrl!)
                    }
                  />
                </div>

                <div className="mb-5">
                  <DownloadUploadedDocument
                    name={"ID Upload"}
                    pathname={_item?.governmentIdFilePath}
                    handleView={() =>
                      handleViewDocument(false, _item?.governmentIdFilePath)
                    }
                  />
                </div>

                <div className="mb-5">
                  <DownloadUploadedDocument
                    name={"Proof of Address"}
                    pathname={_item?.proofOfAddressFilePath}
                    handleView={() =>
                      handleViewDocument(false, _item?.proofOfAddressFilePath)
                    }
                  />
                </div>
              </div>
              <div className="col-md-5">
                <CustomInputField
                  label="Last Name"
                  type="text"
                  name="lastName"
                  defaultValue={_item?.lastName!}
                  disabled
                />
                <CustomInputField
                  label="Country"
                  type="text"
                  name="countryCode"
                  defaultValue={_item?.countryCode!}
                  disabled
                />
                <CustomInputField
                  label="National Identity Number"
                  type="text"
                  name="identityNumber"
                  defaultValue={_item?.identityNumber!}
                  disabled
                />

                <CustomInputField
                  label="Period on the company board"
                  type="text"
                  name="periodInCompanyBoard"
                  defaultValue={_item?.periodInCompanyBoard!}
                  disabled
                />

                <CustomInputField
                  label="Phone Number"
                  type="text"
                  name="phoneNumber"
                  defaultValue={_item?.phoneNumber!}
                  disabled
                />
              </div>
            </div>
          </Accordion>
        </div>
      ))}

      {registeredTrustees && registeredTrustees.length < 1 && (
        <div className="w-100 d-flex align-items-center justify-content-center py-3">
          <h3 className="font-weight-bold">
            Customer is yet to submit Regsitered Trustees
          </h3>
        </div>
      )}
    </div>
  );
};

export default RegisteredTrustees;
