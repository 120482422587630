import React, { Fragment, useEffect, useState } from "react";
import { useCustomerOrdersDetails } from "../../../../custom-hooks/useCustomerOrdersDetails";
import { formatMoney } from "../../../../helpers/formatter";
import Loader from "../../../../NewComponents/Loader/Loader.component";
import Paginator from "../../../Pagination/Pagination.component";
import CustomerOrderItemsModal from "./CustomerOrderItemsModal";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const CustomerOrderData = ({ customerId }: any) => {
  const [count, setCount] = useState(null);
  const [orderItems, setOrderItems] = useState<any | null>(null);
  const [search, setSearch] = useState<{
    PageSize: number;
    Page: number;
    CustomerId: number;
    DateFrom: any;
    DateTo: any;
  }>({
    PageSize: 20,
    Page: 1,
    CustomerId: customerId,
    DateFrom: null,
    DateTo: null,
  });
  const [presearch, setPresearch] = useState({
    Page: 1,
    PageSize: 20,
    CustomerId: customerId,
  });
  const {
    data: ordersData,
    status,
    error,
  } = useCustomerOrdersDetails(presearch);

  useEffect(() => {
    MixPanel.track(`Page View: CustomerOrderData.tsx`, {
      page: "CustomerOrderData.tsx",
    });
  }, []);

  if (ordersData) {
    if (count === null) {
      setCount(ordersData?.totalCount);
    }
  }
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value, Page: search.Page, PageSize: 20 });
  };
  let RenderedComponent = null;
  if (!ordersData && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Customer Order Data
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    //@ts-ignore
    RenderedComponent = <Loader centered={true} text="Loading Repayment..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Customer Order, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (ordersData && ordersData?.data?.length === 0) ||
      (ordersData && !ordersData?.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Order Id</th>
                  <th>Order Amount</th>
                  <th>Order Sub-Amount</th>
                  <th>Discount Amount</th>
                  <th>Tax Amount </th>
                  <th>Order Date </th>
                  <th>Order Currency </th>
                  <th>Payment Type</th>
                  <th>Customer Category</th>
                  <th>Customer Rating</th>
                  <th>Customer Phone Number</th>
                  <th>Total Items</th>
                  <th>Is Credit</th>
                  <th>Store Name</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Country</th>
                  <th>Order Items</th>
                </tr>
              </thead>
              <tbody>
                {ordersData?.data?.map((data: any, id: number) => {
                  const {
                    orderId,
                    orderSubAmount,
                    discountAmount,
                    taxAmount,
                    orderAmount,
                    orderDate,
                    orderCurrency,
                    paymentType,
                    customerPhoneNo,
                    customerRating,
                    customerCategory,
                    storeName,
                    address,
                    city,
                    state,
                    country,
                    // gpsLocation,
                    isCredit,
                    totalItems,
                    orderItems,
                  } = data;
                  return (
                    <tr key={id}>
                      <td>{id + 1}</td>
                      <td>{orderId}</td>
                      <td>{formatMoney(orderAmount)}</td>
                      <td>{formatMoney(orderSubAmount)}</td>
                      <td>{formatMoney(discountAmount)}</td>
                      <td>{formatMoney(taxAmount)}</td>
                      <td>{orderDate?.substring(0, 10)}</td>
                      <td>{orderCurrency}</td>
                      <td>{paymentType}</td>
                      <td>{customerCategory}</td>
                      <td>{customerRating}</td>
                      <td>{customerPhoneNo}</td>
                      <td>{totalItems}</td>
                      <td>{isCredit}</td>
                      <td>{storeName}</td>
                      <td>{address}</td>
                      <td>{city}</td>
                      <td>{state}</td>
                      <td>{country}</td>
                      <td>
                        <button
                          type="button"
                          className="btn advancly-btn"
                          data-toggle="modal"
                          data-target="#customerOrderItems"
                          onClick={(e) => {
                            e.preventDefault();
                            setOrderItems(orderItems);
                          }}
                        >
                          View Items
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="dataTables_wrapper">
        <div
          id="activeEcosystem"
          className="Disbursement table-view animated speed-1x fadeInRight"
        >
          <form className="d-flex flex-row flex-wrap">
            <div className="form-group mr-3 mt-2">
              <div className="input-group ">
                <label className="mr-2">From </label>
                <input
                  type="date"
                  name="DateFrom"
                  className="form-control"
                  value={search.DateFrom}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="form-group mt-2 mr-3">
              <div className="input-group">
                <label className="mr-2">To </label>
                <input
                  type="date"
                  className="form-control"
                  name="DateTo"
                  value={search.DateTo}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="btn advancly-btn btn-md"
                value="submit"
                disabled={status === "loading" || !ordersData}
                onClick={() => setPresearch(search)}
              >
                Search
              </button>
            </div>
          </form>

          {RenderedComponent}
          <Paginator
            size={presearch.PageSize}
            page={presearch.Page}
            count={count}
            changeCurrentPage={(Page: any) => setPresearch({ ...search, Page })}
          />
        </div>
      </div>
      <CustomerOrderItemsModal orderItems={orderItems} />
    </Fragment>
  );
};

export default CustomerOrderData;
