import React, { FormEvent, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setAdvancedCustomerSearchParams } from "../../../../redux/actions/digitalBank";
// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import { RootState } from "../../../../redux/store";
import { IBusinessCustomerSearchParams } from "../../../../components/DigitalBank/Interfaces";
import { hideModal, showModal } from "../../../../redux/actions/modal";

const AdvancedCustomersSearchModal = ({ onCloseModal, dataRecord }: any) => {
  const [state, setState] = useState<IBusinessCustomerSearchParams>();

  const dispatch = useDispatch();

  const { customerType } = dataRecord;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setAdvancedCustomerSearchParams(state!));
    dispatch(hideModal());
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <React.Fragment>
            <h3 className="Modal__title p-b-1">Search By:</h3>
            {/* 2. Confirm Update Role Modal*/}
            <div id="disbursement-confirm">
              <div className="m-b-30">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3 col-sm-12 mt-2">
                      <label htmlFor="table-fetch">Email:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setState((prev) => {
                            return {
                              ...prev!,
                              email: e.target.value,
                            };
                          });

                          //     setBusinessCustomerSearchParams({
                          //       ...searchParams,

                          //     })
                          //   );
                        }}
                        name="borrowerName"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12 mt-2">
                      <label htmlFor="table-fetch">Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setState((prev) => {
                            return {
                              ...prev!,
                              name: e.target.value,
                            };
                          });
                        }}
                        name="loanId"
                      />
                    </div>
                    <div className="col-md-3 col-sm-12 mt-2">
                      <label htmlFor="table-fetch">PhoneNumber:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setState((prev) => {
                            return {
                              ...prev!,
                              phoneNumber: e.target.value,
                            };
                          });
                        }}
                        name="bvn"
                      />
                    </div>

                    {customerType === "businessCustomers" && (
                      <>
                        <div className="col-md-3 col-sm-12 mt-2">
                          <label htmlFor="table-fetch">AccountNumber:</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setState((prev) => {
                                return {
                                  ...prev!,
                                  accountNumber: e.target.value,
                                };
                              });
                            }}
                            name="rcNumber"
                          />
                        </div>
                        <div className="col-md-3 col-sm-12 mt-2">
                          <label htmlFor="table-fetch">RcNumber:</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setState((prev) => {
                                return {
                                  ...prev!,
                                  rcNumber: e.target.value,
                                };
                              });
                            }}
                            name="loanRef"
                          />
                        </div>

                        <div className="col-md-3 col-sm-12 mt-2">
                          <label htmlFor="table-fetch">From:</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) => {
                              setState((prev) => {
                                return {
                                  ...prev!,
                                  startDate: e.target.value,
                                };
                              });
                            }}
                            name="startDate"
                            placeholder="YYYY/MM/DD"
                          />
                        </div>

                        <div className="col-md-3 col-sm-12 mt-2">
                          <label htmlFor="table-fetch">To:</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) => {
                              setState((prev) => {
                                return {
                                  ...prev!,
                                  endDate: e.target.value,
                                };
                              });
                            }}
                            name="endDate"
                            placeholder="YYYY/MM/DD"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="Form__action-buttons m-t-2">
                    <button
                      type="submit"
                      className="btn zoom-element btn login-btn m-0 mr-auto"
                    >
                      <i className="fa fa-check-circle" /> Search
                    </button>
                    {/* <button
                      id="create"
                      data-id="create-role"
                      data-action="create"
                      className="create zoom-element btn login-btn"
                      // onClick={getLoanRecordsModal}
                    >
                      <i className="fas fa-download" /> &nbsp; Download
                    </button> */}
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(setAdvancedCustomerSearchParams({}));
                        onCloseModal();
                      }}
                      className="btn zoom-element btn login-btn m-0 ml-auto"
                    >
                      <i className="fa fa-times-circle" /> Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {/* 2. Disbursement Loader*/}
            <div
              id="disbursement-loader"
              className="hidden loading-overlay speed-1x fadeInRight"
            >
              <div className=" form-loader form-loader-login verify">
                <div className="centerLoader">
                  <div className="loading" id="adv-loader-alt" />
                  <div className="center-txt webapp-ripple-text">
                    <i className="notifier fas fa-user-cog" />
                    Searching ...
                  </div>
                </div>
              </div>
            </div>

            {/* 3. Disbursement Success Message*/}
            <div
              id="disbursement-success"
              className="hidden loading-overlay speed-1x fadeInRight"
            >
              <div className="form-loader form-loader-login verify">
                <div className="centerLoader">
                  <div className="loading success-loader">
                    <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                  </div>
                  <div className="center-txt webapp-ripple-text">
                    <i className="notifier far fa-check-circle" />
                    Search Successful
                  </div>
                </div>
              </div>
            </div>

            {/* 4. Disbursement Error */}
            <div
              id="disbursement-error"
              className="hidden loading-overlay speed-1x fadeInRight"
            >
              <ErrorLoader
                icon="fas fa-wifi"
                text="Network Issue"
                subText="Unable to connect to Advancly services."
              />
            </div>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default AdvancedCustomersSearchModal;
