import React, { Fragment, useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import BackButtonComponent from "../../../../../../../NewComponents/BackButton/BackButton.component";
import Alert from "../../../../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { appInsights } from "../../../../../../../config/appInsights";
import ToggleTab from "../../../../../../../NewComponents/ToggleTab/ToggleTab.component";
import General from "./General";
import Transactions from "./Transactions";
import { useCustomerAccountDetail } from "../../../../../../../custom-hooks/useCustomerAccountDetails";
import { formatMoney } from "../../../../../../../helpers/formatter";
import { Loader } from "../../../../../../../UI/Loaders/Loaders";

import styles from "./selectedAccountDetail.module.scss";

const SelectedAccountDetail = () => {
  const [activeTab, setActiveTab] = useState("general");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const {
    data: accountDetails,
    isLoading,
    status,
  } = useCustomerAccountDetail({
    customerId: searchParams.get("customerId")!,
    accountNumber: searchParams.get("accountNumber")!,
  });

  console.log("accountDetails: ", accountDetails);

  useEffect(() => {
    appInsights.trackPageView({
      name: "SelectedAccountDetail.tsx",
      isLoggedIn: true,
    });
  }, []);

  let RenderedComponent = null;
  if (!accountDetails && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Account Details
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Account Details..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Account Details Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (accountDetails && !accountDetails.data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> No Account Details
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div>
          <div className={styles.statistics}>
            <div className={styles.statCard}>
              <p>Product</p>
              <h1>{accountDetails?.data?.product}</h1>
            </div>
            <div className={styles.statCard}>
              <p>Account Number</p>
              <h1>{accountDetails?.data?.accountNumber}</h1>
            </div>
            <div className={styles.statCard}>
              <p>Available Balance</p>
              <h1>{formatMoney(accountDetails?.data?.availableBalance)}</h1>
            </div>
            <div className={styles.statCard}>
              <p>Current Balance</p>
              <h1>{formatMoney(accountDetails?.data?.currentBalance)}</h1>
            </div>
          </div>

          <div className="alignToggleTabItems mb-3 mt-5">
            <ToggleTab
              text="General"
              id="general"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ToggleTab
              text="Transactions"
              id="transactions"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>

          {(() => {
            switch (activeTab) {
              case "general":
                return <General data={accountDetails?.data!} />;

              case "transactions":
                return <Transactions />;

              default:
                break;
            }
          })()}
        </div>
      );
    }
  }

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> {searchParams.get("accountName")!}
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          <div>
            <button
              className="btn advancly-btn btn-md mr-2"
              //   data-toggle="modal"
              //   data-target="#addReviewTierUpgradeModal"
              //   onClick={() => setModalActionType("approve")}
            >
              Place Lien
            </button>
            <button
              className="btn advancly-white-btn btn-md"
              //   data-toggle="modal"
              //   data-target="#addReviewTierUpgradeModal"
              //   onClick={() => setModalActionType("decline")}
            >
              Post No Debit
            </button>
          </div>
        </div>
      </div>

      <React.Fragment>{RenderedComponent}</React.Fragment>
    </Fragment>
  );
};

export default SelectedAccountDetail;
