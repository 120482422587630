import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// UI Components
import { InfoLoader, Loader } from "../../../UI/Loaders/Loaders";
import { useEffect, useState } from "react";
import Paginator from "../../Pagination/Pagination.component";
import { currencyFormatter } from "../../../helpers/stringControllers";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";
import { BASE_URL } from "../../../appConstants";
import { getTokenFromLocalStorage } from "../../../helpers/localStorage";
import { useHistory } from "react-router-dom";
import { message } from "antd";

const Partners = (props: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);
  const [size] = useState(20);
  let {
    partnersTableData,
    loading,
    aggregator_id,
    getAggregatorDrilldown,
    showModal,
  } = props;

  const history = useHistory();

  useEffect(() => {
    MixPanel.track(`Page View: Aggregator`, {
      page: "Aggregator",
    });
  }, []);

  let token = getTokenFromLocalStorage();

  function aggregatorBorrowerRecords() {
    setIsDownloading(true);
    fetch(
      BASE_URL +
        `/account/aggregator_major_details/export?aggregator_id=${aggregator_id}&fileType=excel`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      },
    )
      .then((response) => {
        setIsDownloading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `aggregator borrower records.csv`);
            link.click();
            // link.parentNode.removeChild(link);
          });
        }

        if (response.status === 400) {
          // response.json()
          // .then(res => {
          //     this.setState({ error: res.message });
          // })
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          message.error(
            "Something went wrong. Please check your internet connection and try again",
          );
        }
      })
      .catch((err) => message.error("Something went wrong."));
  }

  const modalHandler = (modalData: any) => {
    showModal({
      modalIdentifier: "Partner",
      dataRecord: modalData,
      action: "form",
    });
  };

  let RenderedComponent = null;

  if (partnersTableData === undefined) {
    RenderedComponent = (
      <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
        <InfoLoader
          text="There was issue fetching Available Partners."
          subText="Please reload the page after a few seconds"
        />
      </div>
    );
  } else if (loading) {
    RenderedComponent = (
      <Loader centered={true} text="Loading available Partners ..." />
    );
  } else {
    // Verify that a issues object exists
    if (partnersTableData === undefined || partnersTableData === null) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> Problem Fetching Exisiting Issues,
              Please Reload the Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (partnersTableData.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are Currently No Partners on
              your Platform
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="grid__padding animated fadeInRight text-center">
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>First Name</th>
                <th>Last Name </th>
                <th>Email </th>
                <th>Phone</th>
                <th>Debt Balance</th>
                <th>Loan</th>
                <th>Repaid</th>
                <th>Trust Score</th>
                <th>Status</th>
                <th>Borrower Type</th>
                <th>Customer Type</th>
                <th>Manage</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {partnersTableData
                .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                .map((partner: any, _id: number) => {
                  const {
                    first_name,
                    last_name,
                    borrower_phone,
                    debt_balance,
                    total_loan_collected,
                    total_loan_repaid,
                    trust_score,
                    is_active,
                    borrower_type,
                    customer_category,
                    email,
                  } = partner;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{first_name}</td>
                      <td>{last_name}</td>
                      <td>{email}</td>
                      <td>{borrower_phone}</td>
                      <td>{currencyFormatter(parseFloat(debt_balance))}</td>
                      <td>
                        {currencyFormatter(parseFloat(total_loan_collected))}
                      </td>
                      <td>
                        {currencyFormatter(parseFloat(total_loan_repaid))}
                      </td>
                      <td>{trust_score}</td>
                      <td
                        className={`btn-display ${
                          is_active ? "bg-success" : "bg-warning"
                        }`}
                      >
                        {is_active ? "Active" : "Inactive"}
                      </td>
                      <td className="text-capitalize">
                        {borrower_type && Number(borrower_type) === 1
                          ? "Individual"
                          : Number(borrower_type) === 2
                            ? "Corporate"
                            : ""}
                      </td>
                      <td>{customer_category || "unavailable"}</td>

                      <td className="d-flex flex-row">
                        <span
                          className="Partner view btn-manage zoom-element btn-slim"
                          onClick={() => {
                            modalHandler(partner);
                          }}
                        >
                          View
                        </span>
                        <span
                          className="Partner Disable btn-manage zoom-element btn-slim"
                          onClick={() => {
                            modalHandler(partner);
                          }}
                        >
                          Disable
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
          <Paginator
            size={size}
            page={pageNumber}
            count={partnersTableData.length}
            changeCurrentPage={(pageNumber: number) =>
              setPageNumber(pageNumber)
            }
          />
        </div>
      );
    }
  }

  return (
    <div className="Transactions datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div>
            <button
              id="create"
              data-id="create-role"
              data-action="create"
              className="create btn advancly-btn m-r-1"
              onClick={(e) => {
                showModal({
                  modalIdentifier: "Partner",
                  action: "advanceSearch",
                  dataRecord: aggregator_id,
                });
              }}
            >
              <i className="fas fa-search" />
              &nbsp; Advance Search
            </button>

            <button
              id="create"
              data-id="create-role"
              data-action="create"
              className="create btn advancly-btn"
              onClick={aggregatorBorrowerRecords}
            >
              {isDownloading ? (
                <span className="spinner-border spinner-border-sm m-r-1"></span>
              ) : (
                <i className="fas fa-download m-r-1" />
              )}
              Download
            </button>
          </div>
        </div>

        {RenderedComponent}
      </div>
    </div>
  );
};

export default Partners;
