import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { USE_MOCK } from "../appConstants";
import { IPaginatedResponse } from "../interfaces/response";
import { IBusinessCustomerSearchParams } from "../components/DigitalBank/Interfaces";

export interface IBusinessCustomerParams extends IBusinessCustomerSearchParams {
  pageNumber: number;
  size: number;
  searchValue?: string;
}

export enum TCustomerType {
  INDIVIDUAL = "1",
  CORPORATE = "2",
}

export enum TProductDomain {
  DIGITAL_BANK = "3",
}

export interface IBusinessCustomer {
  businessName: string;
  businessType: string;
  sector: string;
  businessEmail: string;
  rcNumber: string;
  requestDate: string;
  id: number;
  onboardingRequestDate: string;
  accountActivationDate: string | null;
}

export const getBusinessCustomers = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  searchValue,
  accountNumber,
  businessType,
  email,
  name,
  phoneNumber,
  rcNumber,
}: IBusinessCustomerParams): Promise<
  IPaginatedResponse<IBusinessCustomer[]>
> => {
  if (USE_MOCK) {
    // Return mock data using setTimeout to simulate network delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          message: "Requests fetched successfully!",
          status: true,
          status_code: 200,
          page: 1,
          pageSize: 10,
          totalCount: 100,
          totalPages: 100,
          data: [
            {
              id: 1,
              businessName: "Test",
              businessType: "User",
              sector: "Agriculture",
              businessEmail: "testuser@example.com",
              rcNumber: "2345678",
              requestDate: "2024-01-01",
              accountActivationDate: "2024-01-01",
              onboardingRequestDate: "2024-01-01",
            },
          ],
        });
      }, 5000);
    });
  }

  let queryParams = `?Page=${pageNumber}&PageSize=${size}&CustomerType=${TCustomerType?.CORPORATE}&ProductDomain=${TProductDomain?.DIGITAL_BANK}`;
  if (startDate) queryParams += `&startDate=${startDate}`;
  if (endDate) queryParams += `&endDate=${endDate}`;
  if (searchValue) queryParams += `&searchValue=${searchValue}`;
  if (email) queryParams += `&email=${email}`;
  if (name) queryParams += `&name=${name}`;
  if (phoneNumber) queryParams += `&phoneNumber=${phoneNumber}`;
  if (accountNumber) queryParams += `&accountNumber=${accountNumber}`;
  if (rcNumber) queryParams += `&rcNumber=${rcNumber}`;
  if (businessType) queryParams += `&businessType=${businessType}`;
  if (startDate) queryParams += `&startDate=${startDate}`;
  if (endDate) queryParams += `&endDate=${endDate}`;

  const { data } = await getData(
    `${ajaxEndpoints.DB_BUSINESS_CUSTOMERS}${queryParams}`
  );

  return data;
};

export const useBusinessCustomers = (params: IBusinessCustomerParams) => {
  return useQuery<IPaginatedResponse<IBusinessCustomer[]>, Error>(
    ["getBusinessCustomers", params],
    () => getBusinessCustomers(params),
    {
      refetchOnWindowFocus: false,
    }
  );
};
