import { useEffect } from "react";

import moment from "moment";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import { Loader } from "../../../../../../../../UI/Loaders/Loaders";
import { appInsights } from "../../../../../../../../config/appInsights";
import { useCustomerTransactions } from "../../../../../../../../custom-hooks/useCustomerTransactions";
import { useLocation } from "react-router-dom";
import { formatMoney } from "../../../../../../../../helpers/formatter";

const Transactions = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Transactions.tsx",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: customerTransactions,
    status,
    error,
  } = useCustomerTransactions({
    accountNumber: searchParams.get("accountNumber")!,
    customerId: searchParams.get("customerId")!,
  });

  let RenderedComponent = null;
  if (!customerTransactions && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Transactions
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Transactions..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Transactions Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (customerTransactions &&
        customerTransactions.data &&
        customerTransactions.data.length === 0) ||
      (customerTransactions && !customerTransactions.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no transactions
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Transaction Type</th>
                <th>Amount</th>
                <th>Transaction Date</th>
                <th>Balance</th>
                <th>Narration</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {customerTransactions &&
                customerTransactions.data.map((data, _id: number) => {
                  const {
                    balance,
                    clientId,
                    currencyCode,
                    narration,
                    transactionAmount,
                    transactionDate,
                    transactionType,
                  } = data;
                  return (
                    <tr key={clientId}>
                      <td>{_id + 1}</td>
                      <td>{transactionType}</td>
                      <td>{formatMoney(transactionAmount, currencyCode)}</td>
                      <td>
                        {transactionDate
                          ? moment(transactionDate).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      <td>{formatMoney(balance, currencyCode)}</td>
                      <td>{narration}</td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  return (
    <div className="table-view speed-1x animated fadeInRight">
      <div>{RenderedComponent}</div>
    </div>
  );
};

export default Transactions;
