import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";

import { formatMoney } from "../../../../../../helpers/formatter";
import { useEffect } from "react";
import { MixPanel } from "../../../../../../shared/MixPanel/MixPanel";

const OrderItems = ({ orderItemlist }: any) => {
  useEffect(() => {
    MixPanel.track(`Page View: OrderItems.tsx`, {
      page: "OrderItems.tsx",
    });
  }, []);

  let RenderedComponent = null;
  if (orderItemlist) {
    if (orderItemlist && orderItemlist.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> No Order Information available
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Product Category</th>
                <th>Product Rating</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Amount</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {orderItemlist &&
                orderItemlist.length > 0 &&
                orderItemlist.map((_data: any, _id: number) => {
                  const {
                    amount,
                    productCategory,
                    productRating,
                    quantity,
                    unitPrice,
                  } = _data;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      <td>{productCategory}</td>
                      <td>{productRating}</td>
                      <td>{quantity}</td>
                      <td>{formatMoney(unitPrice)}</td>
                      <td>{formatMoney(amount)}</td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  return (
    <div className="dataTables_wrapper">
      <div
        id="activeEcosystem"
        className="Disbursement table-view animated speed-1x fadeInRight"
      >
        <div
          style={{
            height: "30rem",
            overflowY: "scroll",
          }}
        >
          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default OrderItems;
