import TierListItem from "../TierItem";

import styles from "./tierCheckList.module.scss";

interface ITierCheckListProps {
  name: string;
  items: {
    id: number;
    name: string;
    status: boolean;
  }[];
}

const TierCheckList = ({ name, items }: ITierCheckListProps) => {
  return (
    <div className={styles.tier}>
      <h6 className={styles.tierTitle}>{name}</h6>

      {items.map((_item) => (
        <div className="mb-3" key={_item?.id}>
          <TierListItem
            serialNumber={_item?.id}
            name={_item?.name}
            status={_item?.status}
          />
        </div>
      ))}
    </div>
  );
};
export default TierCheckList;
