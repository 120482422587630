import React, { Dispatch, SetStateAction } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment/moment";
import { formatMoney } from "../../../helpers/formatter";
import styles from "./LoanOffer.module.scss";
import { Button } from "antd";
import { LoanOfferProps } from "../Loans/Disburse/interface";

enum LoanOfferStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
}

interface LoanOfferModalProps {
  setShowLoanOffer: Dispatch<SetStateAction<boolean>>;
  loan_offers: LoanOfferProps[];
  isLoadingLoanOffers: boolean;
}

const LoanOfferModal = ({
  setShowLoanOffer,
  loan_offers,
  isLoadingLoanOffers,
}: LoanOfferModalProps) => {
  function closeModalHandler() {
    setShowLoanOffer(false);
  }
  let RenderedComponent = null;
  if (isLoadingLoanOffers) {
    RenderedComponent = <p>Loading...</p>;
  } else if (loan_offers?.length === 0) {
    RenderedComponent = <p>No loan offer has been sent</p>;
  } else {
    RenderedComponent = (
      <>
        <MDBTable
          responsive
          minimal="true"
          striped
          bordered
          hover
          id="table-to-xls"
        >
          <MDBTableHead>
            <tr>
              <th>S/N</th>
              <th>Creation Date</th>
              <th>Requested Amount</th>
              <th>Offer Amount</th>
              <th>Requested Tenor</th>
              <th>Offer Tenor</th>
              <th>Status</th>
              <th>Acceptance/Rejection Date</th>
              <th>Comment</th>
              <th>Created By</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {loan_offers.map((loanOffer, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  {moment(loanOffer?.created_at).format("DD-MMM-YYYY, h:mm a")}
                </td>
                <td>{formatMoney(loanOffer.requested_amount)}</td>
                <td>{formatMoney(loanOffer.offer_amount)}</td>
                <td>{loanOffer?.requested_tenure}</td>
                <td>{loanOffer?.offer_tenure}</td>
                <td
                  className={
                    loanOffer.status === LoanOfferStatus.Pending
                      ? styles.pending
                      : loanOffer.status === LoanOfferStatus.Accepted
                        ? styles.accepted
                        : styles.rejected
                  }
                >
                  {loanOffer.status}
                </td>
                <td>
                  {loanOffer?.updated_at
                    ? moment(loanOffer?.updated_at).format(
                        "DD-MMM-YYYY, h:mm a",
                      )
                    : "Nil"}
                </td>
                <td>{loanOffer.comment}</td>
                <td>{loanOffer.created_by ? loanOffer.created_by : "Nil"}</td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </>
    );
  }

  return (
    <div
      className="modal fade show"
      tabIndex={-1}
      style={{ display: "block", zIndex: 1055 }} // Increase zIndex for the second modal
    >
      <div
        className="Modal__dialog  animated slideInDown md-lg-modal"
        role="document"
      >
        <div className="Modal__content">
          <div
            className="Modal__body"
            style={{
              height: "25rem",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <h4 className="d-flex justify-content-center mb-2">Loan Offer</h4>
            <div className="d-flex justify-content-between">
              {RenderedComponent}
            </div>
            <div
              style={{ position: "absolute", right: "10px", bottom: "10px" }}
            >
              <Button
                className={styles.view_offer_btn}
                onClick={closeModalHandler}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanOfferModal;
