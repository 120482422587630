import { Loader } from "../../../../../../UI/Loaders/Loaders";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { currencyFormatter } from "../../../../../../helpers/stringControllers";
import { resultProps } from "./type";

import "./loanSchedule.scss";
import { useEffect } from "react";
import { MixPanel } from "../../../../../../shared/MixPanel/MixPanel";

const LoanSchedule = ({
  result,
  setActiveTab,
  setLoanInfoFromSchedule,
}: resultProps) => {
  useEffect(() => {
    MixPanel.track(`Page View: LoanSchedule.tsx`, {
      page: "LoanSchedule.tsx",
    });
  }, []);

  const handleNavigateToCreditScore = () => {
    setActiveTab("credit-score");
    setLoanInfoFromSchedule({
      totalPrincipal: result?.data?.totalPrincipalDisbursed,
      totalRepayment: result?.data?.totalRepaymentExpected,
    });
  };

  let RenderedComponent = null;
  if (!result?.data && result?.status === "error") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Generate Loan Schedule
          </h4>
        </div>
      </div>
    );
  } else if (result?.status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Fetching loan schedule..." />
    );
  } else {
    if (result?.error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              borrower's loans, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (result?.data && result?.data.periods.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no loans for
              this borrower
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <MDBTable responsive minimal="true" bordered hover>
          <MDBTableHead>
            <tr>
              <th>S/N</th>
              <th>Days In Period</th>
              <th>Due Date</th>
              <th>Loan balance Outstanding</th>
              <th>Principal Disbursed</th>
              <th>Principal Due</th>
              <th>Interest Due</th>
              <th>Total Due for Period</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {result?.data &&
              result?.data.periods.map((loanSchedule, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{loanSchedule.daysInPeriod}</td>
                    <td>
                      <div style={{ width: "100px" }}>
                        {loanSchedule.dueDate.join("-")}
                      </div>
                    </td>
                    <td>
                      {currencyFormatter(
                        Number(loanSchedule.principalLoanBalanceOutstanding),
                        result?.data?.currency?.code,
                      )}
                    </td>
                    <td>
                      {currencyFormatter(
                        Number(loanSchedule.principalDisbursed),
                        result?.data?.currency?.code,
                      )}
                    </td>
                    <td>
                      {currencyFormatter(
                        Number(loanSchedule.principalDue),
                        result?.data?.currency?.code,
                      )}
                    </td>
                    <td>
                      {currencyFormatter(
                        Number(loanSchedule.interestDue),
                        result?.data?.currency?.code,
                      )}
                    </td>
                    <td>
                      {currencyFormatter(
                        Number(loanSchedule.totalDueForPeriod),
                        result?.data?.currency?.code,
                      )}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td className="total">Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td className="total">
                {currencyFormatter(
                  Number(result?.data?.totalPrincipalDisbursed),
                  result?.data?.currency?.code,
                )}
              </td>
              <td className="total">
                {currencyFormatter(
                  Number(result?.data?.totalPrincipalExpected),
                  result?.data?.currency?.code,
                )}
              </td>
              <td className="total">
                {currencyFormatter(
                  Number(result?.data?.totalInterestCharged),
                  result?.data?.currency?.code,
                )}
              </td>
              <td className="total">
                {currencyFormatter(
                  Number(result?.data?.totalRepaymentExpected),
                  result?.data?.currency?.code,
                )}
              </td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      );
    }
  }

  return (
    <div className="rendered-component">
      <div>{RenderedComponent}</div>
      <div className="mt-3 d-flex justify-content-end w-100 mb-5">
        <button
          className="btn advancly-btn btn-md mb-3 m-0"
          onClick={handleNavigateToCreditScore}
        >
          Get Credit Decision
        </button>
      </div>
    </div>
  );
};

export default LoanSchedule;
