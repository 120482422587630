import { IBusinessCustomerSearchParams } from "../../components/DigitalBank/Interfaces";
import * as actionTypes from "./actionTypes";

export const setAdvancedCustomerSearchParams = (
  values: IBusinessCustomerSearchParams,
) => {
  return {
    type: actionTypes.SET_ADVANCED_CUSTOMERS_SEARCH_PARAMS,
    payload: values,
  };
};
