import { useState } from "react";

import ToggleTab from "../../../../../NewComponents/ToggleTab/ToggleTab.component";
import Shareholders from "../../../../../components/AggregatorOnboardingReview/Tabs/Shareholders/Shareholders";

import useAnAggregatorOnboardingParameters from "../../../../../custom-hooks/useAnAggregatorOnboardingParameters";
import BoardOfDirectors from "../../../../../components/AggregatorOnboardingReview/Tabs/BoardOfDirectors/BoardOfDirectors";
import { Loader } from "../../../../../UI/Loaders/Loaders";

import styles from "./ownershipreview.module.scss";

const OwnershipReview = (props: any) => {
  const [activeTab, setActiveTab] = useState("board_of_directors");

  const {
    data: aggregatorOnboardingDetails,
    refetch,
    error,
    status,
  } = useAnAggregatorOnboardingParameters({
    customer_id: props?.dataRecord?.data,
    productType: "",
    documentOwnerType: "",
  });

  let RenderedComponent = null;
  if (!aggregatorOnboardingDetails && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Aggregator Information
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Aggregator Information..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Aggregator Information
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div>
          {(() => {
            switch (activeTab) {
              case "board_of_directors":
                return (
                  <BoardOfDirectors
                    boardOfDirectors={
                      aggregatorOnboardingDetails &&
                      aggregatorOnboardingDetails?.board_of_directors
                    }
                    refetch={refetch}
                    isFromCreditAnalysis={true}
                  />
                );
              case "shareholders":
                return (
                  <Shareholders
                    shareholders={
                      aggregatorOnboardingDetails &&
                      aggregatorOnboardingDetails?.shareholders
                    }
                    businessType={
                      aggregatorOnboardingDetails &&
                      aggregatorOnboardingDetails?.basicinformation
                        ?.business_type_id
                    }
                  />
                );

              default:
                return null;
            }
          })()}
        </div>
      );
    }
  }

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div
          className="Modal__body"
          style={{ height: "90vh", overflowY: "scroll" }}
        >
          <div className="alignToggleTabItems mb-3">
            <ToggleTab
              text="Board of Directors"
              id="board_of_directors"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ToggleTab
              text="ShareHolders"
              id="shareholders"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>

          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default OwnershipReview;
