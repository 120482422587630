import React, { useState } from "react";
import ApproveOrDeclineRolloverCommentsChecker from "./ApproveOrDeclineRolloverCommentsChecker";
import ApproveOrDeclineRolloverConfirmationChecker from "./ApproveOrDeclineRolloverConfirmationChecker";

const ApproveOrDeclineRolloverCheckerMain = ({
  properties,
  onCloseModal,
}: any) => {
  const {
    action,
    rolloverData: { loan_ref, rollover_maker_checker_reference },
  } = properties?.dataRecord;
  const [currentStep, setcurrentStep] = useState(1);
  const [currentComments, setCurrentComments] = useState("");
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="PartnerModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          {(() => {
            switch (currentStep) {
              case 1:
                return (
                  <ApproveOrDeclineRolloverCommentsChecker
                    action={action}
                    setcurrentStep={setcurrentStep}
                    setCurrentComments={setCurrentComments}
                    onCloseModal={onCloseModal}
                  />
                );

              case 2:
                return (
                  <ApproveOrDeclineRolloverConfirmationChecker
                    action={action}
                    currentComments={currentComments}
                    setcurrentStep={setcurrentStep}
                    onCloseModal={onCloseModal}
                    loanRef={loan_ref}
                    rolloverMakerCheckerReference={
                      rollover_maker_checker_reference
                    }
                  />
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default ApproveOrDeclineRolloverCheckerMain;
