import React, { useEffect } from "react";
import CustomInputField from "../../../CustomHTMLElements/CustomInputField";
import { appInsights } from "../../../../config/appInsights";
import { Loader } from "../../../../UI/Loaders/Loaders";
import { useIndividualCustomerDetails } from "../../../../custom-hooks/useIndividualCustomer";
import { useRouteMatch } from "react-router-dom";

const IndividualAdditionalInformationTab: React.FC = () => {
  const match = useRouteMatch();
  const { id } = match?.params as { id: string };

  useEffect(() => {
    appInsights.trackPageView({
      name: "IndividualAdditionalInformationTab",
      isLoggedIn: true,
    });
  }, []);

  const { data, isLoading, isError } = useIndividualCustomerDetails({
    customerId: Number(id),
  });

  if (isLoading)
    return (
      <Loader
        centered={true}
        text="Loading customer additional information..."
      />
    );

  if (isError)
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            customer additional information, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );

  const { photoUrl, dateOfBirth } = data?.data?.additionalInformation || {};

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <br />
          <div className="mt-4">
            <CustomInputField
              label="Date of Birth"
              type="text"
              name="dob"
              disabled
              defaultValue={dateOfBirth}
            />
          </div>

          <div>
            <label>Picture</label>
            <div className="card">
              {photoUrl ? (
                <div
                  className="card-body d-flex justify-content-between align-items-center"
                  style={{ padding: "0" }}
                >
                  <img
                    src={`https://advanclystorage.blob.core.windows.net/advancly/${photoUrl}`}
                    alt="Profile Image"
                    className="img-fluid"
                  />
                </div>
              ) : (
                <p className="text-center">Profile picture not available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualAdditionalInformationTab;
