import React, { useEffect } from "react";
import { MDBDataTable } from "mdbreact";

//Table Helper Controllers
import {
  filterDataByColumns,
  attachActionButtons,
  TableFetchSelect,
  onTableFetchSelectChange,
  tableFetchSelectOptions,
} from "../../../helpers/tableControllers";

// UI Components
import { InfoLoader, Loader } from "../../../UI/Loaders/Loaders";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const Funders = (props: any) => {
  let { funderTableData, loading } = props;

  useEffect(() => {
    MixPanel.track(`Page View: Funder.tsx`, {
      page: "Funder.tsx",
    });
  }, []);

  let RenderedComponent = null;

  if (funderTableData === undefined) {
    RenderedComponent = (
      <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
        <InfoLoader
          text="There was an issue fetching Available Partners."
          subText="Please reload the page after a few seconds"
        />
      </div>
    );
  } else if (loading) {
    RenderedComponent = (
      <Loader centered={true} text="Loading available Funders ..." />
    );
  } else {
    // Verify that a issues object exists
    if (
      funderTableData === undefined ||
      funderTableData === null ||
      !funderTableData.rows
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> Problem Fetching Funders, Please
              Reload the Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (funderTableData.rows.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are Currently No Funders on
              your Platform
            </h4>
          </div>
        </div>
      );
    } else {
      const tableName = "Funder";
      const actions = ["view"];
      const columnsToExclude = [] as any[];
      let newTableData = filterDataByColumns(
        funderTableData,
        "exclude",
        columnsToExclude,
      );
      let modifiedRows = attachActionButtons(
        newTableData.rows,
        tableName,
        actions,
        props.onModal,
      );
      newTableData = {
        columns: [...newTableData.columns],
        rows: [...modifiedRows],
      };

      RenderedComponent = (
        <div className="grid__padding animated fadeInRight">
          <MDBDataTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            data={newTableData}
          />
        </div>
      );
    }
  }

  return (
    <div className="Transactions datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        <div
          className="m-l-1 m-r-1 m-b-1"
          style={{ display: "flex", justifyContent: "center", height: "30px" }}
        >
          <TableFetchSelect
            style={{ marginRight: "20px" }}
            label="Showing results for: "
            id="table-fetch"
            className="table-fetch-select"
            onChange={(e: any) =>
              onTableFetchSelectChange(
                e,
                props.showModal,
                props.getAggregatorDrilldown,
                "getAggregatorDrilldown",
                `searchvalue=`,
                [props.aggregator_id],
              )
            }
            options={[...tableFetchSelectOptions]}
          />
        </div>

        {RenderedComponent}
      </div>
    </div>
  );
};

export default Funders;
