import { useEffect } from "react";
import { appInsights } from "../../../../../../config/appInsights";
import CustomInputField from "../../../../../CustomHTMLElements/CustomInputField";
import { IBusinessOwnersInformation } from "../../../../Interfaces";

interface IBusinessOwnersInformationProps {
  data: IBusinessOwnersInformation;
}

const BusinessOwnersInformation = ({
  data,
}: IBusinessOwnersInformationProps) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "BusinessOwnersInformation.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      <div className="row">
        <div className="col-md-5">
          <CustomInputField
            label="First Name"
            type="text"
            name="firstName"
            defaultValue={data?.firstName}
            disabled
          />
          <CustomInputField
            label="Gender"
            type="text"
            name="gender"
            defaultValue={data?.gender}
            disabled
          />
          <CustomInputField
            label="Date of Birth"
            type="date"
            name="dateOfBirth"
            defaultValue={
              data?.dateOfBirth ? data?.dateOfBirth.split("T")[0] : ""
            }
            disabled
          />
          <CustomInputField
            label="Residential Address"
            type="text"
            name="address"
            defaultValue={data?.address!}
            disabled
          />
          <CustomInputField
            label="Identification"
            type="text"
            name="identityNumber"
            defaultValue={data?.identityNumber!}
            disabled
          />
          <CustomInputField
            label="Country"
            type="text"
            name="countryCode"
            defaultValue={data?.countryCode!}
            disabled
          />
        </div>

        <div className="col-md-5">
          <CustomInputField
            label="Last Name"
            type="text"
            name="lastName"
            defaultValue={data?.lastName!}
            disabled
          />

          <CustomInputField
            label="Nationality"
            type="text"
            name="countryCode"
            defaultValue={data?.countryCode!}
            disabled
          />

          <CustomInputField
            label="BVN"
            type="text"
            name="bvn"
            defaultValue={data?.bvn!}
            disabled
          />

          <CustomInputField
            label="City"
            type="text"
            name="city"
            defaultValue={data?.city!}
            disabled
          />

          <CustomInputField
            label="State"
            type="text"
            name="state"
            defaultValue={data?.state!}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessOwnersInformation;
