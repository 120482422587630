import { useState, Fragment, useEffect } from "react";

// Redux
import { connect } from "react-redux";
import { showModal } from "../../../redux/actions/modal";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
// UI Components
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import { formatMoney } from "../../../helpers/formatter";
import useFailedPendingDisbursement from "../../../custom-hooks/useFailedPendingDisbursement";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const FailedDisbursement = ({ showModal }: any) => {
  const [state, setState] = useState({
    size: 10,
    pageNumber: 1,
  });
  const {
    data: failedDisbursementData,
    status,
    error,
  } = useFailedPendingDisbursement();

  useEffect(() => {
    MixPanel.track(`Page View: FailedDisbursement.tsx`, {
      page: "FailedDisbursement.tsx",
    });
  }, []);

  const dialogHandler = ({ action, data }: any) => {
    showModal({
      action,
      dataRecord: data,
      modalIdentifier: "faileddisbursement",
      subModal: "single",
    });
  };

  const resetTable = () => {
    setState({
      ...state,
      pageNumber: 1,
    });
  };

  const { size, pageNumber } = state;

  let RenderedComponent = null;

  //  If loans object is equal to null e.g. Initial component render before API fetch
  if (error) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> Unable to Fetch Loans
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader
        text="Loading available Loans for Disbursement ..."
        centered={true}
      />
    );
  } else if (failedDisbursementData?.data.length === 0) {
    RenderedComponent = (
      <div className="centerLoader">
        <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There are Currently No Loans to
            Disburse
          </h4>
        </div>
      </div>
    );
  } else if (
    failedDisbursementData &&
    failedDisbursementData?.data.length > 0
  ) {
    RenderedComponent = (
      <Fragment>
        <MDBTable responsive minimal="true" striped bordered hover>
          <MDBTableHead>
            <tr>
              <th>BORROWER ID</th>
              <th>AGGREGATOR NAME</th>
              <th>FIRST NAME</th>
              <th>LAST NAME</th>
              <th>LOANS AMOUNT</th>
              <th>LOAN INTEREST</th>
              <th>LOAN REF</th>
              <th>CREATED DATE</th>
              <th>PRODUCT NAME</th>
              <th>MANAGE</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {failedDisbursementData &&
              failedDisbursementData?.data
                .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                .map((data, _id: number) => {
                  const {
                    loan_id,
                    borrower_details,
                    aggregator_details,
                    loan_amount,
                    loan_interest_amount,
                    loan_ref,
                    product_name,
                    pub_date,
                  } = data;
                  const currency = aggregator_details?.currency;
                  return (
                    <tr key={loan_id}>
                      <td>{borrower_details.borrower_id}</td>
                      <td>{aggregator_details.biz_name}</td>
                      <td>{borrower_details.first_name}</td>
                      <td>{borrower_details.last_name}</td>
                      <td>{formatMoney(Number(loan_amount), currency)}</td>
                      <td>
                        {formatMoney(Number(loan_interest_amount), currency)}
                      </td>
                      <td>{loan_ref}</td>
                      <td>{pub_date.substring(0, 10)}</td>
                      <td>{product_name}</td>
                      <td>
                        <div className="d-flex ">
                          <span
                            className="faileddisbursement details btn-manage zoom-element btn-slim"
                            onClick={() => {
                              dialogHandler({
                                action: "details",
                                data,
                              });
                            }}
                          >
                            Details
                          </span>
                          <span
                            className="faileddisbursement retry btn-manage zoom-element btn-slim"
                            onClick={() => {
                              dialogHandler({
                                action: "retry",
                                data,
                              });
                            }}
                          >
                            Retry
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </MDBTableBody>
        </MDBTable>
        <Paginator
          size={size}
          page={pageNumber}
          count={failedDisbursementData?.data.length}
          changeCurrentPage={(pageNumber: number) => {
            setState({ ...state, pageNumber });
          }}
        />
      </Fragment>
    );
  }

  return (
    <div className="dataTables_wrapper">
      <div className="m-l-1 m-t-2 m-b-1">
        <label htmlFor="table-fetch" className="form-group">
          Number of results:{" "}
          <select
            onChange={(e) => {
              resetTable();
              setState({ ...state, size: Number(e.target.value) });
            }}
            id="table-fetch"
            className="table-fetch-select"
            value={size}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </label>
      </div>
      <div
        id="activeEcosystem"
        className="Disbursement table-view animated speed-1x fadeInRight"
      >
        <div className="m-l-1 m-r-1">
          {/* All Loans Ready for Disbursement Datatable || Loader || Error Message*/}
          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default connect(null, { showModal })(FailedDisbursement);
