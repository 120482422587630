import React, { useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import "../../Issues/IssuesModal.css";
//Table Helper Controllers
import { filterDataByColumns } from "./../../../../helpers/tableControllers";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

export const IdentityDetails = (props) => {
  const {
    borrower_identity: { name, address, gender, bvn },
  } = props.details;

  useEffect(() => {
    MixPanel.track(`Page View: IdentityDetails.tsx`, {
      page: "IdentityDetails.tsx",
    });
  }, []);

  return (
    <React.Fragment>
      <label className="label-txt">Name</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          className="user__form form-control"
          readOnly
          value={name || "Unavailable"}
        />
        <i className="fas fa-user-circle" />
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Gender</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={
                (gender === "F" && "Female") ||
                (gender === "M" && "Male") ||
                "Unavailable"
              }
            />
            <i className="fas fa-coins" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">BVN</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={bvn || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
      </div>
      <label className="label-txt">Address</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={address || "Unavailable"}
        />
        <i className="fas fa-hourglass-start" />
      </div>
      {/* <div className="row">
                <div className="col-xs-6">
                    <label className="label-txt">Date Created</label>
                    <div className="form-group inputWithIcon">
                        <input
                            type="text"
                            readOnly
                            className="user__form form-control"
                            value={new Date(pub_date)} />
                        <i className="far fa-calendar-alt" />
                    </div>
                </div>
                <div className="col-xs-6">
                    <label className="label-txt">Settlement Date</label>
                    <div className="form-group inputWithIcon">
                        <input
                            type="text"
                            className="user__form form-control"
                            readOnly
                            value={settlement_day} />
                        <i className="fas fa-calendar-alt" />
                    </div>
                </div>
            </div> */}
    </React.Fragment>
  );
};

export const BalanceDetails = (props) => {
  const {
    borrower_bank_balance: {
      available_balance,
      currency,
      account_name,
      ledger_balance,
      last_updated,
    },
  } = props.details;

  useEffect(() => {
    MixPanel.track(`Page View: BalanceDetails.tsx`, {
      page: "BalanceDetails.tsx",
    });
  }, []);

  return (
    <React.Fragment>
      <label className="label-txt">Available Balance</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          className="user__form form-control"
          readOnly
          value={currency + available_balance || "Unavailable"}
        />
        <i className="fas fa-user-circle" />
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Ledger Balance</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={currency + ledger_balance || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Account Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={account_name || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
      </div>
      <label className="label-txt">Last Updated</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={last_updated || "Unavailable"}
        />
        <i className="fas fa-hourglass-start" />
      </div>
    </React.Fragment>
  );
};

export const StatementDetails = (props) => {
  let rows = [];
  props.details.map((detail, index) => {
    let row_data = {
      id: index + 1,
      date: detail.trans_date,
      bank: detail.bank.name,
      customer_name: detail.customer.name,
      debit: detail.debit,
      credit: detail.credit,
    };
    rows.push(row_data);
    return rows;
  });

  useEffect(() => {
    MixPanel.track(`Page View: StatementDetails.tsx`, {
      page: "StatementDetails.tsx",
    });
  }, []);

  let statement = {
    columns: [
      { field: "id", label: "id", sort: "asc", width: 370 },
      { field: "date", label: "DATE", sort: "asc", width: 670 },
      { field: "bank", label: "BANK", sort: "asc", width: 670 },
      { field: "customer_name", label: "CUSTOMER", sort: "asc", width: 700 },
      { field: "debit", label: "DEBIT", sort: "asc", width: 600 },
      { field: "credit", label: "CREDIT", sort: "asc", width: 600 },
    ],
    rows: [],
  };
  // const tableName = 'Statement';
  // const actions = [];
  const columnsToExclude = [];
  let filteredTableData = filterDataByColumns(
    statement,
    "exclude",
    columnsToExclude,
  );
  filteredTableData = {
    columns: [...statement.columns],
    rows: [...rows],
  };

  return (
    <div style={{ maxHeight: "50vh", "overflow-x": "hidden" }}>
      {rows.length > 0 ? (
        <MDBDataTable
          responsive
          minimal="true"
          striped
          bordered
          hover
          // scrollY
          // maxHeight='35vh'
          data={filteredTableData}
        />
      ) : (
        <div className="centerLoader m-b-40" style={{ paddingTop: "0" }}>
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" />
              No statement available for this borrower.
            </h4>
          </div>
        </div>
      )}
    </div>
  );
};

export const IncomeDetails = (props) => {
  const {
    borrower_income: {
      last_updated,
      max_number_of_overlapping_income_streams,
      last_year_income,
      number_of_income_streams,
      projected_yearly_income,
      avg_monthly_income,
      income_type,
      confidence,
    },
  } = props.details;

  useEffect(() => {
    MixPanel.track(`Page View: IncomeDetails`, {
      page: "IncomeDetails",
    });
  }, []);

  return (
    <React.Fragment>
      <label className="label-txt">Last Year Income</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          className="user__form form-control"
          readOnly
          value={last_year_income || "Unavailable"}
        />
        <i className="fas fa-user-circle" />
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Projected Yearly Income</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={projected_yearly_income || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Number of Income Streams</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={number_of_income_streams || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
      </div>
      <label className="label-txt">Max Overlapping Income Streams</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={max_number_of_overlapping_income_streams || "Unavailable"}
        />
        <i className="fas fa-hourglass-start" />
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Average Monthly Income</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={avg_monthly_income || "Unavailable"}
            />
            <i className="far fa-calendar-alt" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Last Updated</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={last_updated || "Unavailable"}
            />
            <i className="fas fa-calendar-alt" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Income Type</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={income_type || "Unavailable"}
            />
            <i className="far fa-calendar-alt" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Confidence Level</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={confidence || "Unavailable"}
            />
            <i className="fas fa-calendar-alt" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
