import React, { useEffect } from "react";
import { Badge, Table } from "reactstrap";
import moment from "moment";
import { appInsights } from "../../../../config/appInsights";
import { Loader } from "../../../../UI/Loaders/Loaders";
import { useIndividualCustomerDetails } from "../../../../custom-hooks/useIndividualCustomer";
import { useRouteMatch } from "react-router-dom";

const IndividualAuditTrailTab: React.FC = () => {
  const match = useRouteMatch();
  const { id } = match?.params as { id: string };
  useEffect(() => {
    appInsights.trackPageView({
      name: "IndividualAuditTrailTab",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: customerData,
    isLoading,
    isError,
  } = useIndividualCustomerDetails({
    customerId: Number(id),
  });

  // Function to determine badge color based on account status
  const getBadgeColor = (status: string): string => {
    switch (status) {
      case "ACTIVE":
        return "success";
      case "INACTIVE":
        return "secondary";
      default:
        return "light";
    }
  };

  if (isLoading)
    return <Loader centered={true} text="Loading audit trail..." />;

  if (isError)
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            audit trail, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );
  if (customerData && customerData?.data?.auditTrail.length === 0) {
    return <p className="text-center">No Approval Trail Data at the moment</p>;
  }

  return (
    <div>
      <br />
      <Table responsive>
        <thead>
          <tr>
            <th>Action Date</th>
            <th>Status</th>
            <th>Comment</th>
            <th>Action</th>
            <th>Started by</th>
            <th>Completed by</th>
          </tr>
        </thead>
        <tbody>
          {customerData?.data.auditTrail.map((trail, index) => (
            <tr key={index}>
              <td>{moment(trail.actionDate).format("DD-MM-YYYY")}</td>
              <td>
                {" "}
                <Badge color={getBadgeColor(trail.status)} pill>
                  {trail.status}
                </Badge>
              </td>
              <td>{trail.comment}</td>
              <td>{trail.action}</td>
              <td>{trail.startedBy}</td>
              <td>{trail.completedBy}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default IndividualAuditTrailTab;
