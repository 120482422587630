import { ICustomerAccountDetails } from "../../../../../../../../custom-hooks/useCustomerAccountDetails";
import { formatMoney } from "../../../../../../../../helpers/formatter";

import styles from "./general.module.scss";

interface IGeneralProps {
  data: ICustomerAccountDetails;
}

const General = ({ data: accountDetails }: IGeneralProps) => {
  return (
    <div className={styles.general}>
      <div className={styles.details}>
        <h1>Savings Details</h1>
        <div className={styles.cardInformation}>
          <div className={styles.cardItem}>
            <p>Activated On</p>
            <p className={styles.value}>{accountDetails?.activatedOn}</p>
          </div>
          <div className={styles.cardItem}>
            <p>Officer Assigned</p>
            <p className={styles.value}>{accountDetails?.officerAssigned}</p>
          </div>{" "}
          <div className={styles.cardItem}>
            <p>Total Interest Earned</p>
            <p className={styles.value}>
              {accountDetails?.totalInterestEarned}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.details}>
        <h1>Account Summary</h1>
        <div className={styles.cardInformation}>
          <div className={styles.cardItem}>
            <p>Total Withdrawals</p>
            <p className={styles.value}>
              {formatMoney(accountDetails?.totalWithdrawals)}
            </p>
          </div>
          <div className={styles.cardItem}>
            <p>Interest Earned</p>
            <p className={styles.value}>
              {formatMoney(accountDetails?.interestEarned)}
            </p>
          </div>{" "}
          <div className={styles.cardItem}>
            <p>Nominal Interest Rate</p>
            <p className={styles.value}>
              {formatMoney(accountDetails?.nominalInterestRate)}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.details}>
        <h1>Performance History</h1>
        <div className={styles.cardInformation}>
          <div className={styles.cardItem}>
            <p>Total Deposits</p>
            <p className={styles.value}>
              {formatMoney(accountDetails?.totalDeposits)}
            </p>
          </div>
          <div className={styles.cardItem}>
            <p>Total Interest Earned</p>
            <p className={styles.value}>
              {formatMoney(accountDetails?.totalInterestEarned)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
