import { useEffect } from "react";
import { appInsights } from "../../../../../../config/appInsights";
import CustomInputField from "../../../../../CustomHTMLElements/CustomInputField";
import { IContactPerson } from "../../../../Interfaces";

import styles from "./contactPerson.module.scss";

interface IContactPersonProps {
  data: IContactPerson;
}

const ContactPerson = ({ data }: IContactPersonProps) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "ContactPerson.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      <div className="row">
        <div className="col-md-5">
          <CustomInputField
            label="First Name"
            type="text"
            name="firstName"
            defaultValue={data?.firstName}
            disabled
          />
          <CustomInputField
            label="Phone Number"
            type="text"
            name="phoneNumber"
            defaultValue={data?.phoneNumber!}
            disabled
          />
        </div>
        <div className="col-md-5">
          <CustomInputField
            label="Last Name"
            type="text"
            name="lastName"
            defaultValue={data?.lastName!}
            disabled
          />
          <CustomInputField
            label="Email"
            type="text"
            name="email"
            defaultValue={data?.email!}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default ContactPerson;
