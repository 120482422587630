// Audit
export const AUDIT_AJAX_START = "GET_AUDIT_START";
export const GET_AUDIT_SUCCESS = "GET_AUDIT_SUCCESS";
export const GET_AUDIT_FAILURE = "GET_AUDIT_FAILURE";

// Document Viewer
export const UPDATE_DOCUMENT_VIEWER_RECORD = "UPDATE_DOCUMENT_VIEWER_RECORD";
export const CLEAR_DOCUMENT_VIEWER_RECORD = "CLEAR_DOCUMENT_VIEWER_RECORD";

// Authentication
export const AUTH_START = "AUDIT_AJAX_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_NETWORK_FAILURE = "AUTH_NETWORK_FAILURE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const UPDATE_AUTHENTICATED = "UPDATE_AUTHENTICATED";
export const UPDATE_USER = "UPDATE_USER";

// Onboarding
export const ONBOARDING_START = "ONBOARDING_START";
export const ONBOARDING_SUCCESS = "ONBOARDING_SUCCESS";
export const ONBOARDING_ERROR = "ONBOARDING_ERROR";
export const ONBOARDING_NETWORK_FAILURE = "ONBOARDING_NETWORK_FAILURE";
export const UPDATE_ONBOARDED = "UPDATE_ONBOARDED";

// Partners
export const PARTNERS_AJAX__START = "PARTNERS_AJAX__START";
export const ALL_PARTNERS_SUCCESS = "ALL_PARTNERS_SUCCESS";
export const GET_PARTNERS_FAILURE = "GET_PARTNERS_FAILURE";

// Loans
export const GET_DISBURSEMENTS_START = "GET_DISBURSEMENTS_START";
export const GET_DISBURSEMENTS_SUCCESS = "GET_DISBURSEMENTS_SUCCESS";
export const GET_DISBURSEMENTS_FAILURE = "GET_DISBURSEMENTS_FAILURE";
export const SET_DISBURSEMENT_FILTER_START_DATE =
  "SET_DISBURSEMENT_FILTER_START_DATE";
export const SET_DISBURSEMENT_FILTER_END_DATE =
  "SET_DISBURSEMENT_FILTER_END_DATE";
export const SET_DISBURSEMENT_COUNT = "SET_DISBURSEMENT_COUNT";
export const SET_DISBURSEMENT_PAGE_NUMBER = "SET_DISBURSEMENT_PAGE_NUMBER";
export const SET_DISBURSEMENT_SIZE = "SET_DISBURSEMENT_SIZE";
export const SET_DISBURSEMENT_BORROWER_NAME = "SET_DISBURSEMENT_BORROWER_NAME";
export const SET_DISBURSEMENT_LOAN_ID = "SET_DISBURSEMENT_LOAN_ID";
export const SET_DISBURSEMENT_BVN = "SET_DISBURSEMENT_BVN";
export const SET_DISBURSEMENT_RC_NUMBER = "SET_DISBURSEMENT_RC_NUMBER";
export const SET_DISBURSEMENT_AGGREGATOR_ID = "SET_DISBURSEMENT_AGGREGATOR_ID";
export const SET_DISBURSEMENT_LOAN_REF = "SET_DISBURSEMENT_LOAN_REF";
export const GET_REPAYMENTS_START = "GET_REPAYMENTS_START";
export const GET_REPAYMENTS_SUCCESS = "GET_REPAYMENTS_SUCCESS";
export const GET_REPAYMENTS_FAILURE = "GET_REPAYMENTS_FAILURE";
export const GET_LOANS_START = "GET_LOANS_START";
export const GET_LOANS_SUCCESS = "GET_LOANS_SUCCESS";
export const GET_LOANS_FAILURE = "GET_LOANS_FAILURE";
export const REMOVE_DISBURSED_LOANS = "REMOVE_DISBURSED_LOANS";
export const REMOVE_DENIED_LOANS = "REMOVE_DENIED_LOANS";
export const SET_LOAN_FILTER_START_DATE = "SET_LOAN_FILTER_START_DATE";
export const SET_LOAN_FILTER_END_DATE = "SET_LOAN_FILTER_END_DATE";
export const SET_LOAN_COUNT = "SET_LOAN_COUNT";
export const SET_LOAN_PAGE_NUMBER = "SET_LOAN_PAGE_NUMBER";
export const SET_LOAN_SIZE = "SET_LOAN_SIZE";
export const SET_LOAN_IS_DISBURSED = "SET_LOAN_IS_DISBURSED";
export const SET_LOAN_IS_APPROVED = "SET_LOAN_IS_APPROVED";
export const SET_LOAN_STATUS = "SET_LOAN_STATUS";
export const SET_LOAN_START_DATE = "SET_LOAN_START_DATE";
export const SET_LOAN_END_DATE = "SET_LOAN_END_DATE";
export const SET_LOAN_ON_MIFOS = "SET_LOAN_ON_MIFOS";
export const SET_LOAN_BORROWER_NAME = "SET_LOAN_BORROWER_NAME";
export const SET_LOAN_ID = "SET_LOAN_ID";
export const SET_LOAN_BVN = "SET_LOAN_BVN";
export const SET_LOAN_RC_NUMBER = "SET_LOAN_RC_NUMBER";
export const SET_LOAN_AGGREGATOR_ID = "SET_LOAN_AGGREGATOR_ID";
export const SET_LOAN_REFERENCE = "SET_LOAN_REFERENCE";
export const REFETCH_LOANS = "REFETCH_LOAN";

// Frequently Asked Questions
export const GET_FAQ_START = "GET_FAQ_START";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

// Funders
export const GET_FUNDERS_START = "GET_FUNDERS_START";
export const GET_FUNDERS_SUCCESS = "GET_FUNDERS_SUCCESS";
export const GET_FUNDERS_FAILURE = "GET_FUNDERS_FAILURE";
export const FUNDER_INVESTMENT_POOL_START = "FUNDER_INVESTMENT_POOL_START";
export const FUNDER_INVESTMENT_POOL_SUCCESS = "FUNDER_INVESTMENT_POOL_SUCCESS";
export const FUNDER_INVESTMENT_POOL_ERROR = "FUNDER_INVESTMENT_POOL_ERROR";
export const FUNDER_PROFILE_START = "FUNDER_PROFILE_START";
export const FUNDER_PROFILE_SUCCESS = "FUNDER_PROFILE_SUCCESS";
export const FUNDER_PROFILE_ERROR = "FUNDER_PROFILE_ERROR";
export const FUNDER_NOTIFICATIONS_START = "FUNDER_NOTIFICATIONS_START";
export const FUNDER_NOTIFICATIONS_SUCCESS = "FUNDER_NOTIFICATIONS_SUCCESS";
export const FUNDER_NOTIFICATIONS_ERROR = "FUNDER_NOTIFICATIONS_ERROR";
export const FUNDER_TRANSACTIONS_START = "FUNDER_TRANSACTIONS_START";
export const FUNDER_TRANSACTIONS_SUCCESS = "FUNDER_TRANSACTIONS_SUCCESS";
export const FUNDER_TRANSACTIONS_ERROR = "FUNDER_TRANSACTIONS_ERROR";
export const FUNDER_INVESTMENTS_START = "FUNDER_INVESTMENTS_START";
export const FUNDER_INVESTMENTS_SUCCESS = "FUNDER_INVESTMENTS_SUCCESS";
export const FUNDER_INVESTMENTS_ERROR = "FUNDER_INVESTMENTS_ERROR";
export const FUNDER_PRODUCTS_START = "FUNDER_PRODUCTS_START";
export const FUNDER_PRODUCTS_SUCCESS = "FUNDER_PRODUCTS_SUCCESS";
export const FUNDER_PRODUCTS_ERROR = "FUNDER_PRODUCTS_ERROR";

// Aggregators
export const GET_AGGREGATORS_START = "GET_AGGREGATORS_START";
export const GET_AGGREGATORS_SUCCESS = "GET_AGGREGATORS_SUCCESS";
export const GET_AGGREGATORS_FAILURE = "GET_AGGREGATORS_FAILURE";
export const GET_DRILLDOWN_START = "GET_DRILLDOWN_START";
export const GET_DRILLDOWN_SUCCESS = "GET_DRILLDOWN_SUCCESS";
export const GET_DRILLDOWN_FAILURE = "GET_DRILLDOWN_FAILURE";

// Investments
export const GET_INVESTMENTS_START = "GET_INVESTMENTS_START";
export const GET_INVESTMENTS_SUCCESS = "GET_INVESTMENTS_SUCCESS";
export const MAKE_INVESTMENT_SUCCESS = "MAKE_INVESTMENT_SUCCESS";
export const GET_INVESTMENTS_FAILURE = "GET_INVESTMENTS_FAILURE";
export const SUBSCRIPTION_START = "SUBSCRIPTION_START";
export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS";
export const SUBSCRIPTION_ERROR = "SUBSCRIPTION_ERROR";
export const SUBSCRIPTION_CLOSE = "SUBSCRIPTION_CLOSE";

// Issues
export const ALL_ISSUES_START = "ALL_ISSUES_START";
export const ALL_ISSUES_SUCCESS = "ALL_ISSUES_SUCCESS";
export const ALL_ISSUES_NETWORK_FAILURE = "ALL_ISSUES_FAILURE";
export const ALL_ISSUES_ERROR = "ALL_ISSUES_ERROR";
export const PENDING_ISSUES_START = "PENDING_ISSUES_START";
export const PENDING_ISSUES_SUCCESS = "PENDING_ISSUES_SUCCESS";
export const PENDING_ISSUES_NETWORK_FAILURE = "PENDING_ISSUES_FAILURE";
export const PENDING_ISSUES_ERROR = "PENDING_ISSUES_ERROR";
export const REPORT_ISSUE_START = "REPORT_ISSUE_START";
export const REPORT_ISSUE_SUCCESS = "REPORT_ISSUE_SUCCESS";
export const REPORT_ISSUE_NETWORK_FAILURE = "REPORT_ISSUE_FAILURE";
export const REPORT_ISSUE_ERROR = "REPORT_ISSUE_ERROR";

// Modals
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

// Navigation
export const SHOW_NAVIGATION = "SHOW_NAVIGATION";
export const HIDE_NAVIGATION = "HIDE_NAVIGATION";

// Notifications
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const GET_NOTIFICATIONS_START = "GET_NOTIFICATIONS_START";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR";

// Settings
export const PROFILE_DETAILS = "PROFILE_DETAILS";
export const AGGREGATORS_LOAN_SETTINGS = "AGGREGATORS_LOAN_SETTINGS";
export const AGGREGATORS_INVESTMENT_SETTINGS =
  "AGGREGATORS_INVESTMENT_SETTINGS";
export const AGGREGATORS_REPAYMENT_SETTINGS = "AGGREGATORS_REPAYMENT_SETTINGS";

export const SETTINGS_START = "SETTINGS_START";
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const SETTINGS_FAILURE = "SETTINGS_FAILURE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_SYSTEM_SUCCESS = "UPDATE_SYSTEM_SUCCESS";

// Toast
export const SET_TOAST = "SET_TOAST";
export const CLEAR_TOAST = "CLEAR_TOAST";

// Transactions
export const TRANSACTION_AJAX_START = "TRANSACTION_AJAX_START";
export const ALL_TRANSACTIONS_SUCCESS = "ALL_TRANSACTIONS_SUCCESS";
export const TRANSACTION_BY_ID_SUCCESS = "TRANSACTION_BY_ID_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";

// Wallet
export const FUND_WALLET_START = "FUND_WALLET_START";
export const FUND_WALLET_SUCCESS = "FUND_WALLET_SUCCESS";
export const FUND_WALLET_FAILURE = "FUND_WALLET_FAILURE";
export const FUND_WALLET_COMPLETE = "FUND_WALLET_COMPLETE";
export const GET_WALLET_START = "GET_WALLET_START";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";
export const GET_WALLET_FAILURE = "GET_WALLET_FAILURE";

// Statistics
export const GET_ADMIN_STATISTICS_SUCCESS = "GET_ADMIN_STATISTICS";
export const GET_AGGREGATOR_STATISTICS_SUCCESS = "GET_AGGREGATOR_STATISTICS";

//Digital Bank
export const SET_ADVANCED_CUSTOMERS_SEARCH_PARAMS =
  "SET_ADVANCED_CUSTOMERS_SEARCH_PARAMS";
