import { useEffect, useState } from "react";

import Tabs from "../../../../components/Tabs/Tabs";
import { appInsights } from "../../../../config/appInsights";
import GeneralInformation from "./Tabs/GeneralInformation";
import WalletDetails from "./Tabs/WalletDetails";
import { TFundingWallet } from "../../../../custom-hooks/useFundingWallets";
import RequestFund from "./Tabs/RequestFund";

import styles from "./fundingWalletDetails.module.scss";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

enum TabId {
  GENRAL_INFORMATION = "general_information",
  WALLET_DETAILS = "wallet_details",
  REQUEST_FUND = "request_fund",
}

const FundingWalletDetails = ({
  properties: { dataRecord },
  onCloseModal,
}: any) => {
  const [scene, setScene] = useState<TabId>(TabId.GENRAL_INFORMATION);
  const [walletInfo, setWalletInfo] = useState<TFundingWallet>();

  const [tabs] = useState([
    {
      name: "General Information",
      id: TabId.GENRAL_INFORMATION,
      identifier: "fundingWalletDetailsTab",
    },
    {
      name: "Wallet Details",
      id: TabId.WALLET_DETAILS,
      identifier: "fundingWalletDetailsTab",
    },
    {
      name: "Request Fund",
      id: TabId.REQUEST_FUND,
      identifier: "fundingWalletDetailsTab",
    },
  ]);

  const toggleTabContent = (content: TabId) => {
    return setScene(content);
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "FundingWalletDetails",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: FundingWalletDetails.tsx`, {
      page: "FundingWalletDetails.tsx",
    });
  }, []);

  useEffect(() => {
    if (dataRecord) {
      setWalletInfo(dataRecord);
    }
  }, [dataRecord]);

  return (
    <div
      className={`Modal__dialog md-modal animated slideInDown ${styles.FundingWalletDetails}`}
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1">
            <div className="row m-b-1">
              <Tabs onContentToggle={toggleTabContent} tabs={tabs} />
            </div>
          </h3>

          {(() => {
            switch (scene) {
              case TabId.GENRAL_INFORMATION:
                return <GeneralInformation walletInfo={walletInfo!} />;
              case TabId.WALLET_DETAILS:
                return <WalletDetails walletInfo={walletInfo!} />;
              case TabId.REQUEST_FUND:
                return <RequestFund walletInfo={walletInfo!} />;

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default FundingWalletDetails;
