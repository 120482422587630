import { useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../../redux/actions/modal";

import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// UI Components
import { Loader } from "../../../../../UI/Loaders/Loaders";
import { Fragment } from "react";
import Paginator from "../../../../Pagination/Pagination.component";
import { attachClassNameToTag } from "../../../../../helpers/attachClassNameToTag";
import { formatMoney } from "../../../../../helpers/formatter";
import { setToast } from "../../../../../redux/actions/toast";

import Styles from "./Approver.module.scss";

type TState = {
  pendingDisbursementData: any[];
  checkedPendingDisbursementData: any[];
  Loading: boolean;
  error: string;
  status: string;
  totalAmount: number;
  count: number;
  size: number;
  pageNumber: number;
  searchValue: string;
};

const Approver = (props: any) => {
  const [state, setState] = useState<TState>({
    pendingDisbursementData: props.pendingDisbursementData,
    checkedPendingDisbursementData: [],
    Loading: false,
    error: "",
    status: "off",
    totalAmount: 0,
    count: 0,
    size: 50,
    pageNumber: 1,
    searchValue: "",
  });

  const loggedInUser = useSelector(
    (state: any) => state?.authReducer?.loggedInUser,
  );

  const dispatch = useDispatch();

  const { Loading, error } = props;
  const { pageNumber, size, pendingDisbursementData } = state;

  const modalHandler = (modalData: any) => {
    dispatch(showModal(modalData));
  };

  const dialogHandler = ({ action, data, approver }: any) => {
    dispatch(
      showModal({
        action,
        approver,
        dataRecord: data,
        modalIdentifier: "pendingloan",
        subModal: "single",
        result: props.getPendingDisbursement,
      }),
    );
  };

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        pendingDisbursementData: props.pendingDisbursementData,
      };
    });
  }, [props.pendingDisbursementData]);

  useEffect(() => {
    const allLoans = document.querySelectorAll(
      ".loans_for_disbursement",
    ) as any;
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = false;
    }
    setState((prev) => {
      return {
        ...prev,
        checkedPendingDisbursementData: [],
        count: 0,
        totalAmount: 0,
        status: "off",
      };
    });
  }, [state.pendingDisbursementData]);

  const handleChangeSearchInput = (e: any) => {
    const value = e.target.value;

    setState((prev) => ({ ...prev, searchValue: value }));
  };

  const handleSearch = () => {
    setState((prev) => {
      return {
        ...prev,
        pageNumber: 1,
      };
    });
    const currentData = [...state.pendingDisbursementData];

    const searchResult = currentData.filter(
      (item) =>
        item?.aggregator_name
          .toLowerCase()
          .includes(state.searchValue.toLowerCase()) ||
        item?.borrower_name
          .toLowerCase()
          .includes(state.searchValue.toLowerCase()) ||
        item?.loan_ref
          .toLowerCase()
          .includes(state.searchValue.toLowerCase()) ||
        item?.pub_date.toLowerCase().includes(state.searchValue.toLowerCase()),
    );

    return setState((prev) => {
      return {
        ...prev,
        pendingDisbursementData: searchResult,
      };
    });
  };

  const handleClear = () => {
    setState((prev) => {
      return {
        ...prev,
        pendingDisbursementData: props.pendingDisbursementData,
        pageNumber: 1,
      };
    });
  };

  // Single Checkbox
  const checkboxHandler = ({
    dataRecord: { loan_amount },
    eventTarget,
  }: any) => {
    const loanRef = eventTarget.dataset["ref"];

    if (eventTarget.checked === false) {
      const checkedPendingData = state.checkedPendingDisbursementData;
      const newCheckedPendingDisbursementData = checkedPendingData.filter(
        (loan) => {
          return loan !== loanRef;
        },
      );
      return setState((prev) => {
        return {
          ...prev,
          checkedPendingDisbursementData: [
            ...newCheckedPendingDisbursementData,
          ],
          count: state.count - 1,
          totalAmount: state.totalAmount - parseFloat(loan_amount),
        };
      });
    }
    setState((prev: any) => {
      return {
        ...prev,
        checkedPendingDisbursementData: [
          ...state.checkedPendingDisbursementData,
          loanRef,
        ],
        count: state.count + 1,
        totalAmount: state.totalAmount + parseFloat(loan_amount),
      };
    });
  };

  // Disburse Selected Loans
  const disbursePendingSelectedLoans = (e: any) => {
    e.preventDefault();
    const checkedPendingDisbursementData = state.checkedPendingDisbursementData;
    if (checkedPendingDisbursementData.length === 0) {
      return dispatch(setToast("warning", "You have not selected any loans"));
    }

    modalHandler({
      modalIdentifier: "pendingloan",
      dataId: e.target.dataset["id"],
      dataRef: e.target.dataset["ref"],
      action: "approve",
      subModal: "multiple",
      loans: state.checkedPendingDisbursementData,
      totalAmount: state.totalAmount,
      result: props.getPendingDisbursement,
    });
  };

  // Multiple Checkbox
  const checkAllPendingDisbursementData = (e: any) => {
    e.preventDefault();
    if (e.target.dataset["state"] === "checked") {
      const allLoans = document.querySelectorAll(
        ".loans_for_disbursement",
      ) as any;
      for (let i = 0; i < allLoans.length; i++) {
        allLoans[i].checked = false;
      }

      e.target.dataset["state"] = "unchecked";

      setState((prev) => {
        return {
          ...prev,
          status: "off",
          count: 0,
          totalAmount: 0,
          checkedPendingDisbursementData: [],
        };
      });

      return;
    }

    let totalAmount = 0;
    let loans = [] as any[];
    const allLoans = document.querySelectorAll(
      ".loans_for_disbursement",
    ) as any;
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = true;
      const loanRef = allLoans[i].dataset["ref"];
      loans.push(loanRef);
      totalAmount += parseFloat(
        JSON.parse(allLoans[i].dataset["record"]).loan_amount,
      );
    }

    e.target.dataset["state"] = "checked";

    setState((prev: any) => {
      return {
        ...prev,
        status: "on",
        count: loans.length,
        totalAmount,
        checkedPendingDisbursementData: [
          ...state.checkedPendingDisbursementData,
          ...loans,
        ],
      };
    });
  };
  const resetTable = () => {
    setState((prev) => {
      return {
        ...prev,
        pageNumber: 1,
      };
    });
  };

  let RenderedComponent = null;
  //  If loans object is equal to null e.g. Initial component render before API fetch
  if (error) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> An error occurred. Unable to Fetch
              Loans
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (
    error === null &&
    pendingDisbursementData === undefined &&
    !Loading
  ) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> Unable to Fetch Loans
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (Loading) {
    RenderedComponent = (
      <Loader
        text="Loading available Loans for Disbursement ..."
        centered={true}
      />
    );
  } else if (pendingDisbursementData?.length === 0) {
    RenderedComponent = (
      <div className="centerLoader">
        <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There are Currently No Loans to
            Disburse
          </h4>
        </div>
      </div>
    );
  } else if (pendingDisbursementData?.length > 0) {
    RenderedComponent = (
      <Fragment>
        <MDBTable responsive minimal="true" bordered striped hover>
          <MDBTableHead>
            <tr>
              <th></th>
              <th>Aggregator Name</th>
              <th>Borrower Name</th>
              <th>Loan Status</th>
              <th>Loan Amount</th>
              <th>Loan Interest</th>
              <th>Requested Amount</th>
              <th>Loan Ref</th>
              <th>Created Date</th>
              <th>Product Name</th>
              <th>Product Catergory Name</th>
              <th>Trust SCORE</th>
              <th>CONSOLIDATED SCORE DECISION</th>
              <th>ROLL OVER STATUS</th>
              <th>REQUIRE DEAL SLIP</th>
              <th>Manage</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {pendingDisbursementData &&
              pendingDisbursementData
                .slice(
                  (pageNumber - 1) * Number(size),
                  Number(size) * (pageNumber - 1) + Number(size),
                )
                .map((data: any, _id: number) => {
                  const {
                    loan_id,
                    currency,
                    aggregator_name,
                    borrower_name,
                    loan_amount,
                    loan_interest,
                    loan_ref,
                    pub_date,
                    product_name,
                    product_category_name,
                    trust_score,
                    disbursement_decision,
                    require_deal_slip,
                    loan_amount_requested,
                    is_rollover,
                  } = data;
                  return (
                    <tr key={_id}>
                      <td>
                        <input
                          type="checkbox"
                          data-record={JSON.stringify(data)}
                          data-id={loan_id}
                          data-ref={loan_ref}
                          checked={state.checkedPendingDisbursementData.includes(
                            loan_ref,
                          )}
                          onClick={(e) =>
                            checkboxHandler({
                              dataRecord: data,
                              eventTarget: e.target,
                            })
                          }
                          className="pendingloan loans_for_disbursement"
                        />
                      </td>
                      <td>{aggregator_name}</td>
                      <td>{borrower_name}</td>
                      <td>
                        <span className={attachClassNameToTag("Pending")}>
                          {attachClassNameToTag("Pending")}
                        </span>
                      </td>
                      <td>{formatMoney(Number(loan_amount), currency)}</td>
                      <td>{formatMoney(Number(loan_interest), currency)}</td>
                      <td>
                        {formatMoney(Number(loan_amount_requested), currency)}
                      </td>
                      <td>{loan_ref}</td>
                      <td>{pub_date && pub_date.substring(0, 10)}</td>
                      <td>{product_name}</td>
                      <td>{product_category_name}</td>
                      <td>{trust_score}</td>
                      <td>{disbursement_decision}</td>
                      <td>{is_rollover ? "True" : "False"}</td>
                      <td>{require_deal_slip ? "True" : "False"}</td>
                      <td>
                        <span className="d-flex justify-content-center align-items-center">
                          <span
                            className="pendingloan details btn-manage zoom-element btn-slim"
                            onClick={() => {
                              dialogHandler({
                                action: "details",
                                data,
                              });
                            }}
                          >
                            Details
                          </span>
                          <span
                            className="pendingloan approve btn-manage zoom-element btn-slim"
                            onClick={() =>
                              dialogHandler({
                                action: "approve",
                                approver: loggedInUser.email,
                                data,
                              })
                            }
                          >
                            Approve
                          </span>
                          <span
                            className="pendingloan deny btn-manage zoom-element btn-slim"
                            onClick={() =>
                              dialogHandler({
                                action: "deny",
                                data,
                              })
                            }
                          >
                            Deny
                          </span>
                        </span>
                      </td>
                    </tr>
                  );
                })}
          </MDBTableBody>
        </MDBTable>
      </Fragment>
    );
  }

  return (
    <div className="dataTables_wrapper">
      <div
        id="activeEcosystem"
        className="Disbursement table-view animated speed-1x fadeInRight"
      >
        <div className="m-l-1 m-r-1">
          <div className="block-header">
            <div className="flex-row">
              <h3 className="m-l-1 m-b-1 flex-row">Pending Approval </h3>

              <div className="flex-row m-l-1 m-b-1">
                <div className="m-r-1">
                  <div
                    data-state="unchecked"
                    className="rounded-border flex-row"
                    onClick={checkAllPendingDisbursementData}
                  >
                    <i
                      className={`adv-icon fas fa-toggle-${state.status} fa-2x`}
                    />
                    &nbsp; <span>Select all</span>
                  </div>
                </div>

                <div className="m-r-1 rounded-border">
                  Selected: {state.count}
                </div>

                <div className="rounded-border m-r-1">
                  Total Amount: {formatMoney(state.totalAmount)}
                </div>
              </div>
              <div className="flex-row">
                <div className=" m-b-1">
                  <button
                    id="create"
                    data-id="create-role"
                    data-action="create"
                    className="create zoom-element btn login-btn"
                    onClick={disbursePendingSelectedLoans}
                  >
                    <i className="far fa-credit-card" /> &nbsp; Proceed With
                    Bulk
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
            <label htmlFor="table-fetch" className="form-group">
              Number of results:{" "}
              <select
                onChange={(e) => {
                  resetTable();
                  setState((prev: any) => {
                    return { ...prev, size: e.target.value };
                  });
                }}
                id="table-fetch"
                className="table-fetch-select"
                value={size}
              >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={300}>300</option>
                <option value={400}>400</option>
                <option value={500}>500</option>
                <option value={600}>600</option>
                <option value={700}>700</option>
                <option value={800}>800</option>
                <option value={900}>900</option>
                <option value={1000}>1000</option>
              </select>
            </label>
            <div className={`form-group ${Styles.FormGroup}`}>
              <input
                type="text"
                className={Styles.SearchInput}
                placeholder="Search"
                name="search"
                onChange={handleChangeSearchInput}
              />
              <i className={`fas fa-search ${Styles.Icon}`}></i>
            </div>
            <div className={Styles.FormButtonContainer}>
              <button
                type="submit"
                className={`btn advancly-btn btn-md ${Styles.SearchActionButton}`}
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
            <div className={Styles.FormButtonContainer}>
              <button
                type="submit"
                className={`btn advancly-red-btn btn-md ml-3 ${Styles.SearchActionButton}`}
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </div>
          {/* All Loans Ready for Disbursement Datatable || Loader || Error Message*/}
          {RenderedComponent}
          <Paginator
            size={size}
            page={pageNumber}
            count={pendingDisbursementData?.length}
            changeCurrentPage={(pageNumber: number) =>
              setState((prev) => {
                return { ...prev, pageNumber };
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Approver;
