import React, { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import ToggleTab from "../../../../NewComponents/ToggleTab/ToggleTab.component";
import IndividualAccountsTab from "./IndividualAccountsTab";
import { useIndividualCustomerDetails } from "../../../../custom-hooks/useIndividualCustomer";
import { currencyFormatter } from "../../../../helpers/stringControllers";
import { appInsights } from "../../../../config/appInsights";
import IndividualKYCDocumentsTabs from "./IndividualKYCDocumentsTabs";
import IndividualNextOfKinTab from "./IndividualNextOfKinTab";
import IndividualAdditionalInformationTab from "./IndividualAdditionalInformationTab";
import IndividualAuditTrailTab from "./individualAuditTrailTab";
import moment from "moment";
import { Loader } from "../../../../UI/Loaders/Loaders";

interface CustomerData {
  accountName: string;
  email: string;
  totalBalance: string;
  dateCreated: string;
  tier: string;
  transactionLimit: string;
  currencyCode: string;
}

const IndividualCustomerDetails = () => {
  const match = useRouteMatch();
  const { id } = match?.params as { id: string };
  let location: any = useLocation();
  let { customerName, email, dateCreated } = location?.state;
  const [activeTab, setActiveTab] = useState("account-details");

  const {
    data: customerData,
    isLoading,
    isError,
  } = useIndividualCustomerDetails({
    customerId: Number(id),
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "IndividualCustomerDetails",
      isLoggedIn: true,
    });
  }, []);

  if (isLoading)
    return <Loader centered={true} text="Loading customer accounts..." />;

  if (isError)
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            customer accounts, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Go back
        </h3>
      </div>
      <br />
      <div className="row m-x-sm m-y-2 grid__padding">
        <div className="table-responsive">
          <table className="table">
            <thead className="bg-lighter-grey">
              <tr>
                <th>Customer's Name</th>
                <th>Total Account Balance</th>
                <th>Customer Email</th>
                <th>Date Created</th>
                <th>Tier</th>
                <th>Transaction Limit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold">{customerName}</td>
                <td className="font-weight-bold">
                  {currencyFormatter(
                    Number(customerData?.data?.totalAccountBalance)
                  )}
                </td>
                <td>{email}</td>
                <td>{dateCreated}</td>
                <td>{customerData?.data?.tier}</td>
                <td>
                  {currencyFormatter(
                    Number(customerData?.data?.transactionLimit)
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="Account Details"
          id="account-details"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="KYC Documents"
          id="kyc-documents"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Next of Kin Information"
          id="next-of-kin"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Additional Information"
          id="additional-information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Approval Trail"
          id="audit-trail"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      <div>
        {activeTab === "account-details" && <IndividualAccountsTab />}
        {activeTab === "kyc-documents" && <IndividualKYCDocumentsTabs />}
        {activeTab === "next-of-kin" && <IndividualNextOfKinTab />}
        {activeTab === "additional-information" && (
          <IndividualAdditionalInformationTab />
        )}
        {activeTab === "audit-trail" && <IndividualAuditTrailTab />}
      </div>
    </div>
  );
};

export default IndividualCustomerDetails;
