import React, { useEffect } from "react";
import CustomInputField from "../../../CustomHTMLElements/CustomInputField";
import { appInsights } from "../../../../config/appInsights";
import { Loader } from "../../../../UI/Loaders/Loaders";
import { useIndividualCustomerDetails } from "../../../../custom-hooks/useIndividualCustomer";
import { useRouteMatch } from "react-router-dom";
import useCountryCodes from "../../../../custom-hooks/useCountryCodes";
import { getCountry } from "../../../AggregatorOnboardingReview/Tabs/helper";

const IndividualNextOfKinTab: React.FC = () => {
  const match = useRouteMatch();
  const { id } = match?.params as { id: string };

  useEffect(() => {
    appInsights.trackPageView({
      name: "IndividualNextOfKinTab",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: customerData,
    isLoading,
    isError,
  } = useIndividualCustomerDetails({
    customerId: Number(id),
  });

  const { data } = useCountryCodes();

  if (isLoading)
    return <Loader centered={true} text="Loading customer accounts..." />;

  if (isError)
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            customer accounts, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    relationshipType,
    street,
    city,
    state,
    countryCode,
  } = customerData?.data?.nextOfKin ?? {};

  return (
    <div className="container">
      <div className="row">
        {/* First Column */}
        <div className="col-md-6">
          <div className="mt-4">
            <CustomInputField
              label="First Name"
              type="text"
              name="firstName"
              disabled
              defaultValue={firstName}
            />
          </div>
          <div className="mt-4">
            <CustomInputField
              label="Last Name"
              type="text"
              name="lastName"
              disabled
              defaultValue={lastName}
            />
          </div>
          <div className="mt-4">
            <CustomInputField
              label="Phone Number"
              type="text"
              name="phoneNumber"
              disabled
              defaultValue={phoneNumber}
            />
          </div>
          <div className="mt-4">
            <CustomInputField
              label="Next of Kin Email Address"
              type="email"
              name="nextOfKinEmail"
              disabled
              defaultValue={email}
            />
          </div>
          <div className="mt-4">
            <CustomInputField
              label="Relationship"
              type="text"
              name="relationship"
              disabled
              defaultValue={relationshipType?.name}
            />
          </div>
        </div>

        {/* Second Column */}
        <div className="col-md-6">
          <div className="mt-4">
            <CustomInputField
              label="Street Address"
              type="text"
              name="street"
              disabled
              defaultValue={street}
            />
          </div>
          <div className="mt-4">
            <CustomInputField
              label="City"
              type="text"
              name="city"
              disabled
              defaultValue={city}
            />
          </div>
          <div className="mt-4">
            <CustomInputField
              label="State"
              type="text"
              name="state"
              disabled
              defaultValue={state}
            />
          </div>
          <div className="mt-4">
            <CustomInputField
              label="Country"
              type="text"
              name="country"
              disabled
              defaultValue={getCountry(countryCode || "", data?.data || [])}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualNextOfKinTab;
