import React, { useEffect, useState } from "react";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { postData } from "../../../../newApis/apiMethods";
import CreditBureauCheckTableData from "./CreditBureauCheckTableData";
import { appInsights } from "../../../../config/appInsights";
import { errorHandler } from "../../../../helpers/errorHandler";
import { Loader } from "../../../../UI/Loaders/Loaders";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

export interface IcreditBureauProps {
  first_name: string;
  last_name: string;
  bvn: string;
  borrower_type_value: string;
  rc_number: string;
  loan_id?: number;
  customer_id?: number;
  borrower_id?: number;
}
const CreditBureauCRC = ({
  first_name,
  last_name,
  loan_id,
  bvn,
  borrower_type_value,
  rc_number,
}: IcreditBureauProps) => {
  const [creditBureauCheckLoading, setCreditBureauCheckLoading] =
    useState(false);
  const [creditBureauCheckError, setCreditBureauCheckError] = useState<
    string | null
  >(null);
  const [creditBureauCheckData, setCreditBureauCheckData] = useState<any>(null);

  useEffect(() => {
    MixPanel.track(`Page View: CreditBureau.tsx`, {
      page: "CreditBureau.tsx",
    });
  }, []);

  const HandleCreditBureauCheckData = async () => {
    const requestBody = {
      loanId: loan_id,
      customerType: borrower_type_value,
    };
    try {
      setCreditBureauCheckLoading(true);
      setCreditBureauCheckError(null);
      let creditBureauResponse = await postData(
        `${ajaxEndpoints.CREDIT_BUREAU_CHECK_DATA}`,
        requestBody,
      );

      if (creditBureauResponse?.status === true) {
        setCreditBureauCheckData(
          creditBureauResponse?.data?.consumerHitResponse?.body,
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "creditBureauCRC.tsx",
        },
      });
      setCreditBureauCheckError(errorHandler(error));
    } finally {
      setCreditBureauCheckLoading(false);
    }
  };
  return (
    <div>
      {creditBureauCheckError && (
        <p className="alert alert-danger   mt-4 small ">
          {creditBureauCheckError}
        </p>
      )}
      <div>
        <h2 className="text-bold py-3">Credit Bureau Check</h2>
        <div>
          <p>
            Borrower Name:
            <span className="text-weight-bold">
              {" "}
              {` ${first_name} ${last_name}`}
            </span>
          </p>
          <p>
            Customer Type:{" "}
            <span className="text-weight-bold">{borrower_type_value}</span>{" "}
          </p>
          <p>
            {" "}
            <span className="text-weight-bold">
              {" "}
              {borrower_type_value.toLowerCase() === "individual"
                ? `BVN: ${bvn}`
                : `RC Number: ${rc_number}`}
            </span>
          </p>
        </div>
        <button
          className="btn advancly-btn"
          onClick={HandleCreditBureauCheckData}
          disabled={creditBureauCheckLoading}
        >
          {creditBureauCheckLoading ? "Loading...." : "Perform Credit Check"}
        </button>
      </div>
      <div>
        {creditBureauCheckLoading ? (
          <Loader centered={true} text="Loading Credit Bureau Data..." />
        ) : creditBureauCheckData ? (
          <CreditBureauCheckTableData
            creditBureauCheckData={creditBureauCheckData}
          />
        ) : (
          <div className="d-flex justify-content-center pt-4">
            <p>
              No Credit Bureau Data Available Click Button to perform a check{" "}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditBureauCRC;
