import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  TIndividualCustomerParams,
  useIndividualCustomers,
} from "../../../custom-hooks/useIndividualCustomer";
import Paginator from "../../Pagination/Pagination.component";
import { Loader } from "../../../UI/Loaders/Loaders";
import { appInsights } from "../../../config/appInsights";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { showModal } from "../../../redux/actions/modal";
import { setAdvancedCustomerSearchParams } from "../../../redux/actions/digitalBank"; // Adjust import path as needed

const IndividualCustomers = () => {
  const searchParams = useSelector(
    (state: RootState) => state?.digitalBankReducer,
  );
  const dispatch = useDispatch();
  const [query, setQuery] = useState<any>({
    page: 1,
    pageSize: 20,
  });
  const [state, setState] = useState<TIndividualCustomerParams>({
    name: "",
    email: "",
    phoneNumber: "",
    startDate: "",
    endDate: "",
    page: 1,
    pageSize: 20,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "IndividualCustomers.tsx",
      isLoggedIn: true,
    });
  }, []);

  const { data, isLoading, isError } = useIndividualCustomers({
    page: query?.page,
    pageSize: query?.pageSize,
    email: searchParams?.email,
    phoneNumber: searchParams?.phoneNumber,
    name: searchParams?.name,
    startDate: searchParams?.startDate
      ? searchParams?.startDate
      : query?.startDate,
    endDate: searchParams?.endDate ? searchParams?.endDate : query?.endDate,
  });

  const resetTable = () => {
    setQuery({ ...state, page: 1 });
  };

  const handleFilterChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateDates = () => {
    if (
      state.startDate &&
      state.endDate &&
      new Date(state.startDate) > new Date(state.endDate)
    ) {
      alert("End date must be greater than start date.");
      return false;
    }
    return true;
  };

  const filterData = () => {
    if (!validateDates()) {
      return;
    }
    setQuery(state);
    resetTable();
  };

  const handleClear = () => {
    setState({
      name: "",
      email: "",
      phoneNumber: "",
      startDate: "",
      endDate: "",
      page: 1,
      pageSize: 20,
    });

    setQuery({
      page: 1,
      pageSize: 20,
      startDate: "",
      endDate: "",
    });

    dispatch(
      setAdvancedCustomerSearchParams({
        email: "",
        name: "",
        phoneNumber: "",
        endDate: "",
        startDate: "",
      }),
    );
  };

  useEffect(() => {
    setQuery({ ...query, pageSize: state?.pageSize });
  }, [state?.pageSize]);

  if (isLoading)
    return <Loader centered={true} text="Loading individual customers..." />;
  if (isError)
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            individual customers, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );

  return (
    <div>
      <h3 className="page-title mb-4">Individual Customers</h3>
      <div className="row">
        <div className="form-group col-md-2">
          <label>From</label>
          <div className="input-group">
            <input
              type="date"
              name="startDate"
              className="form-control"
              value={query.startDate || state.startDate}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="form-group col-md-2">
          <label>To</label>
          <div className="input-group">
            <input
              type="date"
              className="form-control"
              name="endDate"
              value={query.endDate || state.endDate}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
          <div className="input-group">
            <CustomSelectDropdown
              value={query.pageSize}
              name="pageSize"
              isShowDropdownArrow
              onChange={handleFilterChange}
            >
              <option value={10}>10 Entries</option>
              <option value={20}>20 Entries</option>
              <option value={50}>50 Entries</option>
              <option value={100}>100 Entries</option>
            </CustomSelectDropdown>
          </div>
        </div>
        <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
          <div className="input-group">
            <button
              className="btn advancly-white-btn ml-2 form-control"
              onClick={() => {
                dispatch(
                  showModal({
                    modalIdentifier: "advancedcustomersearch",
                    dataRecord: { customerType: "individualCustomers" },
                  }),
                );
              }}
            >
              <i className="fas fa-search" />
              &nbsp; Advance Search
            </button>
          </div>
        </div>
        <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
          <div className="input-group">
            <button
              className="btn advancly-btn ml-2 form-control"
              onClick={filterData}
            >
              Search
            </button>
          </div>
        </div>

        <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
          <div className="input-group">
            <button
              type="submit"
              className="btn advancly-red-btn btn-md ml-3"
              style={{ height: "3rem" }}
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <br />
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>S/N</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone Number</th>
              <th>Email Address</th>
              <th>Date Created</th>
              <th>Gender</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.map((item, index) => (
              <tr key={item.id}>
                <td>{(state.page - 1) * state.pageSize + index + 1}</td>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.phoneNumber}</td>
                <td>{item.email}</td>
                <td>{item.dateCreated}</td>
                <td>{item.gender}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/db/individual-customers/${item.id}`,
                      state: {
                        customerName: item.firstName + " " + item.lastName,
                        email: item?.email,
                        dateCreated: item.dateCreated,
                      },
                    }}
                    className="color-blue"
                  >
                    View Details
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data && data?.data && (
        <Paginator
          size={query.pageSize}
          page={query.page}
          count={data?.totalCount || 0}
          changeCurrentPage={(Page: number) =>
            setQuery({ ...state, page: Page })
          }
        />
      )}
    </div>
  );
};

export default IndividualCustomers;
