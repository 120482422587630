import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import { appInsights } from "../../../../../../config/appInsights";
import { IAccounts } from "../../../../Interfaces";
import Paginator from "../../../../../Pagination/Pagination.component";

import styles from "./accountDetails.module.scss";

interface IAccountDetailsProps {
  accounts: IAccounts[];
}

const AccountDetails = ({ accounts }: IAccountDetailsProps) => {
  const [page, setPage] = useState(1);
  const [size, setPageSize] = useState(10);

  const match = useRouteMatch();
  const { id } = match?.params as { id: string };

  const history = useHistory();

  useEffect(() => {
    appInsights.trackPageView({
      name: "AccountDetails.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      <h1 className={styles.title}>All Savings Account</h1>
      <div>
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Account Number</th>
                <th>Account Name</th>
                <th>Product</th>
                <th>Status</th>
                <th>Balance</th>
                <th>Last Active</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {accounts &&
                accounts
                  .slice((page - 1) * size, size * (page - 1) + size)
                  .map((data, _id: number) => {
                    const {
                      accountName,
                      accountNumber,
                      availableBalance,
                      lastActive,
                      product,
                      status,
                    } = data;
                    return (
                      <tr key={_id}>
                        <td>{_id + 1}</td>
                        <td>{accountNumber}</td>
                        <td>{accountName}</td>
                        <td>{product}</td>
                        <td>{status}</td>
                        <td>{availableBalance}</td>
                        <td>{lastActive}</td>
                        <td>
                          <span
                            className="color-mid-blue cursor-pointer font-weight-bold"
                            onClick={() => {
                              history.push(
                                `/db/business-customer/account-detail?customerId=${id}&accountNumber=${accountNumber}&accountName=${accountName}`
                              );
                            }}
                          >
                            {" "}
                            View Details
                          </span>
                        </td>
                      </tr>
                    );
                  })}
            </MDBTableBody>
          </MDBTable>
        </>

        <Paginator
          size={size}
          page={page}
          count={accounts && accounts.length - 1}
          changeCurrentPage={(pageNumber: number) => setPage(pageNumber)}
        />
      </div>
    </div>
  );
};

export default AccountDetails;
