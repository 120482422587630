import styles from "./tierListItem.module.scss";

interface ITierListItemProps {
  name: string;
  status: boolean;
  serialNumber: number;
}

const TierListItem = ({ name, status, serialNumber }: ITierListItemProps) => {
  return (
    <div className={styles.tierListItem}>
      <span
        className={`${styles.iconContainer} ${
          status === true && styles.pending
        }`}
      >
        {status === true ? <i className="ri-check-line"></i> : serialNumber}
      </span>
      <p
        className={`${styles.itemTitle} ${
          status === true && styles.greyedOutTitle
        }`}
      >
        {name}
      </p>
    </div>
  );
};

export default TierListItem;
