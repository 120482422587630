import { useEffect } from "react";
import { appInsights } from "../../../../../../config/appInsights";
import CustomInputField from "../../../../../CustomHTMLElements/CustomInputField";
import Accordion from "../../../../Components/Accordion";
import { IPartners } from "../../../../Interfaces";

interface IPartnersProps {
  data: IPartners[];
}

const Partners = ({ data: partners }: IPartnersProps) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "Partners.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      {partners.map((_item, _id) => (
        <div className="mb-3" key={_id}>
          <Accordion
            title={`Partners (${_id + 1})`}
            subTitle="Click the - icon to see more information on the partner"
          >
            <div className="row">
              <div className="col-md-5">
                <CustomInputField
                  label="First Name"
                  type="text"
                  name="firstName"
                  defaultValue={_item?.firstName!}
                  disabled
                />

                <CustomInputField
                  label="Gender"
                  type="text"
                  name="gender"
                  defaultValue={_item?.gender!}
                  disabled
                />
                <CustomInputField
                  label="Date Of Birth"
                  type="text"
                  name="dateOfBirth"
                  defaultValue={
                    _item?.dateOfBirth ? _item?.dateOfBirth.split("T")[0] : ""
                  }
                  disabled
                />
                <CustomInputField
                  label="Residential Address"
                  type="text"
                  name="address"
                  defaultValue={_item?.address!}
                  disabled
                />
                <CustomInputField
                  label="Identification"
                  type="text"
                  name="identityNumber"
                  defaultValue={_item?.identityNumber!}
                  disabled
                />
                <CustomInputField
                  label="Country"
                  type="text"
                  name="countryCode"
                  defaultValue={_item?.countryCode!}
                  disabled
                />
              </div>
              <div className="col-md-5">
                <CustomInputField
                  label="Last Name"
                  type="text"
                  name="lastName"
                  defaultValue={_item?.lastName!}
                  disabled
                />

                <CustomInputField
                  label="Phone Number"
                  type="text"
                  name="phoneNumber"
                  defaultValue={_item?.phoneNumber!}
                  disabled
                />

                <CustomInputField
                  label="City"
                  type="text"
                  name="city"
                  defaultValue={_item?.city!}
                  disabled
                />

                <CustomInputField
                  label="State"
                  type="text"
                  name="state"
                  defaultValue={_item?.state!}
                  disabled
                />

                <CustomInputField
                  label="BVN"
                  type="text"
                  name="bvn"
                  defaultValue={_item?.bvn!}
                  disabled
                />

                <CustomInputField
                  label="Email"
                  type="text"
                  name="email"
                  defaultValue={_item?.email!}
                  disabled
                />
              </div>
            </div>
          </Accordion>
        </div>
      ))}

      {partners && partners.length < 1 && (
        <div className="w-100 d-flex align-items-center justify-content-center py-3">
          <h3 className="font-weight-bold">
            Customer is yet to submit partners information
          </h3>
        </div>
      )}
    </div>
  );
};

export default Partners;
