import { useEffect } from "react";
import { appInsights } from "../../../../../config/appInsights";
import CustomInputField from "../../../../CustomHTMLElements/CustomInputField";
import CustomTextArea from "../../../../../NewComponents/TypedComponents/CustomHTMLElements/CustomTextArea";
import { IBusinessInformation } from "../../../Interfaces";

interface IBusinessInformationProps {
  data: IBusinessInformation;
}
const BusinessInformation = ({ data }: IBusinessInformationProps) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "BusinessInformation.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="table-view speed-1x animated fadeInRight">
      <div className="row">
        <div className="col-md-5">
          <CustomInputField
            label="Business Type"
            type="text"
            name="business_type"
            defaultValue={data?.business_type!}
            disabled
          />
          <CustomInputField
            label="Industry"
            type="text"
            name="biz_category"
            defaultValue={data?.biz_category!}
            disabled
          />
          <CustomInputField
            label="Tax Identification number"
            type="text"
            name="tax_identification_number"
            defaultValue={data?.tax_identification_number!}
            disabled
          />
          <CustomInputField
            label="Business Street Address"
            type="text"
            name="biz_Address"
            defaultValue={data?.biz_Address!}
            disabled
          />
          <CustomInputField
            label="Business State"
            type="text"
            name="state"
            defaultValue={data?.state!}
            disabled
          />
        </div>

        <div className="col-md-5">
          <CustomInputField
            label="Business Name"
            type="text"
            name="biz_name"
            defaultValue={data?.biz_name!}
            disabled
          />
          <CustomTextArea
            label="How does your business create value?"
            name="biz_overview"
            defaultValue={data?.biz_description!}
            disabled
          />
          <CustomInputField
            label="Business City"
            type="text"
            name="city"
            defaultValue={data?.city!}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessInformation;
