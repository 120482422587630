import React, { useState, useEffect } from "react";
import { Table, Badge, Button } from "reactstrap";
import { appInsights } from "../../../../config/appInsights";
import { Link, useRouteMatch } from "react-router-dom";
import { useIndividualCustomerDetails } from "../../../../custom-hooks/useIndividualCustomer";
import { formatMoney } from "../../../../helpers/formatter";
import moment from "moment";
import { Loader } from "../../../../UI/Loaders/Loaders";

const IndividualAccountsTab = () => {
  const match = useRouteMatch();
  const { id } = match?.params as { id: string };
  useEffect(() => {
    appInsights.trackPageView({
      name: "IndividualAccountsTab",
      isLoggedIn: true,
    });
  }, []);

  const { data, isLoading, isError } = useIndividualCustomerDetails({
    customerId: Number(id),
  });

  // Function to determine badge color based on account status
  const getBadgeColor = (status: string): string => {
    switch (status) {
      case "ACTIVE":
        return "success";
      case "INACTIVE":
        return "secondary";
      default:
        return "light";
    }
  };

  if (isLoading)
    return <Loader centered={true} text="Loading customer accounts..." />;

  if (isError)
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            customer accounts, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );

  return (
    <div>
      <br />
      <h4>All Savings Accounts</h4>
      <br />
      <Table responsive>
        <thead>
          <tr>
            <th>Account No</th>
            <th>Acct Name</th>
            <th>Product</th>
            <th>Status</th>
            <th>Available Balance</th>
            <th>Last Active</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.accounts?.map((account, index) => (
            <tr key={index}>
              <td>{account.accountNumber}</td>
              <td>{account.accountName}</td>
              <td>{account.product}</td>
              <td>
                <Badge color={getBadgeColor(account.status)} pill>
                  {account.status}
                </Badge>
              </td>
              <td>{formatMoney(account.availableBalance, "NGN ")}</td>
              <td>
                {account?.lastActive
                  ? moment(account?.lastActive).format(
                      "MMMM Do YYYY, h:mm:ss a",
                    )
                  : "N/A"}
              </td>
              <td>
                <Link
                  to={{
                    pathname: `/db/individual-customers/${id}/details`,
                    state: {
                      accountNumber: Number(account.accountNumber),
                      accountName: account.accountName,
                    },
                  }}
                  className="color-blue"
                >
                  View Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default IndividualAccountsTab;
