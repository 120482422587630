import React, { Fragment, useEffect, useState } from "react";
import { useCustomerStockDetails } from "../../../../custom-hooks/useCustomerStockDetails";
import { formatMoney } from "../../../../helpers/formatter";
import Loader from "../../../../NewComponents/Loader/Loader.component";
import Paginator from "../../../Pagination/Pagination.component";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const CustomerStockData = ({ customerId }: any) => {
  const [count, setCount] = useState(null);
  const [search, setSearch] = useState<{
    PageSize: number;
    Page: number;
    CustomerId: number;
    DateFrom: any;
    DateTo: any;
  }>({
    PageSize: 20,
    Page: 1,
    CustomerId: customerId,
    DateFrom: null,
    DateTo: null,
  });
  const [presearch, setPresearch] = useState({
    Page: 1,
    PageSize: 20,
    CustomerId: customerId,
  });
  const { data: stockdata, status, error } = useCustomerStockDetails(presearch);

  useEffect(() => {
    MixPanel.track(`Page View: StockData.tsx`, {
      page: "StockData.tsx",
    });
  }, []);

  if (stockdata) {
    if (count === null) {
      setCount(stockdata?.totalCount);
    }
  }
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value, Page: search.Page, PageSize: 20 });
  };
  let RenderedComponent = null;
  if (!stockdata && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Customer Stock Data
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    //@ts-ignore
    RenderedComponent = <Loader centered={true} text="Loading Repayment..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Customer Stock Order, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (stockdata && stockdata?.data?.length === 0) ||
      (stockdata && !stockdata?.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Product ID</th>
                  <th>Product Category</th>
                  <th>Customer Phone No</th>
                  <th>Minimum Stock Quantity</th>
                  <th>Total Quantity</th>
                  <th>Available Quantity</th>
                  <th>Reserved Quantity</th>
                  <th>Unit Price</th>
                  <th>Tax Amount</th>
                  <th>Discount Amount</th>
                  <th>Net Price</th>
                  <th>Stock Level</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {stockdata?.data?.map((data: any, id: number) => {
                  const {
                    productId,
                    productCategory,
                    customerPhoneNo,
                    minimumStockQuantity,
                    totalQuantity,
                    availableQuantity,
                    reservedQuantity,
                    unitPrice,
                    taxAmount,
                    discountAmount,
                    netPrice,
                    stockLevel,
                    date,
                  } = data;
                  return (
                    <tr key={id}>
                      <td>{id + 1}</td>
                      <td>{productId}</td>
                      <td>{productCategory}</td>
                      <td>{customerPhoneNo}</td>
                      <td>{minimumStockQuantity}</td>
                      <td>{totalQuantity}</td>
                      <td>{availableQuantity}</td>
                      <td>{reservedQuantity}</td>
                      <td>{formatMoney(unitPrice)}</td>
                      <td>{formatMoney(taxAmount)}</td>
                      <td>{formatMoney(discountAmount)}</td>
                      <td>{formatMoney(netPrice)}</td>
                      <td>{stockLevel}</td>
                      <td>{date?.substring(0, 10)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="dataTables_wrapper">
        <div
          id="activeEcosystem"
          className="Disbursement table-view animated speed-1x fadeInRight"
        >
          <form className="d-flex flex-row flex-wrap">
            <div className="form-group mr-3 mt-2">
              <div className="input-group ">
                <label className="mr-2">From </label>
                <input
                  type="date"
                  name="DateFrom"
                  className="form-control"
                  value={search.DateFrom}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="form-group mt-2 mr-3">
              <div className="input-group">
                <label className="mr-2">To </label>
                <input
                  type="date"
                  className="form-control"
                  name="DateTo"
                  value={search.DateTo}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="btn advancly-btn btn-md"
                value="submit"
                disabled={status === "loading" || !stockdata}
                onClick={() => setPresearch(search)}
              >
                Search
              </button>
            </div>
          </form>

          {RenderedComponent}
          <Paginator
            size={presearch.PageSize}
            page={presearch.Page}
            count={count}
            changeCurrentPage={(Page: any) => setPresearch({ ...search, Page })}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerStockData;
