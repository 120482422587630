import { useRef, useState, Fragment, useEffect } from "react";
import styles from "./UploadBankStatement.module.scss";
import * as apiEndpoints from "../../../../../../api/ajax-endpoints";
import moment from "moment";
import { useQueryCache } from "react-query";
import { Loader } from "../../../../../../UI/Loaders/Loaders";
import FileUploadIcon from "../../../../../../assets/svg/icons/file-upload-fill.svg";
import {
  postFormDataWithDotNet,
  getData,
  getBlobData,
} from "../../../../../../newApis/dotNetApiMethods";
import useBankStatementUploadHistory from "../../../../../../custom-hooks/useBankStatementUploadHistory";
import { Button, Dropdown } from "antd";
import { appInsights } from "../../../../../../config/appInsights";
import {
  handleDynamicSwalAlert,
  handleSwalErrorAlert,
} from "../../../../../../helpers";
import ViewBankStatementModal from "./ViewBankStatementModal";
import { MixPanel } from "../../../../../../shared/MixPanel/MixPanel";

interface DownloadStatus {
  [id: string]: boolean;
}

interface BankStatementHistoryProps {
  requestRef: string;
  loanId: number;
  fileName: string;
  filePath: string;
  createdDateTime: string;
  responseDateTime: string;
  status: string;
}

const UploadBankStatement = ({ loanId, properties }: any) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingBankStatementStatus, setLoadingBankStatementStatus] =
    useState<DownloadStatus>({});
  const [confirmationTab, setConfirmationTab] = useState<boolean>(false);
  const [isDownloadingDoc, setIsDownloadingDoc] = useState<DownloadStatus>({});
  const [isLoadingBankStatementView, setIsLoadingBankStatementView] =
    useState(false);
  const [documentRecord, setDocumentRecord] = useState({
    documentUrl: "",
    type: "",
  });
  const [showDocViewModal, setShowDocViewModal] = useState(false);
  const queryCache = useQueryCache();

  useEffect(() => {
    appInsights.trackPageView({
      name: "UploadBankStatement - (UploadBankStatement.jsx)",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: UploadBankStatement.tsx`, {
      page: "UploadBankStatement.tsx",
    });
  }, []);

  const downloanDocType = [
    {
      key: "pdf",
      label: "PDF",
    },
    {
      key: "xlsx",
      label: "EXCEL",
    },
  ];

  const {
    data: bankStatementUploadHistory,
    isLoading: isLoadingBankStatementUploadHistory,
    error: bankStatementUploadHistoryError,
    refetch: refetchBankStatementUploadHistory,
  } = useBankStatementUploadHistory({
    customerId: properties?.dataRecord?.borrower_details?.customer_id,
  });

  const getBankStatementExtractionStatus = async (requestRef: string) => {
    setLoadingBankStatementStatus((prev) => ({ ...prev, [requestRef]: true }));
    try {
      const response = await getData(
        `${apiEndpoints.BANK_STATEMENT_EXTRACTION_STATUS}?requestRef=${requestRef}`,
      );
      if (response?.data?.data.status === "Pending") {
        handleDynamicSwalAlert("Document Extraction is Processing...");
      }
      if (response?.data?.data.status === "Error") {
        handleDynamicSwalAlert("Document Extraction failed.", "Error", "error");
      }
      if (response?.data?.data.status === "Success") {
        handleDynamicSwalAlert(
          "Document Successfully Extracted!",
          "Success",
          "success",
        );
      }
    } catch (error: any) {
      handleSwalErrorAlert(error?.response?.data?.message ?? error?.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadBankStatement.tsx",
        },
      });
    } finally {
      setLoadingBankStatementStatus((prev) => ({
        ...prev,
        [requestRef]: false,
      }));
    }
  };

  const handleViewDocument = async (filePath: string) => {
    setShowDocViewModal(true);
    setIsLoadingBankStatementView(true);
    try {
      const response = await getData(
        `${apiEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${filePath}`,
      );

      if (response.status === 200) {
        setDocumentRecord({
          documentUrl:
            "data:application/pdf;base64," + response.data.base64_string,
          type: "application/pdf",
        });
      }
    } catch (error: any) {
      handleSwalErrorAlert(
        error.response?.data?.message ?? "Failed to load document",
      );
    } finally {
      setIsLoadingBankStatementView(false);
    }
  };

  const handleDownloadPDFDoc = async (docRef: string) => {
    setIsDownloadingDoc((prevstate) => ({ ...prevstate, [docRef]: true }));
    try {
      const response = await getBlobData(
        `${apiEndpoints.DOWNLOAD_BANK_STATEMENT_PDF}?requestRef=${docRef}`,
      );
      if (response.status === 200) {
        const blob = response?.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Doc_${docRef}_${moment()
            .format("YYYYMMDD_HHmmss")
            .replaceAll("/", "_")}_file.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        handleSwalErrorAlert("Failed to download document");
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: new Error(error),
        properties: {
          fileName: "UploadBankStatement.tsx",
        },
      });
      handleSwalErrorAlert(error?.response?.data?.message ?? error?.message);
    }
    setIsDownloadingDoc((prevstate) => ({ ...prevstate, [docRef]: false }));
  };

  const handleDownloadExcelDoc = async (
    docRef: string,
    extractionStatus: string,
  ) => {
    if (extractionStatus === "Pending" || extractionStatus === "Error") {
      return handleSwalErrorAlert("Document extraction is incomplete");
    }
    setIsDownloadingDoc((prevstate) => ({ ...prevstate, [docRef]: true }));
    try {
      const response = await getBlobData(
        `${apiEndpoints.DOWNLOAD_BANK_STATEMENT_EXCEL}?requestRef=${docRef}`,
      );

      if (response.status === 200) {
        const blob = response?.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Doc_${docRef}_${moment()
            .format("YYYYMMDD_HHmmss")
            .replaceAll("/", "_")}_file.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        handleSwalErrorAlert("Failed to download document");
      }
    } catch (error: any) {
      handleSwalErrorAlert(error?.response?.data?.message ?? error?.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadBankStatement.tsx",
        },
      });
    }
    setIsDownloadingDoc((prevstate) => ({ ...prevstate, [docRef]: false }));
  };

  const fileInput = useRef<HTMLInputElement>(null);

  const validateFileToUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e?.target?.files) {
      if (e.target.files[0].name.endsWith(".pdf")) {
        setFile(e.target.files[0]);
      } else {
        setFile(null);
      }
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    if (e.dataTransfer && e.dataTransfer.files) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const resetAllFields = () => {
    setFile(null);
    setConfirmationTab(false);
  };

  const handleUploadBankStatement = async () => {
    setLoading(true);
    const formData: any = new FormData();
    if (file) {
      formData.append("BankStatement", file);
      formData.append("LoanId", loanId);
    }

    try {
      const response = await postFormDataWithDotNet(
        apiEndpoints.UPLOAD_BANK_STATEMENT_TO_EXTRACT,
        formData,
      );
      if (response?.status === true) {
        queryCache.invalidateQueries([
          { customerId: properties?.dataRecord?.borrower_details?.customer_id },
          "fetchBankStatementUploads",
        ]);
        handleDynamicSwalAlert(
          `Request Ref: ${response?.data?.requestRef}\nBank statement is being processed`,
          "Upload Successful",
          "success",
        );
      } else {
        handleSwalErrorAlert(
          response?.message ?? "Failed to upload bank statement",
        );
      }
    } catch (error: any) {
      handleSwalErrorAlert(error?.response?.data?.message ?? error.message);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadBankStatement.tsx",
        },
      });
    } finally {
      setLoading(false);
      resetAllFields();
    }
  };

  return (
    <>
      <div className="speed-1x animated fadeInRight">
        <div className={styles.bank_statement_upload_container}>
          <header className={styles.bank_statement_upload_header}>
            <h4 className={styles.title}>Bank Statement Uploads</h4>
            <div className="d-flex align-items-center">
              <button
                id="create"
                data-id="create-role"
                data-action="create"
                className="btn advancly-white-btn ml-4"
                onClick={() => refetchBankStatementUploadHistory()}
              >
                <i className="fas fa-refresh" />
              </button>
            </div>
          </header>
          {isLoadingBankStatementUploadHistory ? (
            <Loader
              text="Loading available Bank statement uploads.."
              centered={true}
            />
          ) : bankStatementUploadHistory?.length > 0 ? (
            <div className="row m-x-sm m-y-2 grid__padding">
              <div className="table-responsive">
                <table className="table">
                  <thead className="bg-lighter-grey">
                    <tr>
                      <th>S/N</th>
                      <th>File Name</th>
                      <th>Date Created</th>
                      <th>Status</th>
                      <th>Processing Date</th>
                      <th>GetPDFExtractionStatus</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {bankStatementUploadHistory?.map(
                    (
                      bankStatement: BankStatementHistoryProps,
                      index: number,
                    ) => (
                      <tbody key={bankStatement.requestRef}>
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {bankStatement?.fileName.length <= 25
                              ? bankStatement?.fileName.slice(0, 25)
                              : bankStatement?.fileName.slice(0, 25) + "..."}
                          </td>
                          <td>
                            {moment(bankStatement.createdDateTime).format(
                              "D/MM/YYYY, h:mm a",
                            )}
                          </td>
                          <td>{bankStatement?.status}</td>
                          <td>
                            {bankStatement.responseDateTime ===
                            "0001-01-01T00:00:00"
                              ? "NIL"
                              : moment(bankStatement.responseDateTime).format(
                                  "D/MM/YYYY, h:mm a",
                                )}
                          </td>
                          <td>
                            {loadingBankStatementStatus[
                              bankStatement.requestRef
                            ] ? (
                              <Button
                                type="primary"
                                loading
                                onClick={() =>
                                  getBankStatementExtractionStatus(
                                    bankStatement.requestRef,
                                  )
                                }
                              >
                                Loading
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                onClick={() =>
                                  getBankStatementExtractionStatus(
                                    bankStatement.requestRef,
                                  )
                                }
                              >
                                Refresh Status
                              </Button>
                            )}
                          </td>
                          <td>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                type="primary"
                                ghost
                                onClick={() =>
                                  handleViewDocument(bankStatement.filePath)
                                }
                              >
                                View
                              </Button>
                              {isDownloadingDoc[bankStatement.requestRef] ? (
                                <Button type="primary" loading>
                                  Loading
                                </Button>
                              ) : (
                                <Dropdown
                                  trigger={["click"]}
                                  menu={{
                                    items: downloanDocType,
                                    onClick: ({ key }) => {
                                      if (key === "pdf") {
                                        handleDownloadPDFDoc(
                                          bankStatement.requestRef,
                                        );
                                      }
                                      if (key === "xlsx") {
                                        handleDownloadExcelDoc(
                                          bankStatement.requestRef,
                                          bankStatement.status,
                                        );
                                      }
                                    },
                                  }}
                                >
                                  <Button type="primary">Download</Button>
                                </Dropdown>
                              )}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    ),
                  )}
                </table>
              </div>
            </div>
          ) : bankStatementUploadHistoryError ? (
            <p>Error loading bank statement</p>
          ) : (
            <p>No Bank statement has been uploaded</p>
          )}

          <hr />
          <>
            <h3 className="mb-2">Upload Bank Statement</h3>

            <div className={styles.doc_upload_container}>
              {!confirmationTab && (
                <div>
                  <div className={styles.upload_box}>
                    <input
                      type="file"
                      className="d-none"
                      ref={fileInput}
                      id="bank_statement-upload-input"
                      accept=".pdf" // Accept PDF files only
                      onChange={validateFileToUpload}
                    />
                    <div>
                      <Fragment>
                        <div className="upload-box-section">
                          <div
                            className="upload-box-icon-border"
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                          >
                            <img src={FileUploadIcon} alt="Upload File" />
                            {!file ? (
                              <Fragment>
                                <div className="color-blue font-weight-bold">
                                  Upload File
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  Drag & drop your file here or{" "}
                                  <span
                                    className="color-light-blue"
                                    onClick={() => fileInput.current?.click()}
                                  >
                                    browse
                                  </span>
                                </div>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <div className="color-blue font-weight-bold">
                                  {file.name.length > 50
                                    ? file.name.substring(0, 50) + "..."
                                    : file.name}
                                </div>
                                <div>
                                  {(file.size / 1024).toFixed(2)}
                                  kb
                                </div>
                                <div
                                  className="color-light-blue"
                                  onClick={resetAllFields}
                                >
                                  <u>Remove</u>
                                </div>
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    </div>
                  </div>

                  <button
                    className="btn advancly-btn btn-md"
                    disabled={loading || !file}
                    onClick={() => {
                      setConfirmationTab(true);
                    }}
                  >
                    Upload Files
                  </button>
                </div>
              )}
              {confirmationTab && (
                <div>
                  <div>
                    <p className="d-flex justify-content-center">
                      {" "}
                      Are you sure you want to Upload File?
                    </p>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn advancly-btn btn-md"
                        onClick={handleUploadBankStatement}
                      >
                        {loading ? "Uploading Files..." : "Yes"}
                      </button>
                      <button
                        className="btn advancly-btn btn-md"
                        onClick={() => setConfirmationTab(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
      {showDocViewModal && (
        <ViewBankStatementModal
          setShowDocViewModal={setShowDocViewModal}
          isLoadingBankStatementView={isLoadingBankStatementView}
          documentRecord={documentRecord}
        />
      )}
    </>
  );
};

export default UploadBankStatement;
