import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { USE_MOCK } from "../appConstants";
import { IPaginatedResponse } from "../interfaces/response";

export type TParams = {
  pageNumber: number;
  size: number;
  startDate?: string;
  endDate?: string;
  searchValue?: string;
  dateCreated?: string;
  TierType: number;
};

export interface IBvnDetails {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  age: string;
  gender: string;
  birthCountry: string;
}

export interface INinDetails {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  age: string;
  gender: string;
  birthCountry: string;
}

export interface IPendingTierUpgradeRequest {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  accountNumber: string;
  email: string;
  dateCreated: string;
  gender: string;
  bvn: string;
  bvnDetails: IBvnDetails;
  kycRequestDate: string;
  transactionLimit: string;
  nin: string;
  ninDetails: INinDetails;
  isAddressVerified: boolean;
  isBvnVerified: boolean;
  isNinVerified: boolean;
  picturePath: string;
}

export const getPendingTierUpgradeRequests = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  searchValue,
  TierType,
  dateCreated,
}: TParams): Promise<IPaginatedResponse<IPendingTierUpgradeRequest[]>> => {
  if (USE_MOCK) {
    // Return mock data using setTimeout to simulate network delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          message: "Requests fetched successfully!",
          status: true,
          status_code: 200,
          page: 1,
          pageSize: 10,
          totalCount: 100,
          totalPages: 100,
          data: [
            {
              id: 1,
              firstName: "dabi",
              lastName: "dubai",
              phoneNumber: "0812707798",
              accountNumber: "123456677",
              email: "cacedy@nomail.com",
              dateCreated: "20-10-10",
              gender: "Male",
              bvn: "1234566779",
              bvnDetails: {
                firstName: "emeka",
                middleName: "onasanjo",
                lastName: "mandela",
                dateOfBirth: "20-10-10",
                age: "29",
                gender: "Male",
                birthCountry: "NG",
              },
              kycRequestDate: "20-10-10",
              transactionLimit: "2000000",
              nin: "1232344556",
              ninDetails: {
                firstName: "Cane",
                middleName: "Ahmed",
                lastName: "Abel",
                dateOfBirth: "20-10-10",
                age: "59",
                gender: "Prefer Not To Say",
                birthCountry: "NG",
              },
              isAddressVerified: false,
              isBvnVerified: false,
              isNinVerified: false,
              picturePath: "",
            },
          ],
        });
      }, 5000);
    });
  }

  let queryParams = `?Page=${pageNumber}&PageSize=${size}`;
  if (startDate) {
    queryParams = `${queryParams}&startDate=${startDate}`;
  }

  if (endDate) {
    queryParams = `${queryParams}&endDate=${endDate}`;
  }

  if (dateCreated) {
    queryParams = `${queryParams}&dateCreated=${dateCreated}`;
  }

  if (TierType) {
    queryParams = `${queryParams}&TierType=${TierType}`;
  }

  if (searchValue) queryParams += `&searchValue=${searchValue}`;

  return await getDataWithDotNet(
    `${ajaxEndpoints.DB_PENDING_TIER_UPGRADE_REQUESTS}${queryParams}`
  );
};

export const usePendingTierUpgradeRequests = (params: TParams) => {
  return useQuery<IPaginatedResponse<IPendingTierUpgradeRequest[]>, Error>(
    ["getPendingTierUpgradeRequests", params],
    () => getPendingTierUpgradeRequests(params),
    {
      refetchOnWindowFocus: false,
    }
  );
};
