import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { USE_MOCK } from "../appConstants";
import { IResponse } from "../interfaces/response";

export type TCustomerAccountDetailsParams = {
  customerId: string;
  accountNumber: string;
};

export interface ICustomerAccountDetails {
  product: string;
  accountNumber: string;
  availableBalance: number;
  currentBalance: number;
  activatedOn: string;
  officerAssigned: string;
  currency: string;
  totalWithdrawals: number;
  interestEarned: number;
  nominalInterestRate: string;
  totalDeposits: number;
  totalInterestEarned: number;
}

export const getCustomerAccountDetails = async ({
  customerId,
  accountNumber,
}: TCustomerAccountDetailsParams): Promise<
  IResponse<ICustomerAccountDetails>
> => {
  if (USE_MOCK) {
    // Return mock data using setTimeout to simulate network delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          message: "Requests fetched successfully!",
          status: true,
          status_code: 200,
          data: {
            product: "Savings Product",
            accountNumber: "4415153651",
            availableBalance: 0,
            currentBalance: 0,
            activatedOn: "03 Sep 2024",
            officerAssigned: "Unassigned",
            currency: "Nigerian Naira [NGN]",
            totalWithdrawals: 0,
            interestEarned: 0,
            nominalInterestRate: "0.000000",
            totalDeposits: 0,
            totalInterestEarned: 0,
          },
        });
      }, 5000);
    });
  }

  const { data } = await getData(
    `${ajaxEndpoints.DB_CUSTOMER_ACCOUNT_DETAILS}?customerId=${customerId}&accountNumber=${accountNumber}`
  );

  return data;
};

export const useCustomerAccountDetail = (
  params: TCustomerAccountDetailsParams
) => {
  return useQuery<IResponse<ICustomerAccountDetails>, Error>(
    ["getCustomerAccountDetails", params],
    () => getCustomerAccountDetails(params),
    {
      refetchOnWindowFocus: false,
    }
  );
};
