import { useState } from "react";

function HealthProbe() {
  const [healthData, setHealthData] = useState({
    "application name": "Advancly Fuse",
    status: "healthy",
    services: {
      database: "connected",
      cache: "connected",
      api: "running",
    },
    timestamp: new Date().toISOString(),
  });

  return (
    <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
      {JSON.stringify(healthData, null, 2)}
    </pre>
  );
}

export default HealthProbe;
