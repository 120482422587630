import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import { Loader } from "../../../UI/Loaders/Loaders";
import { showModal } from "../../../redux/actions/modal";
import { appInsights } from "../../../config/appInsights";
import useFundingWallets, {
  TFundingWallet,
} from "../../../custom-hooks/useFundingWallets";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import Paginator from "../../Pagination/Pagination.component";
import { postObjectToLocalStorage } from "../../../helpers/localStorage";
import { StorageKeys } from "../../../helpers/storageKeys";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const FundingWallets = () => {
  const [query, setQuery] = useState("");
  // const [preState, setPreState] = useState({
  //   page: 1,
  //   pageSize: 10,
  // });
  const [state, setState] = useState({
    searchQuery: "",
    page: 1,
    pageSize: 10,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "FundingWallets",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: FundingWallet.tsx`, {
      page: "FundingWallet.tsx",
    });
  }, []);

  const {
    data: fundingWallets,
    status,
    error,
  } = useFundingWallets({
    searchQuery: state?.searchQuery,
    page: state?.page,
    pageSize: state?.pageSize,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    return setQuery(value);
  };

  const handleDisplayFundingWalletDetails = ({
    aggregatorName,
    accountNumber,
    aggregatorId,
    accountName,
    bankName,
    rcNumber,
    currency,
    fundingPartner,
    walletId,
  }: TFundingWallet) => {
    dispatch(
      showModal({
        modalIdentifier: "fundingwalletdetails",
        dataRecord: {
          aggregatorName,
          accountNumber,
          aggregatorId,
          accountName,
          bankName,
          rcNumber,
          currency,
          fundingPartner,
          walletId,
        },
      }),
    );
    postObjectToLocalStorage(StorageKeys.FUNDING_WALLET_DETAILS, {
      aggregatorName,
      accountNumber,
      aggregatorId,
      accountName,
      bankName,
      rcNumber,
      currency,
      fundingPartner,
      walletId,
    });
  };

  let RenderedComponent = null;
  if (!fundingWallets && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Funding Wallets
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Funding Wallets..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              funding wallets, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (fundingWallets &&
        fundingWallets.data &&
        fundingWallets.data.length === 0) ||
      (fundingWallets && !fundingWallets.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no funding
              wallets.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Aggregator Name</th>
                <th>Account Number </th>
                <th>Acount Name</th>
                <th>Bank Name</th>
                <th>RC Number</th>
                <th>Currency</th>
                <th>Funding Partner</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {fundingWallets &&
                fundingWallets.data.map((data, _id: number) => {
                  const {
                    accountName,
                    accountNumber,
                    aggregatorName,
                    bankName,
                    currency,
                    fundingPartner,
                    rcNumber,
                    aggregatorId,
                    walletId,
                  } = data;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      {/* <td>
                        {" "}
                        {(searchQuery?.Page - 1) * searchQuery?.PageSize +
                          (_id + 1)}
                      </td> */}
                      <td>{aggregatorName}</td>
                      <td>{accountNumber}</td>
                      <td>{accountName}</td>
                      <td>{bankName}</td>
                      <td>{rcNumber}</td>
                      <td>{currency}</td>
                      <td>{fundingPartner}</td>
                      <td>
                        <span
                          className="color-mid-blue cursor-pointer font-weight-bold"
                          onClick={() => {
                            handleDisplayFundingWalletDetails(data);
                          }}
                        >
                          {" "}
                          View
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  useEffect(() => {
    let debounceGetFundingWallets: NodeJS.Timeout = setTimeout(() => {});

    if (query) {
      debounceGetFundingWallets = setTimeout(() => {
        setState((prev) => ({ ...prev, searchQuery: query }));
      }, 3000);
    } else {
      debounceGetFundingWallets = setTimeout(() => {
        setState((prev) => ({ ...prev, searchQuery: "" }));
      }, 3000);
    }

    return () => clearTimeout(debounceGetFundingWallets);
  }, [query]);

  return (
    <div>
      <div className="datatable-wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-4">
            <h3 className="page-title">Funding Wallet</h3>
            <button
              type="button"
              className="btn advancly-btn btn-md m-0"
              onClick={() => history.push("/funding-wallets/create")}
            >
              Create Funding Wallet
            </button>
          </div>
          <h4 className="mb-4">Current funding wallets available</h4>
          <div className="d-flex flex-row flex-wrap"></div>

          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <CustomInputField
                type="text"
                name="searchQuery"
                iconType="fas fa-search"
                iconStyle={{ top: "0.5rem" }}
                placeholder="Account number, Aggregator"
                customInputStyle={{ marginTop: "0.13rem" }}
                onChange={handleChange}
              />
            </div>
            {/* <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12">
              <CustomSelectDropdown
                name="aggregatorName"
                className="mt-3"
                onChange={handleChange}
              >
                <option value="">Select Aggregator</option>
                {aggregatorData &&
                  aggregatorData?.data?.data.map((_aggregator) => (
                    <option
                      key={_aggregator?.aggregator_id}
                      value={_aggregator?.biz_name}
                    >
                      {_aggregator?.biz_name}
                    </option>
                  ))}
              </CustomSelectDropdown>
            </div> */}
          </div>

          <div>{RenderedComponent}</div>

          <Paginator
            size={state.pageSize}
            page={state.page}
            count={fundingWallets?.totalCount}
            changeCurrentPage={(pageNumber: number) =>
              setState({ ...state, page: pageNumber })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FundingWallets;
