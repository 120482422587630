import { Fragment, useState, useRef, useEffect } from "react";

import { useDispatch } from "react-redux";

import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import { useLocation, useHistory } from "react-router-dom";
import Alert from "../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { appInsights } from "../../../../config/appInsights";
import CustomInputField from "../../../CustomHTMLElements/CustomInputField";
import TierCheckList from "../../Components/Tier";
import TierUpgradeReviewModal from "../../Components/TierUpgradeReviewModal";
import { showModal } from "../../../../redux/actions/modal";
import { UpradeType } from "../../Interfaces";
import {
  IPendingTierUpgradeRequest,
  IBvnDetails,
  INinDetails,
} from "../../../../custom-hooks/usePendingTierUpgradeRequests";

import styles from "./reviewTierUpgradeRequest.module.scss";

const ReviewTierUpgradeRequest = () => {
  const [modalActionType, setModalActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const dismissModalRef = useRef();
  const { state } = useLocation();

  const {
    tier,
    data: {
      accountNumber,
      bvn,
      bvnDetails,
      dateCreated,
      email,
      firstName,
      gender,
      id,
      isAddressVerified,
      isBvnVerified,
      isNinVerified,
      kycRequestDate,
      lastName,
      nin,
      ninDetails,
      phoneNumber,
      transactionLimit,
      picturePath,
    },
  } = state as {
    tier: UpradeType;
    data: IPendingTierUpgradeRequest;
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Review Tier Upgrade Request",
      isLoggedIn: true,
    });
  }, []);

  const declineRequest = async ({ message }: any) => {};

  const tierTwoItems = [
    {
      id: 1,
      name: "BVN Verification",
      status: isBvnVerified,
    },
    {
      id: 2,
      name: "NIN Verification",
      status: isNinVerified,
    },
    {
      id: 3,
      name: "Address Verification",
      status: isAddressVerified,
    },
  ];

  const tierThreeItems = [
    {
      id: 1,
      name: "Proof Of Address",
      status: false,
    },
  ];

  const handleViewUserInformation = (
    bvnDetails: IBvnDetails,
    ninDetails: INinDetails,
    _picturePath: string
  ) => {
    dispatch(
      showModal({
        modalIdentifier: "bvnandnindetails",
        dataRecord: { bvnDetails, ninDetails, _picturePath },
      })
    );
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Review Request
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          <div>
            <button
              className="btn advancly-btn btn-md mr-2"
              data-toggle="modal"
              data-target="#addReviewTierUpgradeModal"
              onClick={() => setModalActionType("approve")}
            >
              Upgrade Tier
            </button>
            <button
              className="btn advancly-white-btn btn-md"
              data-toggle="modal"
              data-target="#addReviewTierUpgradeModal"
              onClick={() => setModalActionType("decline")}
            >
              Decline Tier
            </button>
          </div>
        </div>
      </div>
      <div className={styles.profileArea}>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer's Name</div>
          <div className={styles.content}>{`${firstName} ${lastName}`}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Email</div>
          <div className={styles.content}>{email}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Phone No.</div>
          <div className={styles.content}>{phoneNumber}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>KYC Upgrade Request Date</div>
          <div className={styles.content}>{kycRequestDate}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Tier</div>
          <div className={styles.content}>Tier {tier}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Transaction Limit</div>
          <div className={styles.content}>
            {transactionLimit ? transactionLimit : "NIL"}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Account Number</div>
          <div className={styles.content}>{accountNumber}</div>
        </div>
      </div>

      <div className={`row pl-2 pr-2 ${styles.documentsContainer}`}>
        <div className={`col-sm-5 col-xs-12 ${styles.availableDocs}`}>
          <h4 className={styles.title}>Available Docs</h4>
          <p className={styles.subTitle}>Tier {tier} Upgrade upload</p>

          {tier === UpradeType.TIER_TWO && (
            <>
              <div className="mt-5">
                <CustomInputField
                  label="BVN"
                  type="text"
                  name="searchQuery"
                  disabled
                  defaultValue={bvn}
                />
              </div>
              <div className="mt-5">
                <CustomInputField
                  label="NIN"
                  type="text"
                  name="searchQuery"
                  disabled
                  defaultValue={nin}
                />
              </div>
            </>
          )}

          {tier === UpradeType.TIER_THREE && (
            <div className="mt-5">
              <CustomInputField
                label="Residential Information"
                type="text"
                name="searchQuery"
                disabled
                placeholder="22A ifelodun street off igboamaka street washington DC"
              />
            </div>
          )}

          <div>
            <button className="btn advancly-btn btn-md mr-2">
              Verify Address
            </button>
          </div>
        </div>
        <div className={`col-sm-7 col-xs-12 ${styles.checklistContainer}`}>
          <div className={styles.checkList}>
            <h4 className={styles.title}>Checklist Items</h4>
            {tier === UpradeType?.TIER_THREE && (
              <>
                <div className="mb-4">
                  <TierCheckList name="Tier 3" items={tierThreeItems} />
                </div>
                <div className="mb-4">
                  <TierCheckList name="Tier 2" items={tierTwoItems} />
                </div>
              </>
            )}

            {tier === UpradeType?.TIER_TWO && (
              <>
                <div className="mb-4">
                  <TierCheckList name="Tier 2" items={tierTwoItems} />
                </div>
              </>
            )}

            <p
              className={styles.viewBvnNinAction}
              onClick={() =>
                handleViewUserInformation(bvnDetails, ninDetails, picturePath)
              }
            >
              View Customer's BVN & NIN Information
            </p>
          </div>
        </div>
      </div>

      <TierUpgradeReviewModal
        dismissModalRef={dismissModalRef}
        declineRequest={declineRequest}
        modalActionType={modalActionType}
        loading={loading}
        initialOnboardingCode={200}
      />
    </Fragment>
  );
};

export default ReviewTierUpgradeRequest;
