import React, { useEffect } from "react";

// Component Styles
import "./TransactionModal.css";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const TransactionModal = (props) => {
  const { dataRecord } = props.properties;

  const printHandler = () => {};

  const {
    transaction_id,
    trans_amount,
    transaction_initiator,
    tx_action,
    tx_ref,
    tx_note,
    modified_date,
    pub_date,
  } = dataRecord;

  useEffect(() => {
    MixPanel.track(`Page View: Transactions.tsx`, {
      page: "Transactions.tsx",
    });
  }, []);

  const scene = "form";

  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div className="TransactionModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-2">
            Transaction ID: <span>{transaction_id}</span>
          </h3>
          {/* Transaction Form */}
          {(() => {
            switch (scene) {
              case "form":
                return (
                  <form>
                    <label className="label-txt">Transaction Initiator</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        className="user__form form-control"
                        readOnly
                        value={transaction_initiator}
                      />
                      <i className="fas fa-user-circle" />
                    </div>
                    <label className="label-txt">Transaction Note</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        readOnly
                        className="user__form form-control"
                        value={tx_note}
                      />
                      <i className="fas fa-list-alt" />
                    </div>
                    <label className="label-txt">Action</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        readOnly
                        className="user__form form-control"
                        value={tx_action}
                      />
                      <i className="fas fa-bolt"></i>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <label className="label-txt">Reference</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            readOnly
                            className="user__form form-control"
                            value={tx_ref}
                          />
                          <i className="fas fa-recycle" />
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">Amount</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            readOnly
                            value={trans_amount}
                          />
                          <i className="fas fa-coins" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-6">
                        <label className="label-txt">Initiation Date</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            readOnly
                            value={pub_date}
                          />
                          <i className="fas fa-calendar-alt" />
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">Modified Date</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            readOnly
                            value={modified_date}
                          />
                          <i className="fas fa-calendar-alt" />
                        </div>
                      </div>
                    </div>

                    <div className="Form__action-buttons m-t-1">
                      <button
                        type="button"
                        className="btn zoom-element btn login-btn m-0 mr-auto"
                        onClick={printHandler}
                      >
                        <i className="fas fa-print"></i>
                        &nbsp; Print
                      </button>
                      <button
                        type="button"
                        className="btn zoom-element btn login-btn m-0 ml-auto"
                        onClick={props.onCloseModal}
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times-circle"></i>
                        &nbsp; Go Back
                      </button>
                    </div>
                  </form>
                );
              case "loader":
                return (
                  <div>
                    {/* 2. login Form Loader */}
                    <div
                      id="adv-login-loader"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading" id="adv-loader-alt" />
                          <div className="center-txt webapp-ripple-text">
                            <i className="notifier fas fa-user-circle" />
                            &nbsp; Processing your transaction
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "success":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Logged In Successfully
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "fail":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-close-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Login Failed, try again later
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default TransactionModal;
