import { Fragment, useState, useEffect } from "react";

import moment from "moment";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import { useLocation, useRouteMatch } from "react-router-dom";
import Alert from "../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { appInsights } from "../../../../config/appInsights";
import ToggleTab from "../../../../NewComponents/ToggleTab/ToggleTab.component";
import AccountDetails from "./Tabs/AccountDetails";
import ContactPerson from "./Tabs/ContactPerson";
import BusinessInformation from "./Tabs/BusinessInformation";
import BusinessOwnersInformation from "./Tabs/BusinessOwnerInformation";
import BusinessDocumentation from "./Tabs/BusinessDocumentation";
import RegisteredTrustees from "./Tabs/RegisteredTrustees";
import SeniorManagement from "./Tabs/SeniorManagement";
import Directors from "./Tabs/Directors";
import Shareholders from "./Tabs/Shareholders";
import { useBusinessCustomerDetail } from "../../../../custom-hooks/useBusinessCustomerDetails";
import { Loader } from "../../../../UI/Loaders/Loaders";
import { BusinessTypes } from "../../Interfaces";
import Partners from "./Tabs/Partners";
import AuditTrail from "./Tabs/AuditTrail";

import styles from "./businessCustomerDetails.module.scss";

const BusinessCustomerDetails = () => {
  const [activeTab, setActiveTab] = useState("account-details");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const match = useRouteMatch();

  const { id } = match.params as { id: number };

  const { state } = useLocation();

  useEffect(() => {
    appInsights.trackPageView({
      name: "BusinessCustomerDetails.tsx",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: businessCustomerDetails,
    isLoading: isLoadingBusinessDetails,
    status,
  } = useBusinessCustomerDetail({
    customerId: id,
  });

  let RenderedComponent = null;
  if (!businessCustomerDetails && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Business Customer
            Details
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Business Customer Details..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Business Customer Details, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (businessCustomerDetails && !businessCustomerDetails.data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" />
              There are currently no Business Customer Details
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div>
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
            <h3 className="page-title d-flex align-items-center">
              <BackButtonComponent /> Go Back
            </h3>
          </div>
          <div className={styles.profileArea}>
            <div className={styles.profileAreaCard}>
              <div className={styles.title}>Business Name</div>
              <div className={styles.content}>
                {businessCustomerDetails?.data?.businessInformation.biz_name}
              </div>
            </div>
            <div className={styles.profileAreaCard}>
              <div className={styles.title}>Customer Email</div>
              <div className={styles.content}>
                {businessCustomerDetails?.data?.businessInformation?.biz_email}
              </div>
            </div>
            <div className={styles.profileAreaCard}>
              <div className={styles.title}>Business Type</div>
              <div className={styles.content}>
                {
                  businessCustomerDetails?.data?.businessInformation
                    .business_type
                }
              </div>
            </div>
            <div className={styles.profileAreaCard}>
              <div className={styles.title}>Created Date</div>
              <div className={styles.content}>
                {businessCustomerDetails?.data?.businessInformation
                  ?.onboarding_date
                  ? moment(
                      businessCustomerDetails?.data?.businessInformation
                        ?.onboarding_date
                    ).format("DD-MM-YYYY")
                  : ""}
              </div>
            </div>
            <div className={styles.profileAreaCard}>
              <div className={styles.title}>Request Date</div>
              <div className={styles.content}>
                {businessCustomerDetails?.data?.businessInformation
                  ?.onboarding_request_date
                  ? moment(
                      businessCustomerDetails?.data?.businessInformation
                        ?.onboarding_request_date
                    ).format("DD-MM-YYYY")
                  : ""}
              </div>
            </div>
            <div className={styles.profileAreaCard}>
              <div className={styles.title}>Activation Date</div>
              <div className={styles.content}>
                {businessCustomerDetails?.data?.businessInformation
                  ?.account_activation_date
                  ? moment(
                      businessCustomerDetails?.data?.businessInformation
                        ?.account_activation_date
                    ).format("DD-MM-YYYY")
                  : ""}
              </div>
            </div>
          </div>

          <div className="alignToggleTabItems mb-3 mt-5">
            <ToggleTab
              text="Account Details"
              id="account-details"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ToggleTab
              text="Contact Person"
              id="contact-person"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ToggleTab
              text="Business Information"
              id="business-information"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            {businessCustomerDetails?.data?.businessInformation
              ?.business_type_id === BusinessTypes.REGISTERED_BUSINESS && (
              <ToggleTab
                text="Business Owner Information"
                id="business-owners-information"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}

            <ToggleTab
              text="Business Documentation"
              id="business-documentation"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            {businessCustomerDetails?.data?.businessInformation
              ?.business_type_id === BusinessTypes.LIMITED_LIABILITY && (
              <ToggleTab
                text="Directors"
                id="directors"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}

            {businessCustomerDetails?.data?.businessInformation
              ?.business_type_id === BusinessTypes.NGO && (
              <ToggleTab
                text="Registered Trustees"
                id="registered-trustees"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}

            {businessCustomerDetails?.data?.businessInformation
              ?.business_type_id === BusinessTypes.NGO ||
              (businessCustomerDetails?.data?.businessInformation
                ?.business_type_id === BusinessTypes.LIMITED_LIABILITY && (
                <ToggleTab
                  text="Senior Management"
                  id="senior-management"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              ))}

            {businessCustomerDetails?.data?.businessInformation
              ?.business_type_id === BusinessTypes.LIMITED_LIABILITY && (
              <ToggleTab
                text="Shareholders"
                id="shareholders"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}

            {businessCustomerDetails?.data?.businessInformation
              ?.business_type_id === BusinessTypes.PARTNERSHIP && (
              <ToggleTab
                text="Partners"
                id="partners"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}

            <ToggleTab
              text="Approval Trail"
              id="audit-trail"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>

          <div className="pl-5 pr-5">
            {(() => {
              switch (activeTab) {
                case "account-details":
                  return (
                    <AccountDetails
                      accounts={businessCustomerDetails?.data?.accounts!}
                    />
                  );

                case "contact-person":
                  return (
                    <ContactPerson
                      data={businessCustomerDetails?.data?.contactPerson!}
                    />
                  );

                case "business-information":
                  return (
                    <BusinessInformation
                      data={businessCustomerDetails?.data?.businessInformation!}
                    />
                  );

                case "business-owners-information":
                  return (
                    <BusinessOwnersInformation
                      data={
                        businessCustomerDetails?.data?.businessOwnerInformation!
                      }
                    />
                  );

                case "business-documentation":
                  return (
                    <BusinessDocumentation
                      data={
                        businessCustomerDetails?.data?.businessDocumentation!
                      }
                    />
                  );

                case "registered-trustees":
                  return (
                    <RegisteredTrustees
                      data={businessCustomerDetails?.data?.registeredTrustees!}
                    />
                  );

                case "senior-management":
                  return (
                    <SeniorManagement
                      data={businessCustomerDetails?.data?.seniorManagement!}
                    />
                  );

                case "directors":
                  return (
                    <Directors
                      data={businessCustomerDetails?.data?.directorsDetails!}
                    />
                  );

                case "shareholders":
                  return (
                    <Shareholders
                      data={businessCustomerDetails?.data?.shareHolders!}
                    />
                  );

                case "partners":
                  return (
                    <Partners
                      data={businessCustomerDetails?.data?.partnersInformation!}
                    />
                  );

                case "audit-trail":
                  return (
                    <AuditTrail
                      data={businessCustomerDetails?.data?.auditTrail!}
                    />
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </div>
      );
    }
  }

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      {RenderedComponent}
    </Fragment>
  );
};

export default BusinessCustomerDetails;
